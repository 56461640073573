import React, { useState, useEffect, useContext } from "react";
import { Box, Typography, Button, Autocomplete, TextField, Grid } from "@mui/material";
import { useUser } from "../../components/UserProvider.js";
import axios from "axios";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import { toast } from "react-toastify";
import { NotificationContext } from "../../components/NotificationContext.js";
import Loader from "../../components/Loader/Loader.js";


const AllNotifications = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [allUser, SetAllUser] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null); // Initialize as null for single selection
  console.log("🚀 ~ AllNotifications ~ allUser:", allUser)
  const [selectedUsers, setSelectedUsers] = useState([]);
  console.log("🚀 ~ AllNotifications ~ selectedUsers:", selectedUsers)
  const { notifications, setNotifications, updateUnreadCount } =
    useContext(NotificationContext);
  const { user, token } = useUser();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    if (token){
      console.log("______user mil gya token mil gya");
      
    GetAllUser();    
  }
  }, [token]);

  const GetAllUser = () => {
    console.log("___________________get user calling");
    
    axios.get(BACKEND_BASE_URL + `v1/get_user/`, { headers })
      .then((response) => {
        if (response.status === 200) {
          SetAllUser(response?.data.User);

          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        
        setIsLoading(false)
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });
  };

  useEffect(() => {
    if (token) {
      fetchNotifications();
    }
  }, [token]);

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(
        BACKEND_BASE_URL + `v1/api/notifications/`,
        { headers }
      );
      if (response.status === 200) {
        const data = response.data.notifications;
        setNotifications(data);
        updateUnreadCount(data);
        toast.success("Notifications loaded successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false)
      }
    } catch (error) {
      console.error("Error loading notifications:", error);
      toast.error("Failed to load notifications.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false)
    }
  };

  const handleMarkAsRead = async (notificationId) => {
    try {
      const response = await axios.patch(
        `${BACKEND_BASE_URL}v1/api/notifications/${notificationId}/mark-as-read/`,
        {},
        { headers }
      );
      if (response.status === 200) {
        const updatedNotifications = notifications.map((notification) =>
          notification.id === notificationId
            ? { ...notification, is_read: true }
            : notification
        );
        setNotifications(updatedNotifications);
        updateUnreadCount(updatedNotifications);
      }
    } catch (error) {
      console.error("Failed to mark notification as read:", error);
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      const response = await axios.post(
        `${BACKEND_BASE_URL}v1/api/notifications/mark-as-read-all/`,
        {},
        { headers }
      );
      if (response.status === 200) {
        const updatedNotifications = notifications.map((notification) => ({
          ...notification,
          is_read: true,
        }));
        setNotifications(updatedNotifications);
        updateUnreadCount(updatedNotifications);
        toast.success("All notifications marked as read!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error("Failed to mark all notifications as read:", error);
      toast.error("Failed to mark all notifications as read.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleSearch = async (values) => {
    console.log("🚀+++++++++++++++++++++++++++++ ~ handleSearch ~ values:", values)
    setIsLoading(true);
    
    if (values) {
      try {
        const response = await axios.get(
          BACKEND_BASE_URL + `v1/api/user_wise_notifications/${values.id}`,
          { headers }
        );
        if (response.status === 200) {
          const data = response.data.notifications;
          setNotifications(data);
          // updateUnreadCount(data);
          toast.success("Notifications loaded successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoading(false)
        }
      } catch (error) {
        console.error("Error loading notifications:", error);
        toast.error("Failed to load notifications.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false)
      }
      setIsLoading(false);
    } else {
      setIsLoading(false)
      fetchNotifications()
      // setStatus("All Status");
      // ExportSubscriptions();
      // setDoubleFilter(null);
    }
  };

  return (
    <>
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        All Notifications
      </Typography>
      

      <Grid container spacing={2}>
            
            <Grid item sx={12} sm={9}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={Array.isArray(allUser) ? allUser : []} 
              value={selectedUser} 
              getOptionLabel={(option) => `${option?.first_name} ${option?.last_name}`} 
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Select User" variant="outlined" />
              )}
              onChange={(event, newValue) => {
                setSelectedUser(newValue); 
                handleSearch(newValue); 
              }}
            />
            </Grid>
            </Grid>
      {selectedUser ? (""):(
        <>
      <Box mb={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleMarkAllAsRead}
          disabled={notifications.every((n) => n.is_read)}
        >
          Mark All as Read
        </Button>
      </Box>
        </>
      )}
      {notifications.length > 0 ? (
        notifications.map((notification) => (
          <Box
            key={notification.id}
            sx={{
              mb: 2,
              p: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: notification.is_read ? "#f5f5f5" : "#e3f2fd",
              borderRadius: 1,
              boxShadow: 1,
            }}
          >
            {/* Left Side: Notification Message */}
            <Box>
              <Typography variant="body1">
                {notification.notification_message}
              </Typography>
              <Typography
                variant="caption"
                sx={{ display: "block", mt: 0.5, color: "#757575" }}
              >
                {new Date(notification.created_at).toLocaleString()}
              </Typography>
            </Box>

            {/* Right Side: Mark as Read Button */}
            {selectedUser ? (""): (<>
            {!notification.is_read && (
              <Box>
                <button
                  onClick={() => handleMarkAsRead(notification.id)}
                  style={{
                    padding: "6px 12px",
                    backgroundColor: "#2196f3",
                    color: "#fff",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  Mark as Read
                </button>
              </Box>
            )}
            </>)}
          </Box>
        ))
      ) : (
        <Typography variant="body2">No notifications available.</Typography>
      )}
    </Box>
    {
      isLoading && <Loader />
    }
    </>
  );
};

export default AllNotifications;