import React, { useEffect, useState } from "react";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import {
  Box,
  Button,
  Divider,
  Grid,
  InputBase,
  MenuItem,
  Select,
  Typography,
  useTheme,
  Autocomplete,
  TextField,
  Modal,
} from "@mui/material";
import { MdClose } from "react-icons/md";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme.js";
import Header from "../../components/Header.jsx";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useCSN } from "../../components/CSNProvider.jsx";
import { useUser } from "../../components/UserProvider.js";
import CircleLoader from "../../components/Loader/Loader.js";
import ExportToExcel from "../../components/ExportToExcel.js";


const ChangeLogSubscription = () => {
  const navigate = useNavigate();
  const { csn } = useCSN();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [tab, setTab] = useState(1);
  const [isChartLoading, SetisChartLoading] = useState(true);
  const [loading, SetLoading] = useState(true);
  const [subscriptionData, SetSubscriptionData] = useState([]);
  console.log("🚀 ~ ChangeLogSubscription ~ subscriptionData:", subscriptionData)
  const [lastUpdated, setLastUpdated] = useState([]);
  console.log("🚀 ~ CampareSubscription ~ lastUpdated:", lastUpdated)
  
  const [searchedData, setSearchedData] = useState([]);
  const [selectedSubscription, SetselectedSubscription] = useState(null);
  const [selectedOnBoard, SetSelectedOnBoard] = useState(null);



  const [subscriptionAllData, SetSubscriptionAllData] = useState([]);
  const [subscriptionTableData, SetSubscriptionTableData] = useState([]);
  const [DoubleFilter, setDoubleFilter] = useState([]);
  const [accountsname, setAccountsName] = useState(null);
  const [subscriptionType, setSubscriptionType] = useState("Backup");

  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState(null);
  const [selectedValue, setSelectedValue] = useState([]);
  const [branchfilteredData, setBranchFilteredData] = useState([]);
  const [searchBranch, setSearchBranch] = useState(null);

  const [branches, setBranches] = useState([]);
  const [changeLog, SetChangeLog] = useState([]);
  console.log("🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀 ~ CampareSubscription ~ changeLog:", changeLog)
  

  const { token , user} = useUser();


  const [openassign, setOpenAssign] = useState(false);
  const [allchoices, SetAllChoices] = useState(null);
  const [selectedSubsTrigger, SetselectedsubsTrigger] = useState([]);
  const [triggerSubsID, setTriggerSubsID] = useState(null);
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    if (token){
      const user = localStorage.getItem("user");
      if (user) {
        const userData = JSON.parse(user);
        setUserType(userData.user_type);
      }
    }
    }, [token]);


  const handleOpenModel = (subs_id, type) => {
    console.log("🚀 ~ handleOpenModel ~ subs_id:", subs_id)
    setOpen(true);
    let URL = "";
    if (type == "Backup") {
       URL =  `v1/api/change_log_subscription/${subs_id}`
    }
    else{
       URL =  `v1/api/change_log_subscription/${subs_id}`
    }
    axios
      .get(BACKEND_BASE_URL + URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          SetselectedSubscription(response?.data);
          SetLoading(false);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });
  };


  const handleOpenChangeLogModel = (subs_id) => {
    console.log("🚀 ~ handleOpenModel ~ subs_id:", subs_id)
    setOpen(true);
   
     const URL =  `v1/api/get_subscription/${subs_id}`
  
    axios
      .get(BACKEND_BASE_URL + URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          SetselectedSubscription(response?.data);
          SetLoading(false);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });
  };

  const handleTabChange = (val) => {
    setTab(val);
  };

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    if (token) {
      GetAllBranch();
    }
  }, []);

  const GetAllBranch = () => {
    setStatus("All Status");
    axios
      .get(BACKEND_BASE_URL + `v1/api/get_all_branch/`, { headers })
      .then((response) => {
        if (response.status === 200) {
          const data = response?.data?.Branch.map((item) => {
            const name = item.branch_name;
            const value = item.id;
            return {
              label: name,
              value: value,
            };
          });
          setBranches(data);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });
  };

  useEffect(() => {
    if (token) {
      ExportSubscriptions(subscriptionType);
    }
  }, [csn, token]);


  const ExportSubscriptions = () => {
    setIsLoading(true)
    SetisChartLoading(true);
    setStatus("All Status");
    const apiUrl = `${BACKEND_BASE_URL}v1/api/get_change_log_subscriptions/${
      csn ? `${csn}` : ""
    }`;

    axios
      .get(apiUrl, { headers })
      .then((response) => {
        SetisChartLoading(false);
        if (response.status === 200) {
          SetSubscriptionTableData(response?.data);
          SetSubscriptionData(response?.data);
          SetSubscriptionAllData(response?.data);
          SetLoading(false);
          setIsLoading(false)
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying subscription.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        setError(
          error.response.data?.Response
            ? error.response.data.Response.error?.message
            : error.response.data.error
        );
        SetLoading(false);
        toast.error(
          error.response.data?.Response
            ? error.response.data?.Response.error.message
            : error.response.data?.error,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      });
  };

//   const ExportSubscriptions = (type) => {
//     console.log("🚀 ~ ExportSubscriptions ~ type:", type)
//     setIsLoading(true)
//     SetisChartLoading(true);
//     setStatus("All Status");
//     setSubscriptionType(type)
//     const apiUrl = `${BACKEND_BASE_URL}v1/api/get_change_log_subscriptions/${
//       csn ? `${csn}` : ""
//     }`;

//     axios
//       .get(apiUrl, { headers })
//       .then((response) => {
//         SetisChartLoading(false);
//         if (response.status === 200) {
//           SetLoading(false);
//           setIsLoading(false)
//           SetChangeLog(response?.data?.changed_entries)
//           if (type == "Backup") {
//             SetSubscriptionTableData(response?.data.old_subscription);
//             SetSubscriptionAllData(response?.data.old_subscription)
//           }
//           else{
//             SetSubscriptionTableData(response?.data.new_subscription);
//             SetSubscriptionAllData(response?.data.new_subscription)

//           }
//           toast.success("Data Loaded", {
//             position: toast.POSITION.TOP_RIGHT,
//           });
//         } else {
//           toast.error("Error occured while displaying subscription.", {
//             position: toast.POSITION.TOP_RIGHT,
//           });
//         }
//       })
//       .catch((error) => {
//         setError(
//           error.response.data?.Response
//             ? error.response.data.Response.error?.message
//             : error.response.data.error
//         );
//         SetLoading(false);
//         toast.error(
//           error.response.data?.Response
//             ? error.response.data?.Response.error.message
//             : error.response.data?.error,
//           {
//             position: toast.POSITION.TOP_RIGHT,
//           }
//         );
//       });
//   };

//   const getAccountsData = () => {
//     axios
//       .get(BACKEND_BASE_URL + `v1/api/account_dropdown/`, { headers })
//       .then((response) => {
//         if (response.status === 200) {
//           const data = response.data.accounts.map((item) => {
//             const name = item.name;
//             const csn = item.csn;
//             return {
//               label: name,
//               csn: csn,
//             };
//           });
//           const uniqueLabels = new Set();

//           const uniqueData = data.filter((item) => {
//             if (!uniqueLabels.has(item.label)) {
//               uniqueLabels.add(item.label);
//               return true;
//             }
//             return false;
//           });
//           setAccountsName(uniqueData);
//         }
//       });
//   };
//   useEffect(() => {
//     if (token) {
//       getAccountsData();
//     }
//   }, [token]);


  const getRowId = (row) => row.id;
  const columns = [
    {
      field: "subscriptionReferenceNumber",
      headerName: "subscription",
      width: 150,
      renderCell: (params, index) => (
        <div>
          <button
            onClick={() => handleOpenModel(params?.row.id, subscriptionType)}
            className="action-button bg-white text-black px-3 py-1 rounded"
          >
            {params.value}
          </button>
        </div>
      ),
    },
    {
      field: "account_name",
      headerName: "Account Name",
      width: 200,
      renderCell: (params) => {
        const { value: account } = params;
        const maxChars = 20;

        return (
          <div style={{ whiteSpace: "normal", maxWidth: "200px" }}>
            {account?.length > maxChars ? account : account?.slice(0, maxChars)}
          </div>
        );
      },
    },
    { field: "account_csn", headerName: "Account CSN", width: 100 },
    {
      field: "bd_person",
      headerName: "BD Person Name",
      width: 200,
      renderCell: (params) => (
        <div>
          {params.value && params.value ? (
            params.value
          ) : (
            <span style={{ color: "red" }}>Undefined</span>
          )}
        </div>
      ),
    },
    {
      field: "renewal_person",
      headerName: "Renewal Person Name",
      width: 200,
      renderCell: (params) => (
        <div>
          {params.value && params.value ? (
            params.value
          ) : (
            <span style={{ color: "red" }}>Undefined</span>
          )}
        </div>
      ),
    },
    {
      field: "branch",
      headerName: "Branch",
      width: 100,
      renderCell: (params) => (
        <div>
          {params.value && params.value ? (
            params.value
          ) : (
            <span style={{ color: "red" }}>Undefined</span>
          )}
        </div>
      ),
    },
    {
      field: "contract_manager_email",
      headerName: "Contract Mgr. Email",
      width: 200,
      renderCell: (params) => {
        const { value: email } = params;
        const maxChars = 20;

        return (
          <div style={{ whiteSpace: "normal", maxWidth: "200px" }}>
            {email?.length > maxChars ? email : email?.slice(0, maxChars)}
          </div>
        );
      },
    },
    { field: "seats", headerName: "Seats", width: 70 },
    { field: "startDate", headerName: "Subs Start Date", width: 130 },
    { field: "endDate", headerName: "Subs End Date ", width: 130 },
    { field: "trisita_new_status", headerName: "Trisita New Status", width: 130 },
    { field: "trisita_old_status", headerName: "Trisita Old Status", width: 130 },
    { field: "lastPurchaseDate", headerName: "Last Purchase date", width: 130 },

    { field: "account_group", headerName: "Account Group", width: 100 },
    { field: "subscriptionType", headerName: "CampareSubscription Type", width: 100 },
    { field: "contract_end_date", headerName: "Contract EndDate", width: 130 },
    {
      field: "productLine",
      headerName: "Product Line",
      width: 250,
      renderCell: (params) => {
        const { value: productLine } = params;
        const maxChars = 20;

        return (
          <div style={{ whiteSpace: "normal", maxWidth: "200px" }}>
            {productLine?.length > maxChars
              ? productLine
              : productLine?.slice(0, maxChars)}
          </div>
        );
      },
    },
    {
      field: "change_log_date",
      headerName: "Created Date",
      width: 130,
      type: 'date', // To indicate the data type
      renderCell: (params) => {
        // Custom render function to format the date
        const formattedDate = new Date(params.value).toLocaleDateString(); // Format date
        return <span>{formattedDate}</span>;
      },
      sortable: true, // Enable sorting
      filterable: true, // Enable filtering
    },
    
    // ...(userType && userType === "Superadmin"
    //   ? [{
    //     field: "",
    //     headerName: "Set Trigger",
    //     width: 150,
    //     renderCell: (params, index) => (
    //       <div className="flex items-center w-full justify-center">
    //         <button
    //           onClick={() => handleTriggerModel(params?.row.id)}
    //           className="action-button bg-[#8dbe86] text-black px-3 py-1 rounded"
    //         >
    //           Assign Trigger
    //         </button>
    //       </div>
    //     ),
    //   }] :
    //   []),
  ];


  const [status, setStatus] = React.useState("All Status");

  const handleChange = (event) => {
    SetSelectedOnBoard(null);
    const selectedValue = event.target.value;
    setStatus(selectedValue);

    if (selectedValue) {
      if (selectedValue === "All Status") {
        SetSubscriptionData(subscriptionAllData);
        SetSubscriptionTableData(subscriptionAllData);
      } else {
        const filteredResults = subscriptionAllData?.filter((item) => {
          const trisita_new_status = item["trisita_new_status"];
          return trisita_new_status === selectedValue;
        });

        SetSubscriptionData(filteredResults);
        SetSubscriptionTableData(filteredResults);
        SetisChartLoading(false);
        SetLoading(false);
      }
    } else {
      ExportSubscriptions();
    }
  };

  const handleSearch = (values) => {
    SetisChartLoading(true);
    setSearchTerm(values);
    
    if (values.length > 0) {
      const filteredResults = subscriptionAllData?.filter((item) => {
        const name = item["account_name"];
        return values.some(value => name && name.toLowerCase().includes(value.label.toLowerCase()));
      });
  
      setSearchedData(filteredResults);
      SetSubscriptionData(filteredResults);
      SetSubscriptionTableData(filteredResults);
      setBranchFilteredData(filteredResults);
      setDoubleFilter(filteredResults);
      SetisChartLoading(false);
    } else {
      setStatus("All Status");
      ExportSubscriptions();
      setDoubleFilter(null);
    }
  };

  const handleBranchFilter = (branch) => {
    if (branch?.label) {
      const filteredResults = searchedData?.filter((item) => {
        const name = item["branch"];
        return name === branch.label;
      });
      SetSubscriptionData(filteredResults);
      SetSubscriptionTableData(filteredResults);
      setBranchFilteredData(filteredResults);
      setStatus("All Status");
    } else {
      setStatus("All Status");
      handleSearch(searchTerm);
    }
  };

  const formatLastUpdated = (timestamp) => {
    if (!timestamp) return 'Not Available';
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };

  const HandleAllocate = (subs_id) => {
    console.log("🚀 ~ HandleAllocate ~ subs_id:", subs_id)
    console.log("___________selectedSubsTrigger.map(user => user.id)",selectedSubsTrigger.map(user => user.id));
    
    const requestBody = {
      "subscription": subs_id || null,
      "trigger_choices": selectedSubsTrigger.map(user => user.id),
    };

    axios.put(BACKEND_BASE_URL + `v1/api/subs_trigger/${subs_id}`, requestBody, { headers })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Account Allocated", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occurred while allocating accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error("Error occurred while allocating accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });

    setOpenAssign(false);
    ExportSubscriptions();
  };

  const handleTriggerModel = (subs_id) => {
    setOpenAssign(true);
    setTriggerSubsID(subs_id)
    axios
      .get(BACKEND_BASE_URL + `v1/api/subs_trigger/${subs_id}`, { headers })
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response)
        if (response.status === 200) {
          SetselectedsubsTrigger(response?.data?.trigger_choices || []);
          SetLoading(false);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });
  };

  useEffect(() => {
    if (token){
    GetMailChoices();
    // eslint-disable-next-line
    }
  }, [token]);

  const GetMailChoices = () => {
    setStatus("All Status");
    axios.get(BACKEND_BASE_URL + `v1/api/get_all_mail_trigger/`, { headers })
      .then((response) => {
        if (response.status === 200) {
          SetAllChoices(response?.data?.choices);
          const data = response?.data?.choices.map(item => {
            const name = item.choice;
            const value = item.id;
            return {
              label: name,
              value: value,
            };
          });
          const uniqueLabels = new Set();
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying  sdasDSDASF accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        
        toast.error("Error occured while displaying  DSFDSFDS F accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });
  };

  const formattedDate = formatLastUpdated(lastUpdated);

  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();

  const processedData = subscriptionTableData?.map((item) => ({
    ...item,
    // Join bd_person_first_names and renewal_person_first_names arrays into comma-separated strings
    bd_person: item.bd_person_first_names ? item.bd_person_first_names.join(', ') : '',
    renewal_person: item.renewal_person_first_names ? item.renewal_person_first_names.join(', ') : '',
  }));
  console.log("🚀 ~ processedData ~ processedData:", processedData)

  return (
    <>
      <Box m="20px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Box>
            <Header title="Subscription Change Log Data" />
          </Box>
          {/* <div className="relative group">
            <span className="text-gray-600">Last Updated</span>
              <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-max px-4 py-2 bg-black text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                {formattedDate}
              </div>
          </div> */}

          
          <Box sx={{display:'flex'}}>
          
            

            <Box
                sx={{
                  ml: { md: 2 },
                  height: "50px",
                  width: 100,
                  minWidth: "200px",
                }}
              >
                <Select
                  value={status}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={handleChange}
                  sx={{ width: "100%" }}
                  SelectDisplayProps={{
                    style: { paddingTop: 8, paddingBottom: 8 },
                  }}
                  disabled={isChartLoading}
                  className="min-h-[52.7px]"
                >
                  <MenuItem value={"All Status"}>All Status</MenuItem>
                  <MenuItem value={"Active"}>Active</MenuItem>
                  <MenuItem value={"Expired"}>Expired</MenuItem>
                </Select>
              </Box>
            
            
          </Box>
        </Box>

        <Box
          m="8px 0 0 0"
          width="100%"
          height="80vh"
          sx={{
            overflow: 'auto', 
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <Box sx={{backgroundColor:colors.primary[400] , borderBottom:'0.8px solid' , borderColor:colors.grey[900], padding:2,}}>
        <Typography variant="h4" sx={{fontWeight:'600'}} component="h3">
            Table Data
          </Typography>
        </Box>
          {loading ? (
            <p>Loading data...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : (
            <DataGrid
              rows={processedData}
              columns={columns}
              getRowId={getRowId}
              components={{
                Toolbar: () => (
                  <div>
                    <GridToolbar
                    csvOptions={{
                      fileName: `subs_trisita_${user.first_name} ${user.last_name}_${currDate}_${currTime}`,
                      fields: ['subscriptionReferenceNumber', 'account_name', 'account_csn', 'user_assign', 'renewal_person', 'branch', 'contract_manager_email', 'seats', 'endDate', 'startDate', 'trisita_new_status', 'subscriptionStatus', 'lastPurchaseDate', 'groupName', 'subscriptionType', 'contract_end_date', 'productLine'],
                    }} />
                    <ExportToExcel
                      data={processedData}
                      columns={columns}
                      fileName={`subs_trisita_${user.first_name} ${user.last_name}_${currDate}_${currTime}`}
                    />
                  </div>
                )
              }}
              checkboxSelection
              disableRowSelectionOnClick
            />
          )}

      </Box>

      </Box>



      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        classes={{ backgroundColor: "red" }}
      >
        {/* <div className="absolute -translate-x-2/4 bg-white -translate-y-2/4 w-[500] rounded-lg shadow-[24] p-4 left-2/4 top-[18%]"> */}
        <div className="absolute -translate-x-2/4 bg-white h-[636px] max-w-[500px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
          <div className="h-full">
            <div className="text-black flex border-b border-gray-300">
              <Link
                to={"#"}
                className={`${
                  tab === 1
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "border-b text-blue-500"
                } p-2 border-gray-300 flex-grow text-center`}
                onClick={() => handleTabChange(1)}
              >
                End Customer
              </Link>
              <Link
                to={"#"}
                className={`${
                  tab === 2
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "border-b text-blue-500"
                } p-2 border-gray-300 flex-grow text-center`}
                onClick={() => handleTabChange(2)}
              >
                Reseller
              </Link>
              <Link
                to={"#"}
                className={`${
                  tab === 3
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "border-b text-blue-500"
                } p-2 border-gray-300 flex-grow text-center`}
                onClick={() => handleTabChange(3)}
              >
                Distributor
              </Link>
              <Link
                to={"#"}
                className={`${
                  tab === 4
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "border-b text-blue-500"
                } p-2 border-gray-300 flex-grow text-center`}
                onClick={() => handleTabChange(4)}
              >
                Contract
              </Link>
              <Link
                to={"#"}
                className={`${
                  tab === 5
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "border-b text-blue-500"
                } p-2 border-gray-300 flex-grow text-center`}
                onClick={() => handleTabChange(5)}
              >
                Line Items
              </Link>
            </div>
            <div className="mt-5"></div>
            <div className="p-4 my-4 h-[calc(100%_-_117px)] overflow-auto">
              {tab === 1 && (
                <>
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                    End Customer Details
                  </h1>
                  {selectedSubscription ? (
                    <>
                      <div className="w-full">
                        <div className="flex my-4">
                          <div className="w-[200px]">CampareSubscription#</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.subscriptionReferenceNumber}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">CSN</div>
                          <div className="whitespace-pre">
                            {
                              selectedSubscription?.endCustomer_account
                                .endCustomerCsn
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Name</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.endCustomer_account.name}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Address Line 1</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.endCustomer_account.address1}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Address Line 2</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.endCustomer_account.address2}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">City</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.endCustomer_account.city}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">State</div>
                          <div className="whitespace-pre">
                            {
                              selectedSubscription?.endCustomer_account
                                .stateProvince
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Country</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.endCustomer_account.country}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Postal</div>
                          <div className="whitespace-pre">
                            {
                              selectedSubscription?.endCustomer_account
                                .postalCode
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>Loading data...</p>
                  )}
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                    Contract Manager Details
                  </h1>
                  {selectedSubscription ? (
                    <>
                      <div className="w-full">
                        <div className="flex my-4">
                          <div className="w-[200px]">First Name</div>
                          <div className="whitespace-pre">
                            {
                              selectedSubscription?.endCustomer_contractManager
                                .first
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Last Name</div>
                          <div className="whitespace-pre">
                            {
                              selectedSubscription?.endCustomer_contractManager
                                .last
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Email</div>
                          <div className="whitespace-pre">
                            {
                              selectedSubscription?.endCustomer_contractManager
                                .email
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Phone</div>
                          <div className="whitespace-pre">
                            {
                              selectedSubscription?.endCustomer_contractManager
                                .phone
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>Loading data...</p>
                  )}
                </>
              )}
              {tab === 2 && (
                <>
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                    Reseller Details
                  </h1>
                  {selectedSubscription ? (
                    <>
                      <div className="w-full">
                        <div className="flex my-4">
                          <div className="w-[200px]"> Opportunity#</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.subscriptionReferenceNumber}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">CSN</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.accounts_reseller.csn}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Name</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.accounts_reseller.name}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>Loading data...</p>
                  )}
                </>
              )}
              {tab === 3 && (
                <>
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                    Distributor Details
                  </h1>
                  {selectedSubscription ? (
                    <>
                      <div className="w-full">
                        <div className="flex my-4">
                          <div className="w-[200px]"> CampareSubscription#</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.subscriptionReferenceNumber}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">CSN</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.accounts_soldTo.csn}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Name</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.accounts_soldTo.name}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>Loading data...</p>
                  )}
                </>
              )}
              {tab === 4 && (
                <>
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                    Contract Details
                  </h1>
                  {selectedSubscription ? (
                    <>
                      <div className="w-full">
                        <div className="flex my-4">
                          <div className="w-[200px]"> Opportunity#</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.subscriptionReferenceNumber}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Contract#</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.contract.contract_number}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Type</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.contract.subType}
                          </div>
                        </div>

                        <div className="flex my-4">
                          <div className="w-[200px]">Product Line Name</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.productLine}
                          </div>
                        </div>

                        <div className="flex my-4">
                          <div className="w-[200px]"> Term</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.contract.contract_term}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Duration</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.contract.contract}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Retention Health</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.ews_retentionHealth}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Sub start date</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.startDate}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Sub end date</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.endDate}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Quantity</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.quantity}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>Loading data...</p>
                  )}
                </>
              )}
              {tab === 5 && (
                <>
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                    {" "}
                    Line Items 1 Details{" "}
                  </h1>
                  {selectedSubscription ? (
                    <>
                      <div className="w-1/2">
                        <div className="flex my-4">
                          <div className="w-[200px]"> Serial#</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.subscriptionReferenceNumber}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Quantity</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.quantity}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Seats</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.seats}
                          </div>
                        </div>
                        
                        <div className="flex my-4">
                          <div className="w-[200px]"> Deployment</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.deployment}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Status</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.subscriptionStatus}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Asset Status</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.productStatus}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Support Program</div>
                          <div className="whitespace-pre">{""}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Program Eligibility</div>
                          <div className="whitespace-pre">{""}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Renew</div>
                          <div className="whitespace-pre">{""}</div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>Loading data...</p>
                  )}
                </>
              )}
            </div>
            <div className="border-t">
              <div className="pt-4 text-right">
                <button
                  className="border px-2 py-1 font-[700] text-white bg-gray-500 border-gray-300 rounded"
                  onClick={() => {
                    setOpen(false);
                    SetselectedSubscription(null);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
      open={openassign}
      onClose={() => setOpenAssign(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      classes={{ backgroundColor: "red" }}
    >
      <div className="relative -translate-x-2/4 bg-white h-[350px] max-w-[600px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
        <div className="absolute top-2 right-3 cursor-pointer">
          <MdClose
            className="cursor-pointer text-xl"
            onClick={() => {
              setOpenAssign(false);
              setTriggerSubsID(null);
              SetselectedsubsTrigger([])
            }}
          />
        </div>
        <h3 className="text-base font-semibold leading-6 border-b pb-3 text-gray-900">
          Set Email Trigger
        </h3>
        <Box sx={{ paddingY: '30px' }}>
          <Grid container spacing={2}>
            <Grid item sx={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Trigger Choice :</Typography>
            </Grid>
            <Grid item sx={12} sm={9}>
                {selectedSubsTrigger ? (
                    <>
                    <Autocomplete
                        disablePortal
                        multiple  // Allow multiple selections
                        id="combo-box-demo"
                        options={allchoices}
                        value={selectedSubsTrigger}
                        getOptionLabel={(option) => option.choice}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                        <TextField {...params} label="Select Trigger og" variant="outlined" />
                        )}
                        onChange={(event, newValue) => {
                        SetselectedsubsTrigger(newValue);
                        }}
                    />
                    </>
                    ):(
                        <>
                        <Autocomplete
                        disablePortal
                        multiple  // Allow multiple selections
                        id="combo-box-demo"
                        options={allchoices}
                        getOptionLabel={(option) => option.choice}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                        <TextField {...params} label="Select Trigger" variant="outlined" />
                        )}
                        onChange={(event, newValue) => {
                        SetselectedsubsTrigger(newValue);
                        }}
                    />
                        </>
                    )
                }
            </Grid>

            
          </Grid>
          <div className="max-w-[447px] w-full text-right mt-4">
            <Button
              className="border px-2 py-1 font-[700] text-white bg-gray-500 border-gray-300 rounded"
              onClick={() => HandleAllocate(triggerSubsID)}
            >
              Allocate
            </Button>
          </div>
        </Box>
      </div>
    </Modal>
      {
        isLoading && <CircleLoader />
      }
    </>
  );
};

export default ChangeLogSubscription;
