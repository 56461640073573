import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../components/Header.jsx";
import axios from "axios";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import { useCSN } from "../../components/CSNProvider.jsx";
import { useUser } from "../../components/UserProvider.js";
import {Box, Button, Grid, Typography, useTheme, Modal } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme.js";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MdClose } from "react-icons/md";



const SelectedAudienceList = ({ onProcessSelectedRows }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const receivedData = location.state; // Access passed data
  const [subscriptionAllData, SetSubscriptionAllData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]); // Track selected rows
  console.log("🚀 ~ SelectedAudienceList ~ selectedRows:", selectedRows)
  const { csn } = useCSN();
  const { token, user } = useUser();
  const [userType, setUserType] = useState(null);
  const [openassign, setOpenAssign] = useState(false);
  const [selectedAccount, SetselectedAccount] = useState([]);
  const [selectedAccId, setSelectedAccId] = useState(null);
  const [emailContent, setEmailContent] = useState("");
  const [phoneContent, setPhoneContent] = useState("");

  const [loading, SetLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (token){
      const user = localStorage.getItem("user");
      if (user) {
        const userData = JSON.parse(user);
        setUserType(userData.user_type);
      }
    }
    }, [token]);

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    if (token) {
      GetCampaignAudience();
    }
  }, [token, receivedData]);

  const GetCampaignAudience = () => {
    const apiUrl = `${BACKEND_BASE_URL}v1/api/get_campaign_audience_list/?pcsn=${
      encodeURIComponent(csn || "")
    }&branch_id=${encodeURIComponent(receivedData?.branch || "")}&account_group=${
      encodeURIComponent(receivedData?.account_group?.value || "")
    }&industry=${
      encodeURIComponent(receivedData?.industry?.value || "")
    }&segment=${encodeURIComponent(receivedData?.segment?.value || "")}&subsegment=${
      encodeURIComponent(receivedData?.sub_segment?.value || "")
    }&product_line_code=${
      encodeURIComponent(receivedData?.product_line_code?.value || "")
    }&status=${encodeURIComponent(receivedData?.status || "")}`;
    axios
      .get(apiUrl, { headers })
      .then((response) => {
        if (response.status === 200) {
          const allData = response?.data?.data;
          SetSubscriptionAllData(allData);
          setSelectedRows(allData.map((item) => item.id)); // Auto-select all rows
          SetLoading(false);
        }
      })
      .catch((error) => {
        setError("Failed to fetch data.");
        SetLoading(false);
      });
  };

  const getRowId = (row) => row.id;

  const columns = [
    {
      field: "subscriptionReferenceNumber",
      headerName: "subscription",
      width: 150,
      renderCell: (params, index) => (
        <div>
          <button
            className="action-button bg-white text-black px-3 py-1 rounded"
          >
            {params.value}
          </button>
        </div>
      ),
    },
    {
      field: "account_name",
      headerName: "Account Name",
      width: 200,
      renderCell: (params) => {
        const { value: account } = params;
        const maxChars = 20;

        return (
          <div style={{ whiteSpace: "normal", maxWidth: "200px" }}>
            {account?.length > maxChars ? account : account?.slice(0, maxChars)}
          </div>
        );
      },
    },
    // { field: "bd_person", headerName: "BD Person Name", width: 200 },
    // { field: "renewal_person", headerName: "Renewal Person Name", width: 200 },
    ...(user.user_type !== "Client"
      ? [
    {
      field: "bd_person",
      headerName: "BD Person Name",
      width: 200,
      renderCell: (params) => (
        <div>
          {params.value && params.value ? (
            params.value
          ) : (
            <span style={{ color: "red" }}>Undefined</span>
          )}
        </div>
      ),
    },
    {
      field: "renewal_person",
      headerName: "Renewal Person Name",
      width: 200,
      renderCell: (params) => (
        <div>
          {params.value && params.value ? (
            params.value
          ) : (
            <span style={{ color: "red" }}>Undefined</span>
          )}
        </div>
      ),
    },]
    : []),
    
    { field: "account_csn", headerName: "Account CSN", width: 100 },
    
    { field: "retention_health_riskBand", headerName: "Retention health riskBand", width: 100 },
    ...(user.user_type !== "Client"
      ? [
    {
      field: "branch",
      headerName: "Branch",
      width: 100,
      renderCell: (params) => (
        <div>
          {params.value && params.value ? (
            params.value
          ) : (
            <span style={{ color: "red" }}>Undefined</span>
          )}
        </div>
      ),
    },] : []),
    {
      field: "contract_manager_email",
      headerName: "Contract Mgr. Email",
      width: 200,
      renderCell: (params) => {
        const { value: email } = params;
        const maxChars = 20;

        return (
          <div style={{ whiteSpace: "normal", maxWidth: "200px" }}>
            {email?.length > maxChars ? email : email?.slice(0, maxChars)}
          </div>
        );
      },
    },
    { field: "seats", headerName: "Seats", width: 70 },
    { field: "startDate", headerName: "Subs Start Date", width: 130 },
    { field: "endDate", headerName: "Subs End Date ", width: 130 },
    { field: "trisita_status", headerName: "Trisita Status", width: 130 },
    { field: "subscriptionStatus", headerName: "Status", width: 100 },
    { field: "lastPurchaseDate", headerName: "Last Purchase date", width: 130 },

    { field: "account_group", headerName: "Account Group", width: 100 },
    { field: "programType", headerName: "Program Type", width: 100 },
    { field: "subscriptionType", headerName: "Subscription Type", width: 100 },
    { field: "contract_end_date", headerName: "Contract EndDate", width: 130 },
    { field: "productLineCode", headerName: "Product Line Code", width: 130 },
    
    {
      field: "productLine",
      headerName: "Product Line",
      width: 250,
      renderCell: (params) => {
        const { value: productLine } = params;
        const maxChars = 20;

        return (
          <div style={{ whiteSpace: "normal", maxWidth: "200px" }}>
            {productLine?.length > maxChars
              ? productLine
              : productLine?.slice(0, maxChars)}
          </div>
        );
      },
    },
    ...(userType && userType === "Superadmin"
      ? [{
        field: "",
        headerName: "Set Contact",
        width: 150,
        renderCell: (params, index) => (
          <div className="flex items-center w-full justify-center">
            <button
              onClick={() => handleAccountContactModel(params?.row.account_id)}
              className="action-button bg-[#8dbe86] text-black px-3 py-1 rounded"
            >
              Assign Contact
            </button>
          </div>
        ),
      }] :
      []),
   
  ];

  const handleAccountContactModel = (acc_id) => {
    setOpenAssign(true);
    setSelectedAccId(acc_id)
    axios
      .get(BACKEND_BASE_URL + `v1/api/account_contact/${acc_id}`, { headers })
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response)
        if (response.status === 200) {
          SetselectedAccount(response?.data || []);
          setEmailContent(response?.data?.emails || ""); // Populate email content
          setPhoneContent(response?.data?.phone_numbers || "");
          SetLoading(false);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });
  };



  const processedData = subscriptionAllData?.map((item) => ({
    ...item,
    id: item.id,
    bd_person: item.bd_person_first_names ? item.bd_person_first_names.join(', ') : '',
    renewal_person: item.renewal_person_first_names ? item.renewal_person_first_names.join(', ') : '',
  }));

  const handleSelectionChange = (newSelectedRows) => {
    setSelectedRows(newSelectedRows);
  };

  // const handleProcessRows = () => {
  //   if (selectedRows.length > 0) {
  //     onProcessSelectedRows(selectedRows); 
  //   } else {
  //     alert("Please select at least one row.");
  //   }
  // };

  const handleProcessRows = () => {
    const dataToSend = {
      selected_rows: selectedRows,
      branch: receivedData.branch,
      account_group: receivedData.account_group,
      industry: receivedData.industry,
      segment: receivedData.segment,
      sub_segment: receivedData.sub_segment,
      product_line_code:receivedData.product_line_code,
      status:receivedData.status
    };
    navigate("/final_campaign", { state: dataToSend }); 
  };

  const handleEmailContentChange = (event) => {
    setEmailContent(event.target.value);
  };

  const handlePhoneContentChange = (event) => {
    setPhoneContent(event.target.value);
  };


  const HandleAllocate = (acc_id) => {
    console.log("🚀 ~ HandleAllocate ~ acc_id:", acc_id)
    
    const requestBody = {
      account: acc_id,
      emails: emailContent, // Convert emails list to a comma-separated string
      phone_numbers: phoneContent, // Convert phones list to a comma-separated string
    };

    axios.post(BACKEND_BASE_URL + `v1/api/account_contact/${acc_id}`, requestBody, { headers })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Account Contact Allocated", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occurred while allocating accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error("Error occurred while allocating accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });

    setOpenAssign(false);
    // ExportSubscriptions();
  };
  

  return (
    <>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
          <Header title="Select Campaign Audience" />
        </Box>
        <Box
          m="8px 0 0 0"
          width="100%"
          height="80vh"
          sx={{
            "& .MuiDataGrid-root": { border: "none" },
            "& .MuiDataGrid-cell": { borderBottom: "none" },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": { color: `${colors.greenAccent[200]} !important` },
          }}
        >
          {loading ? (
            <p>Loading data...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : (
            <>
              <DataGrid
                rows={processedData}
                columns={columns}
                getRowId={getRowId}
                checkboxSelection
                onSelectionModelChange={(newSelection) => handleSelectionChange(newSelection)}
                            selectionModel={selectedRows} // Pre-select rows
                            components={{
                              Toolbar: GridToolbar,
                            }}
                
              />
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleProcessRows}
                  disabled={selectedRows.length === 0} // Disable if no rows selected
                >
                  Process Selected Rows
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>

    
      <Modal
          open={openassign}
          onClose={() => setOpenAssign(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="relative bg-white max-w-[600px] w-full rounded-lg shadow-lg p-6 mx-auto top-[10%]">
            <div className="absolute top-2 right-3 cursor-pointer">
              <MdClose
                className="cursor-pointer text-xl"
                onClick={() => {
                  setOpenAssign(false);
                  setSelectedAccId(null);
                  // SetselectedAccount([])
                }}
              />
            </div>
            <h3 className="text-lg font-semibold leading-6 border-b pb-3 text-gray-900">Add Contact</h3>

            <Box sx={{ paddingY: '20px' }}>
              <Grid container spacing={3}>
                {/* Email Field */}
                <Grid item xs={12} sm={4} className="flex items-center">
                  <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Set Email:</Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <textarea
                    name="new_template"
                    className="w-full outline-none rounded-md border border-gray-300 p-3"
                    style={{ height: '150px' }} // Set custom height for textarea
                    value={emailContent}
                    onChange={handleEmailContentChange}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} className="mt-4">
                {/* Phone Field */}
                <Grid item xs={12} sm={4} className="flex items-center">
                  <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Set Phone:</Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <textarea
                    name="new_template"
                    className="w-full outline-none rounded-md border border-gray-300 p-3"
                    style={{ height: '150px' }} // Set custom height for textarea
                    value={phoneContent}
                    onChange={handlePhoneContentChange}
                  />
                </Grid>
              </Grid>

              {/* Button */}
              <div className="max-w-[447px] w-full text-right mt-4 mx-auto">
                <Button
                  className="border px-4 py-2 font-[700] text-white bg-gray-500 border-gray-300 rounded"
                  onClick={() => HandleAllocate(selectedAccId)}
                >
                  Allocate
                </Button>
              </div>
            </Box>
          </div>
        </Modal>
    </>
  );
};

export default SelectedAudienceList;