import React, { useState, useEffect } from "react";
import { Box, Modal, Grid, Typography, TextField, MenuItem, Button, Tooltip, IconButton} from '@mui/material';

import Header from "../../components/Header.jsx";
import {BACKEND_BASE_URL, BACKEND_BASE_MEDIA_URL} from "../../components/config/apiEnv.js";
import axios from "axios";
import { toast } from "react-toastify";
import { useUser } from "../../components/UserProvider.js";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/AuthContext.js";
import { MdClose } from "react-icons/md";
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useCSN } from "../../components/CSNProvider.jsx";
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import PreviewIcon from '@mui/icons-material/Preview';
import { DataGrid, GridToolbar} from "@mui/x-data-grid";
import { CalculateOutlined } from "@mui/icons-material";
import { useParams } from 'react-router-dom';


const UpdateOrderLoadingPO = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { id } = useParams();
  const [error, setError] = useState(null);
  const navigate = useNavigate()
  const { token } = useUser();
  const { csn } = useCSN();
  const location = useLocation();
  
  const [productDetails, SetProductDetails] = useState([]);
  
  const [user_type, setUserType] = useState(null);
  const [selectedProductMaster, setSelectedProductMaster] = useState(null);
  const [selectedPurchaseInvoice, setSelectedPurchaseInvoice] = useState(null);
  const [openProductDetails, setOpenProductDetails] = useState(false);
  const [openPaymentDetails, setOpenPaymentDetails] = useState(false);
  const [openPurchaseInvoice, setOpenPurchaseInvoice] = useState(false);
  const [openUpdatePurchaseInvoice, setOpenUpdatePurchaseInvoice] = useState(false);
  
  const [allProjectDetails, SetAllProjectDetails] = useState(null);
  const [allProjectMaster, SetAllProjectMaster] = useState(null);
  const [allaccountdata, SetAllAccountData] = useState([]);
  const [allUser, SetAllUser] = useState(null);
  const [stateCode, SetStateCode] = useState(null);
  const [allBranch, SetAllBranch] = useState(null);
  const [open, setOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [paymentopen, setPaymentOpen] = useState(false);
  const [purchaseopen, setPurchaseOpen] = useState(false);
  
  const [popupLoading, SetPopupLoading] = useState(true);
  
  const [allSoldTo, SetAllSoldTo] = useState(null);

  const [openExistingPaymentDetails, setOpenExistingPaymentDetails] = useState(false);
  const [openExistingPurchaseInvoice, setOpenExistingPurchaseInvoice] = useState(false);
  
  const [existingPayments, setExistingPayment] = useState([]);
  console.log("🚀 ~ totalNewPaymentReceived ~ amount: exiting", existingPayments)
  const [existingPurchase, setExistingPurchase] = useState([]);

  const [openExistingProductDetails, setOpenExistingProductDetails] = useState(false);
  const [existingProductDetails, SetexistingProductDetails] = useState(null);
  const [selectedProductDetails, SetselectedProductDetails] = useState(null);

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [purchaseInvoiceDeleteOpen, setPurchaseIvoiceDeleteOpen] = useState(false);
  
  console.log("🚀 ~ UpdateOrderLoadingPO ~ deleteOpen:", deleteOpen)
  const [selectedId, setSelectedId] = useState(null);
  const [existingPoCopy, setExistingPOCopy] = useState(null);
  const [existingClientPoCopy, setExistingClientPOCopy] = useState(null);
  const [existingManualCifForm, setExistingManualCifForm] = useState(null);
  
  

  
  const [formData, setFormData] = useState({
    user_type:'',
    order_type:'',
    order_loading_date: '',
    branch: '',
    bd_person: [],
    account: '',
    po_number: '',
    po_date: '',
    po_copy: null,
    client_po_copy: null,
    third_party_account: '',
    billing_address: '',
    billing_gst_number: '',
    shipping_address: '',
    shipping_gst_number: '',
    product_details: [],
    license_contract_manager_name: '',
    license_contract_manager_phone: '',
    license_contract_manager_email_id: '',
    proposed_license_activation_date: null,
    proposed_order_loading_date:null,
    manual_cif_form: null,
    selling_payment_terms: null,
    purchase_payment_terms: null,
    license_activation_date: null,
    license_certificate: null,
    sales_invoice_number: '',
    sales_invoice_date: '',
    sales_invoice_amount_ex_gst: '',
    sales_gst_amount: '',
    tota_sales_invoice_amount_inc_gst: '',
    sales_invoice:null,

    // purchase_from:'',
    // purchase_invoice_number: '',
    // purchase_invoice_date: '',
    // purchase_invoice_amount_ex_gst: '',
    // purchase_gst_amount: '',
    // total_purchase_invoice_amount_inc_gst: '',
    purchase_invoice:[],

    balance_amount: '',
    payment_delayed_by_days: null,
    branch_interest_per_month: '',
    payment:[],
    remarks:''
  });
  console.log("🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀 ~ UpdateOrderLoadingPO ~ formData:", formData)



  const [productFormData, setProductFormData] = useState({
    product_master: null,
    quantity: '',
    selling_amount: '',
    total_selling_amount_exc_gst: '',
    purchase_amount: '',
    total_purchase_amount_exc_gst: '',
    total_acv_amount_exc_gst: '',
    sgst_amount: '',
    cgst_amount: '',
    igst_amount: '',
    remarks: ''
  });

  const [purchaseInvoiceFormData, setPurchaseInvoiceFormData] = useState({
    purchase_from: null, // this will be a reference to the SoldTo model (foreign key)
    purchase_invoice_number: '',
    purchase_invoice_date: '',
    purchase_invoice_amount_ex_gst: '',
    purchase_gst_amount: '',
    total_purchase_invoice_amount_inc_gst: '',
    purchase_invoice: null, // this will handle the file upload
  });

  

  const [productMaster, setProductMaster] = useState('');
  const [productMasterLabel, setProductMaster_label] = useState('');
  
  const [quantity, setQuantity] = useState('');
  const [sellingAmount, setSellingAmount] = useState('');
  const [sgstAmount, setSGSTAmount] = useState('');
  const [cgstAmount, setCGSTAmount] = useState('');
  const [igstAmount, setIGSTAmount] = useState('');
  const [purchaseAmount, setPurchaseAmount] = useState('');
  const [totalSellingAmount, setTotalSellingAmount] = useState(0);
  const [totalPurchaseAmount, setTotalPurchaseAmount] = useState(0);
  const [totalAcvAmount, setTotalAcvAmount] = useState(0);
  const [dependency, SetDependency] = useState(false);

  const [payments, setPayments] = useState([]);
  console.log("🚀 ~ totalNewPaymentReceived ~ amount: payments:", payments)
  const [purchases, setPurchases] = useState([]);
  
  const [paymentNo, setPaymentNo] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [paymentReceivedAmount, setPaymentReceivedAmount] = useState('');
  const [paymentDate, setPaymentDate] = useState('');
  const [paymentRemarks, setPaymentRemarks] = useState('');

  const [purchaseFrom, setPurchaseFrom] = useState('');
  const [purchaseInvoiceNumber, setPurchaseInvoiceNumber] = useState('');
  const [purchaseInvoiceDate, setPurchaseInvoiceDate] = useState('');
  const [purchaseInvoiceAmountExGST, setPurchaseInvoiceAmountExGST] = useState('');
  const [purchaseGstAmount, setPurchaseGstAmount] = useState('');
  const [totalPurchaseInvoiceAmountIncGST, setTotalPurchaseInvoiceAmountIncGST] = useState('');
  const [purchaseInvoice, setPurchaseInvoice] = useState(null);


  const [showLicenceDate, setShowLicenceDate] = useState('');

  const [accountPopup, showAccountPopup] = useState(false);
  const [backPopup, showBackPopup] = useState(false);

  const [allThirdPartyAccount, SetAllThirdPartyAccount] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [GSTPercent, setGSTPercent] = useState(null);
  

  const transformApiResponse = (data) => {
      return data.map(account => ({
        "id": account.id,
        "csn": account?.csn ?? null,
        "email": account?.contract_manager[0]?.email ?? null,
        "phone": account?.contract_manager[0]?.phone ?? null,
        "name": account?.name ?? "",
        "industryGroup": account?.industryGroup ?? null,
        "industrySegment": account?.industrySegment ?? null,
        "industrySubSegment": account?.industrySubSegment ?? null,
        "address1": account?.address1 ?? null,
        "city": account?.city ?? null,
        "status": account?.status ?? null,
        "contract_status": account?.contract_status ?? null,
        "buyingReadinessScore": account?.buyingReadinessScore ?? null,
        "branch":account?.branch_name ?? null,
        "branch_object":account?.branch ?? null,
        "user_assign":account?.user_assign_firstname ?? null,
        "renewal_person":account?.renewal_person_firstname ?? null,
        // "renewal_person_object":account?.renewal_person ?? null,
        // "user_assign_object":account?.user_assign ?? null,
      }));
    };
  
  

  useEffect(() => {
    

    getOrderLoadingData();
  }, [id, token]);

  const getOrderLoadingData = () => {
    axios.get(`${BACKEND_BASE_URL}v1/product/order_loading_po/${id}`, { headers })
      .then((response) => {
        if (response.status === 200) {
          setFormData(response?.data?.order_loading_po);
          setSelectedUsers(response.data.order_loading_po?.bd_person || []);
          setExistingPayment(response?.data?.order_loading_po?.payment);
          setExistingPurchase(response?.data?.order_loading_po?.purchase_invoice);
          SetexistingProductDetails(response?.data?.order_loading_po?.product_details)
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setExistingPOCopy(response?.data?.order_loading_po?.po_copy)
          setExistingClientPOCopy(response?.data?.order_loading_po?.client_po_copy)
          setExistingManualCifForm(response?.data?.order_loading_po?.manual_cif_form)
        } else {
          toast.error("Error occurred while displaying the product.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        setError(error?.response?.data?.Response?.message);
        toast.error("Error occurred while displaying the product.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };



  useEffect(() => {
      if (token){
      GetAllProjectMaster();
      // GetAllUser();
      GetAllBranch();
      GeteAllSoldTo()
      // eslint-disable-next-line
      }
  }, [token]);

  
  const [todoList, setTodoList] = useState(() => {
    return JSON.parse(localStorage.getItem("payments")) || []
  });
    
  useEffect(() => {
    localStorage.removeItem('payments');
  }, [todoList]);


  useEffect(() => {
    if (token){
    GetAllProjectDetails();
    }
  }, [token, dependency]);

  useEffect(() => {
    if (formData.sales_invoice_amount_ex_gst){
      CalculateGSTSalesAmount();
    }

  }, [formData.sales_invoice_amount_ex_gst, payments]);

  const CalculateGSTSalesAmount = () => {
    // const payments = JSON.parse(localStorage.getItem('payments')) || [];
    // const existingPayments = [] // This should be retrieved from your backend

    if (formData.sales_invoice_amount_ex_gst) {
      console.log("🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀 else ");
        const gstAmount = (formData.sales_invoice_amount_ex_gst * 18) / 100;
        const totalSalesAmount = parseFloat(formData.sales_invoice_amount_ex_gst) + parseFloat(gstAmount);

        const totalNewPaymentReceived = payments.reduce((sum, payment) => {
          console.log("🚀 ~ totalNewPaymentReceived ~ amount:");
          const amount = parseFloat(payment.payment_received_amount) || 0;
          console.log("🚀 ~ totalNewPaymentReceived ~ amount:", amount)
          return sum + amount;
        }, 0);

        const totalExistingPaymentReceived = existingPayments.reduce((sum, payment) => {
            const amount = parseFloat(payment.payment_received_amount) || 0;
            console.log("🚀 ~ totalExistingPaymentReceived ~ amount:", amount)
            return sum + amount;
        }, 0);

        // Total payments received 
        const totalPaymentReceived = totalNewPaymentReceived + totalExistingPaymentReceived;
        console.log("🚀 ~ totalPaymentReceived:", totalPaymentReceived);

        // Calculate remaining amount
        const remainingAmount = totalSalesAmount - totalPaymentReceived;

        // Update formData with required amounts
        setFormData(prevFormData => ({
            ...prevFormData,
            sales_gst_amount: gstAmount,
            tota_sales_invoice_amount_inc_gst: totalSalesAmount,
            balance_amount: remainingAmount
        }));
    }
    else{
      console.log("🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀 else ");
      
    //   setFormData(prevFormData => ({
    //     ...prevFormData,
    //     sales_gst_amount: formData?.sales_gst_amount || 0,
    //     tota_sales_invoice_amount_inc_gst: formData.tota_sales_invoice_amount_inc_gst || 0,
    //     balance_amount: formData.balance_amount || 0
    // }));
    }
}

    // const CalculateGSTSalesAmount = () => {
    // const payments = JSON.parse(localStorage.getItem('payments')) || [];
    // if (formData.sales_invoice_amount_ex_gst){
    //   const gstAmount = (formData.sales_invoice_amount_ex_gst * 18) / 100;
    //   const totalSalesAmount = parseInt(formData.sales_invoice_amount_ex_gst) + parseInt(gstAmount);

    //   if (payments){
    //     const totalnewpaymentReceived = payments.reduce((sum, payment) => {
    //       const amount = parseFloat(payment.payment_received_amount) || 0;
    //       return sum + amount;
    //     }, 0);
        
    //     console.log("🚀 ~ totalnewpaymentReceived ~ totalnewpaymentReceived:", totalnewpaymentReceived)
  
    //     if (existingPayments){
    //       const totalexistingpaymentReceived = existingPayments.reduce((sum, payment) => {
    //         const amount = parseFloat(payment.payment_received_amount) || 0;
    //         return sum + amount;
    //       }, 0);
    //       console.log("🚀 ~ totalexistingpaymentReceived ~ totalexistingpaymentReceived:", totalexistingpaymentReceived)
    //     }
    //     const sum_all_payment_received = totalnewpaymentReceived + totalexistingpaymentReceived;
    //   const remainingAmount = totalSalesAmount - sum_all_payment_received

    //   // Update formData with the remaining amount
    //     setFormData(prevFormData => ({
    //       ...prevFormData,
    //       sales_gst_amount: gstAmount
    //       }));
    //     setFormData(prevFormData => ({
    //       ...prevFormData,
    //       tota_sales_invoice_amount_inc_gst: totalSalesAmount
    //       }));
    //     setFormData(prevFormData => ({
    //       ...prevFormData,
    //       balance_amount: remainingAmount
    //       }));
    //   }else{
    //     // setFormData(prevFormData => ({
    //     //   ...prevFormData,
    //     //   tota_sales_invoice_amount_inc_gst: totalSalesAmount
    //     //   }));
    //   }
    // }
    // }
  
  useEffect(() => {
    if (payments.length === 0){
      CalculateBalanced();
    }

  }, [formData.total_purchase_invoice_amount_inc_gst, payments]);
  

  const GetAllProjectDetails = () => {
    axios.get(BACKEND_BASE_URL + `v1/product/product_details/`, { headers })
      .then((response) => {
        if (response.status === 200) {
          SetAllProjectDetails(response?.data?.product_details);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        
        setIsLoading(false)
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });
  };

  const GetAllProjectMaster = () => {
    axios.get(BACKEND_BASE_URL + `v1/product/product_master/`, { headers })
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response)
        if (response.status === 200) {
          SetAllProjectMaster(response?.data?.product_master);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        
        setIsLoading(false)
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });
  };

  // const GetAllUser = () => {
  // // setStatus("All Status");
  // axios.get(BACKEND_BASE_URL + `v1/get_user/`, { headers })
  //     .then((response) => {
  //     if (response.status === 200) {
  //         SetAllUser(response?.data.User);
  //         setError(null);
  //         toast.success("Data Loaded", {
  //         position: toast.POSITION.TOP_RIGHT,
  //         });
  //     } else {
  //         toast.error("Error occured while displaying accounts.", {
  //         position: toast.POSITION.TOP_RIGHT,
  //         });
  //     }
  //     })
  //     .catch(() => {
  //     setError(error?.response.data.Response.message);
      
  //     setIsLoading(false)
  //     toast.error("Error occured while displaying accounts.", {
  //         position: toast.POSITION.TOP_RIGHT,
  //     });
      
  //     });
  // };

  const GetAllBranch = () => {
  // setStatus("All Status");
  axios.get(BACKEND_BASE_URL + `v1/api/get_all_branch/`, { headers })
      .then((response) => {
      if (response.status === 200) {
          SetAllBranch(response?.data?.Branch);
          const data = response?.data?.Branch.map(item => {
          const name = item.branch_name;
          const value = item.id;
          return {
              label: name,
              value: value,
          };
          });
          const uniqueLabels = new Set();
          setError(null);
          toast.success("Data Loaded", {
          position: toast.POSITION.TOP_RIGHT,
          });
      } else {
          toast.error("Error occured while displaying  sdasDSDASF accounts.", {
          position: toast.POSITION.TOP_RIGHT,
          });
      }
      })
      .catch(() => {
      setError(error?.response.data.Response.message);
      
      toast.error("Error occured while displaying  DSFDSFDS F accounts.", {
          position: toast.POSITION.TOP_RIGHT,
      });
      
      });
  };

  const GeteAllSoldTo = () => {
    // setStatus("All Status");
    axios.get(BACKEND_BASE_URL + `v1/api/get_sold_to/`, { headers })
        .then((response) => {
        if (response.status === 200) {
            SetAllSoldTo(response?.data?.sold_to);
            const data = response?.data?.sold_to.map(item => {
            const name = item.name;
            const value = item.id;
            return {
                label: name,
                value: value,
            };
            });
            const uniqueLabels = new Set();
            setError(null);
            toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            toast.error("Error occured while displaying  sdasDSDASF accounts.", {
            position: toast.POSITION.TOP_RIGHT,
            });
        }
        })
        .catch(() => {
        setError(error?.response.data.Response.message);
        
        toast.error("Error occured while displaying  DSFDSFDS F accounts.", {
            position: toast.POSITION.TOP_RIGHT,
        });
        
        });
    };


  useEffect(() => {
    if (token){
    // ExportAccounts();
    ThirdPartyAccounts();
    // eslint-disable-next-line
    }
  }, [csn, token]);

  // const ExportAccounts = () => {
  // console.log("___________________done ");
  // setIsLoading(true)
  // // setStatus("All Status");
  // const apiUrl = `${BACKEND_BASE_URL}v1/api/account_dropdown/${csn ? `${csn}` : ''}`;
  // axios.get(apiUrl, { headers })
  //     .then((response) => {
  //     if (response.status === 200) {
  //         const transformedData = transformApiResponse(response?.data.accounts);
  //         // SetAllAccountData(transformedData);
  //         setIsLoading(false)
  //         setError(null);
  //         // setSearchBranch(null)
  //         // setLastUpdated(response?.data?.last_updated)
  //         toast.success("Data Loaded", {
  //         position: toast.POSITION.TOP_RIGHT,
  //         });
  //     } else {
  //         toast.error("Error occured while displaying accounts.", {
  //         position: toast.POSITION.TOP_RIGHT,
  //         });
  //     }
  //     })
  //     .catch(() => {
  //     setIsLoading(false)
  //     setError(error?.response.data.Response.message);
  //     toast.error("Error occured while displaying accounts.", {
  //         position: toast.POSITION.TOP_RIGHT,
  //     });
  //     });
  // };

  const ThirdPartyAccounts = () => {
    console.log("___________________done ");
    setIsLoading(true)
    // setStatus("All Status");
    const apiUrl = `${BACKEND_BASE_URL}v1/api/get_third_party_accounts/${csn ? `${csn}` : ''}`;
    axios.get(apiUrl, { headers })
        .then((response) => {
        if (response.status === 200) {
            const transformedData = transformApiResponse(response?.data.accounts);
            SetAllThirdPartyAccount(transformedData);
            setIsLoading(false)
            setError(null);
            // setSearchBranch(null)
            // setLastUpdated(response?.data?.last_updated)
            toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
            });
        }
        })
        .catch(() => {
        setIsLoading(false)
        setError(error?.response.data.Response.message);
        toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
        });
        });
    };

  


  const handleFileChange = (e) => {
      const { name, files } = e.target;
      setFormData({
        ...formData,
        [name]: files[0],
      });
  };
  

  const handleMultipleSelectChange = (selectedOptions) => {
    const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setFormData(prevData => ({
      ...prevData,
      product_details: selectedValues
    }));
  };

  const handleProductDetailsChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map(option => option.value);
    setFormData(prevData => ({
      ...prevData,
      product_details: selectedIds, // Array of selected IDs
    }));
  };

  
  const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
  };

//   useEffect(() => {
//     setFormData(prevFormData => ({
//         ...prevFormData,
//         user_type: formData?.user_type,
//     }));
// }, [formData?.user_type]);

  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
  };

  const handleProductUpdateChange = (field, value) => {
    setProductFormData(prevState => ({
      ...prevState,
      [field]: value
    }));
  };
  

  const handlePurchaseInvoiceChange = (field, value) => {
    setPurchaseInvoiceFormData(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const handleInvoiceChange = (e) => {
    const { name, files } = e.target;
    setPurchaseInvoiceFormData({
      ...formData,
      [name]: files[0],
    });
  };


  const handleSelectPurchaseFromChange = (selectedOption) => {
    console.log("🚀 ~ handleSelectProductUpadeChange ~ selectedOption:", selectedOption)
    
    setPurchaseInvoiceFormData(prevData => ({
        ...prevData,
        purchase_from: selectedOption
    }));
    };


  const GetSelectedProductMaster = (product_master_id) => {
    axios.get(BACKEND_BASE_URL + `v1/product/product_master/${product_master_id}`, { headers })
      .then((response) => {
        if (response.status === 200) {
          setSelectedProductMaster(response.data.product_master || []);  // Set default value as array

          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occurred while fetching account data.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        setError(error?.response?.data?.message);
        toast.error("Error occurred while fetching account data.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };


  useEffect(() => {
    if (token){
      if(productFormData.product_master){
        GetSelectedProductMaster(productFormData.product_master)
      }
    }
  }, [token ,productFormData.product_master]);
  
  

  const handleSelectProductUpadeChange = (selectedOption) => {
    console.log("🚀 ~ handleSelectProductUpadeChange ~ selectedOption:", selectedOption)
    
    setProductFormData(prevData => ({
        ...prevData,
        product_master: selectedOption
    }));
    GetSelectedProductMaster(selectedOption)
    };

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    if (name === 'account'){
      showAccountPopup(true);
    }
    setFormData(prevData => ({
        ...prevData,
        [name]: selectedOption?.value || ''
    }));
  };


  useEffect(() => {
    if (token) {
      if (formData.branch) {
        if (formData.order_type) {
          handleBranch(formData.branch);
        } else {
          toast.error("Please select order type first and then select branch", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [token, formData.branch, formData.order_type,]);

  const handleBranch = (electedOption) => {
    console.log("________________hasnding calling brnach",electedOption);
    console.log("________________hasnding calling brnach",electedOption?.value);

    if (electedOption === null){
      console.log("________________________________________null proper");
      setFormData(prevData => ({
        ...prevData,
        bd_person: '', // Array of selected IDs
      }));
      setFormData(prevData => ({
        ...prevData,
        account: '', // Array of selected IDs
      }));
      setFormData(prevData => ({
        ...prevData,
        branch: '', // Array of selected IDs
      }));
      SetAllUser(null);
      SetAllAccountData([])
      
    }

    if (electedOption?.value){
      handleSelectChange(electedOption, { name: 'branch' })
      setFormData(prevData => ({
        ...prevData,
        bd_person: '', // Array of selected IDs
      }));
      setFormData(prevData => ({
        ...prevData,
        account: '', // Array of selected IDs
      }));
    }
    axios.get(BACKEND_BASE_URL + `v1/api/get_bd_person_by_branch/${electedOption?.value ? `${electedOption?.value}` : `${electedOption}`}/${formData.order_type}`, { headers })      .then((response) => {
      if (response.status === 200) {
          SetAllUser(response?.data.bd_person);
          SetStateCode(response?.data.state_code)
          setError(null);
          toast.success("Data Loaded", {
          position: toast.POSITION.TOP_RIGHT,
          });
      } else {
          toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
          });
      }
      })
      .catch(() => {
      setError(error?.response.data.Response.message);
      
      setIsLoading(false)
      toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
      });
      
      });

      GetGSTPercent()
  }
 
  const GetGSTPercent = () => {
    if (formData.billing_gst_number) {
      const gstStateCode = formData.billing_gst_number.substring(0, 2);
      if (parseInt(gstStateCode) === parseInt(stateCode)) {
        setGSTPercent(9)
      } else {
        setGSTPercent(18)
      }
      
    }
  }


  useEffect(() => {
    GetGSTPercent()
  }, [formData.billing_gst_number, stateCode]);

  

  useEffect(() => {
    if (token){
      console.log("__________________start if");
    if (formData.bd_person){
      console.log("__________________if");
      
      handleBDPerson(formData.bd_person)
    }
    // eslint-disable-next-line
    }
  }, [token, formData.bd_person]);

  const handleBDPerson = (selectedOptions) => {
    console.log("🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀 bd person ~ handleBDPerson ~ selectedOptions:", selectedOptions);
    if (selectedOptions.length !== 0) {
     
      
      setFormData(prevData => ({
        ...prevData,
        bd_person: selectedOptions// Maintain the full object structure
      }));
      const selectedIds = selectedOptions?.map(option => option.value);
      console.log("🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀 ~ handleBDPerson ~ selectedIds:", selectedIds)
      const idsString = selectedIds?.join(',');
      console.log("🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀 ~ handleBDPerson ~ idsString:", idsString)
      
      axios.get(BACKEND_BASE_URL + `v1/api/get_accounts_by_bd_person/${idsString}/${formData.order_type}`, { headers })
        .then((response) => {
          if (response.status === 200) {
            // const transformedData = transformApiResponse(response?.data.accounts);
            SetAllAccountData(response?.data.accounts);
            // const thirdPartytransformedData = transformApiResponse(response?.data.third_party_acccounts);
            SetAllThirdPartyAccount(response?.data.third_party_acccounts);
            setError(null);
            toast.success("Data Loaded", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Error occurred while displaying accounts.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          setError(error?.response?.data?.Response?.message);
          setIsLoading(false);
          toast.error("Error occurred while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
  
    }
    else{
      setFormData(prevData => ({
        ...prevData,
        bd_person: '',
      }));
      setFormData(prevData => ({
        ...prevData,
        account: '', 
      }));
    }
  };
  
  
  

  const handleConfirmAccount = (confirm) => {
    if (confirm) {
      // setFormData({ ...formData, account: selectedAccount.value });
    } else {
      // setSelectedAccount(null);
      setFormData(prevData => ({
        ...prevData,
        ['account']: ''
    }));
    }
    showAccountPopup(false);
  };


  const handleBackButtonClick = () => {
    showBackPopup(true);  // Show the back confirmation popup
  };


  const handleConfirmBack = (confirm) => {
    console.log("+++++++++++++++++++++++++++++++++++++++");
    
    if (confirm) {
      localStorage.removeItem('productDetails');
      localStorage.removeItem('payments');
      navigate('/order_loading_po_list');
    }
    showBackPopup(false);
  };

  const handleSelectLicenceChange = (e) => {
    const { name, value } = e.target;
    setShowLicenceDate(value)
      setFormData({
        ...formData,
        [name]: value,
      });
  };

  const AddProductDetails = () => {
    setOpenProductDetails(true);
    console.log("__________ Add category __________");
  };


  const AddPurchaseDetails = () => {
    setOpenPurchaseInvoice(true);
  };


  const AddPaymentDetails = () => {
    setOpenPaymentDetails(true);
  };


  const ShowProductDetails = () => {
    setOpen(true)
  };

  const ShowPurchaseInvoice = () => {
    console.log("___________________calling");
    
    setPurchaseOpen(true)
  };


  const ShowPaymentDetails = () => {
    setPaymentOpen(true)
  };


  const handleNavigation = () => {
    const newPath = 'add_product_master'; // Replace with your desired path
    window.open(newPath, '_blank'); // Open in a new tab
  };

  const [email, setEmail] = useState(false);
  const handleSubmit = (e, sendEmail = false ) => {
    console.log("handle submit _________????????????????????????????????-", sendEmail)
    e.preventDefault();
    setIsSubmitting(true);
    const formDataToSend = new FormData();

    Object.keys(formData).forEach((key) => {
        console.log("🚀🚀🚀🚀🚀🚀🚀🚀============ ~ Object.keys ~ key:", key)
        // Skip file fields for now, handle them separately
        if (!['po_copy', 'manual_cif_form', 'client_po_copy', 'license_certificate', 'sales_invoice', 'do_slip'].includes(key)) {
            
            if (key === 'product_details') {
                // Retrieve product_details from local storage
                const savedProductDetails = JSON.parse(localStorage.getItem('productDetails')) || [];
                console.log("🚀 ~ Object.keys ~ savedProductDetails:", savedProductDetails);

                // Check if product_details is an array and not empty
                if (Array.isArray(savedProductDetails) && savedProductDetails.length > 0) {
                    // Convert the array to JSON string
                    const productDetailsJson = JSON.stringify(savedProductDetails);
                    console.log("Product Details JSON:", productDetailsJson);
                    // Append to formDataToSend
                    formDataToSend.append(key, productDetailsJson);
                } else {
                    console.error('product_details should be an array and not empty');
                }
            
            }else if (key === 'purchase_invoice') {
              console.log(" ________________________++++++++++++++++ a ya ya nhi yeh",);
              
                
              if (Array.isArray(formData[key]) && formData[key].length > 0) {
                console.log(" ________________________++++++++++++++++ a ya ya nhi yeh");
                // Extract IDs from the objects
                const ids = formData[key].map(item => item.id); // Adjust 'value' if your object has a different key for ID
                console.log("_________________________ 🚀 ~ Object.keys ~ ids:", ids)
                const idsJson = JSON.stringify(ids);
                console.log(" _________________________ purchase_invoice IDs JSON:", idsJson);
                formDataToSend.append(key, idsJson);
            } else {
                console.error('bd_person should be an array and not empty');
            }
          
          } else if (key === 'payment') {
              console.log("_________________gggggggggggg_");
              
                
                const savedPayments = JSON.parse(localStorage.getItem('payments')) || [];
                console.log("🚀 ~ Object.keys ~ savedPayments:", savedPayments);

                // Check if product_details is an array and not empty
                if (Array.isArray(savedPayments) && savedPayments.length > 0) {
                    // Convert the array to JSON string
                    const productDetailsJson = JSON.stringify(savedPayments);
                    console.log("Product Details JSON:", productDetailsJson);
                    // Append to formDataToSend
                    formDataToSend.append(key, productDetailsJson);
                } else {
                    console.error('product_details should be an array and not empty');
                }
            
            } else if (key === 'bd_person') {
                // Handle bd_person as a list (array of IDs)
                if (Array.isArray(formData[key]) && formData[key].length > 0) {
                    // Extract IDs from the objects
                    const ids = formData[key].map(item => item.value); // Adjust 'value' if your object has a different key for ID
                    const idsJson = JSON.stringify(ids);
                    console.log("BD Person IDs JSON:", idsJson);
                    formDataToSend.append(key, idsJson);
                } else {
                    console.error('bd_person should be an array and not empty');
                }
            
            } else {
                // Add non-file fields to FormData if they have a value
                if (formData[key] !== null && formData[key] !== undefined && formData[key] !== '') {
                    formDataToSend.append(key, formData[key]);
                }
            }
        }
    });

    // Handle file fields separately
    ['po_copy', 'manual_cif_form', 'client_po_copy', 'license_certificate', 'sales_invoice', 'do_slip'].forEach(fileKey => {
        if (formData[fileKey] instanceof File) {
            formDataToSend.append(fileKey, formData[fileKey]);
        }
    });

    console.log("_________formDataToSend", formDataToSend);
    const params = sendEmail ? { email: true } : {};
    axios
        .put(BACKEND_BASE_URL + `v1/product/order_loading_po/${id}`, formDataToSend, { headers, params })
        .then((response) => {
            if (response.status === 201) {
                navigate("/order_loading_po_list");
                setError(null);
                toast.success("Product Master created Successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                localStorage.removeItem('productDetails');
                localStorage.removeItem('payments');
            } else {
                toast.error("Error occurred while displaying accounts.", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setIsSubmitting(false);
            }
        })
        .catch((error) => {
            toast.error(error.response.data?.error, {
                position: toast.POSITION.TOP_RIGHT,
            });
            setIsSubmitting(false);
        });
  };

//   const handleEmailSubmit = () => {
//     setIsSubmitting(true);
//     setEmail(true);
//     setTimeout(() => {
//         console.log('Data submitted successfully');
//         setIsSubmitting(false);
//         setEmail(false);
//     }, 2000); // Replace this with your actual API call time
// };


  const [currentId, setCurrentId] = useState(1); // Start with ID 1
  const handleAddProductDetails = () => {
    const productDetail = {
        id: currentId,  
        product_master: productMaster,
        product_master_label:productMasterLabel,
        quantity: quantity,
        selling_amount: sellingAmount,
        total_selling_amount_exc_gst: totalSellingAmount,
        purchase_amount: purchaseAmount,
        total_purchase_amount_exc_gst: totalPurchaseAmount,
        total_acv_amount_exc_gst: totalAcvAmount,
        sgst_amount:sgstAmount,
        cgst_amount:cgstAmount,
        igst_amount:igstAmount
    };

    let existingContracts = JSON.parse(localStorage.getItem('productDetails')) || [];
    existingContracts.push(productDetail);
    localStorage.setItem('productDetails', JSON.stringify(existingContracts));
    SetProductDetails(existingContracts);

    // Increment the ID for the next entry
    setCurrentId(currentId + 1);

    setOpenProductDetails(false);
    resetForm();
    setSelectedProductMaster(null)
  };

  const handleOpenModel = (productdetails_id) => {
    SetselectedProductDetails(productdetails_id);
    
    setUpdateOpen(true);
    axios
      .get(BACKEND_BASE_URL + `v1/product/product_details/${productdetails_id}`, { headers })
      .then((response) => {
        console.log("🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀 ~ .then ~ response:", response?.data)
        if (response.status === 200) {
          setProductFormData(response?.data?.product_details)
          GetSelectedProductMaster(response?.data?.product_details?.product_master)
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying product.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        toast.error("Error occured while displaying product.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });
  };

  const handleUpdateProductDetails = (id) => {
    console.log("_____________________productFormData_i",productFormData);
    
    const requestData = {
        product_master: productFormData?.product_master,
        quantity: productFormData?.quantity,
        selling_amount: productFormData?.selling_amount,
        total_selling_amount_exc_gst: productFormData?.total_selling_amount_exc_gst,
        purchase_amount: productFormData?.purchase_amount,
        total_purchase_amount_exc_gst: productFormData?.total_purchase_amount_exc_gst,
        total_acv_amount_exc_gst: productFormData?.total_acv_amount_exc_gst || null,
        sgst_amount: productFormData?.sgst_amount,
        cgst_amount: productFormData?.cgst_amount,
        igst_amount: productFormData?.igst_amount,
        remarks: productFormData?.remarks
    };

  axios
      .put(BACKEND_BASE_URL + `v1/product/product_details/${id}`, requestData, { headers })
      .then((response) => {
          console.log("🚀 ~ .then ~ response:", response);
          if (response.status === 200) {
              getOrderLoadingData()
              setUpdateOpen(false);
              setError(null);
              toast.success("Update Product Details Successfully", {
                  position: toast.POSITION.TOP_RIGHT,
              });
          } else {
              toast.error("Error occurred while updating product details.", {
                  position: toast.POSITION.TOP_RIGHT,
              });
          }
      })
      .catch((error) => {
          toast.error(error.response.data?.error, {
              position: toast.POSITION.TOP_RIGHT,
          });
      });
  
      setSelectedProductMaster(null)
  };

  const [purchaseId, setPurchaseId] = useState(1); // Start with ID 1

  // const handleAddPurchaseInvoice = () => {
  //   console.log("___________________purchaseInvoice",purchaseInvoice);
    
  //   const purchaseInvoiceDetail = {
  //     id: purchaseId,
  //     purchase_from: purchaseFrom,
  //     purchase_invoice_number: purchaseInvoiceNumber,
  //     purchase_invoice_date: purchaseInvoiceDate,
  //     purchase_invoice_amount_ex_gst: purchaseInvoiceAmountExGST,
  //     purchase_gst_amount: purchaseGstAmount,
  //     total_purchase_invoice_amount_inc_gst: totalPurchaseInvoiceAmountIncGST,
  //     purchase_invoice: purchaseInvoice // Assuming this is a file or URL
  //   };
  
  //   // Retrieve existing invoices from localStorage or initialize as an empty array
  //   let existingInvoices = JSON.parse(localStorage.getItem('purchaseInvoices')) || [];
    
  //   // Add the new purchase invoice detail to the existing array
  //   existingInvoices.push(purchaseInvoiceDetail);
  
  //   // Save the updated array back to localStorage
  //   localStorage.setItem('purchaseInvoices', JSON.stringify(existingInvoices));
  
  //   // Update state to reflect changes
  //   setPurchases(existingInvoices);
  //   setPurchaseId(purchaseId + 1);

    
  
  //   // Increment the ID for the next entry
  
  //   // Close the modal and reset form fields
  //   setOpenPurchaseInvoice(false);
  //   resetPurchaseForm();
  // };
  const [purchaseInvoiceFiles, setPurchaseInvoiceFiles] = useState({}); // To temporarily store files
  const handleAddPurchaseInvoice = () => {
    const purchaseInvoiceDetail = {
      // id: purchaseId,
      purchase_from: purchaseInvoiceFormData.purchase_from,
      purchase_invoice_number: purchaseInvoiceNumber,
      purchase_invoice_date: purchaseInvoiceDate,
      purchase_invoice_amount_ex_gst: purchaseInvoiceAmountExGST,
      purchase_gst_amount: purchaseGstAmount,
      total_purchase_invoice_amount_inc_gst: totalPurchaseInvoiceAmountIncGST,
      purchase_invoice: purchaseInvoice
    };
    

    axios
      .post(BACKEND_BASE_URL + `v1/product/purchase_invoice/`, purchaseInvoiceDetail, { headers })
      .then((response) => {
          console.log("🚀 ~ .then ~ response:", response.data.id);
          if (response.status === 201) {
              const newPurchases = Array.isArray(response?.data) ? response.data : [response.data];
              setPurchases((prevPurchases) => [...prevPurchases, ...newPurchases]);
              setFormData((prevData) => ({
                ...prevData,
                purchase_invoice: [
                    ...(prevData.purchase_invoice || []), // Keep existing purchase_invoice data
                    ...newPurchases  // Append new purchases
                ]
            }));
              // setPurchases(esponse?.data.id);
              // getOrderLoadingData()
              setUpdateOpen(false);
              setError(null);
              toast.success("Update Product Details Successfully", {
                  position: toast.POSITION.TOP_RIGHT,
              });
          } else {
              toast.error("Error occurred while updating product details.", {
                  position: toast.POSITION.TOP_RIGHT,
              });
          }
      })
      .catch((error) => {
          toast.error(error.response.data?.error, {
              position: toast.POSITION.TOP_RIGHT,
          });
      });
      
    setPurchaseId(purchaseId + 1);
  
    setOpenPurchaseInvoice(false);
    resetPurchaseForm();
  };

  const handleUpdatePurhcaseModel = (purchase_invoice_id) => {
    setSelectedPurchaseInvoice(purchase_invoice_id);

    setOpenUpdatePurchaseInvoice(true);
      axios
        .get(BACKEND_BASE_URL + `v1/product/purchase_invoice/${purchase_invoice_id}`, { headers })
        .then((response) => {
          console.log("🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀 ~ .then ~ response:", response?.data)
          if (response.status === 200) {
            setPurchaseInvoiceFormData(response?.data?.purchase_invoices)
            setError(null);
            toast.success("Data Loaded", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Error occured while displaying invoice.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(() => {
          setError(error?.response.data.Response.message);
          toast.error("Error occured while displaying product.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          
        });
    };


  const handleUpdatePurchaseInvoice = (e) => {
  e.preventDefault(); // Prevent the default form submission behavior
  
  const purchaseInvoiceDetail = {
    purchase_from: purchaseInvoiceFormData.purchase_from,
    purchase_invoice_number: purchaseInvoiceFormData.purchase_invoice_number,
    purchase_invoice_date: purchaseInvoiceFormData.purchase_invoice_date,
    purchase_invoice_amount_ex_gst: purchaseInvoiceFormData.purchase_invoice_amount_ex_gst,
    purchase_gst_amount: purchaseInvoiceFormData.purchase_gst_amount,
    total_purchase_invoice_amount_inc_gst: purchaseInvoiceFormData.total_purchase_invoice_amount_inc_gst,
  };

  if (purchaseInvoiceFormData.purchase_invoice instanceof File) {
    purchaseInvoiceDetail.purchase_invoice = purchaseInvoiceFormData.purchase_invoice;
  } else {
    // If no new file, keep the existing file (do nothing)
    console.log("No new file selected, old file remains.");
  }

  axios
    .put(`${BACKEND_BASE_URL}v1/product/purchase_invoice/${selectedPurchaseInvoice}`, purchaseInvoiceDetail, { headers })
    .then((response) => {
      console.log("🚀 ~ .then ~ response:", response.data);

      if (response.status === 200 || response.status === 201) {
        getOrderLoadingData()
        setOpenUpdatePurchaseInvoice(false);
        toast.success("Purchase Invoice Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        resetPurchaseForm(); // Reset the form after a successful update
      } else {
        toast.error("Failed to update Purchase Invoice", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
    .catch((error) => {
      toast.error(error?.response?.data?.error || "An error occurred", {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
};

  
  


  const [paymentId, setPaymentId] = useState(1); // Start with ID 1
  const handleAddPaymentDetail = () => {
    const paymentDetail = {
        id: paymentId,  // Use the auto-incremented ID
        payment_no: paymentNo,
        payment_method: paymentMethod,
        payment_status: paymentStatus,
        payment_received_amount: paymentReceivedAmount,
        payment_date: paymentDate,
        remarks: paymentRemarks
    };

    let existingPayments = JSON.parse(localStorage.getItem('payments')) || [];
    existingPayments.push(paymentDetail);
    localStorage.setItem('payments', JSON.stringify(existingPayments));
    setPayments(existingPayments);

    // Increment the ID for the next entry
    setPaymentId(paymentId + 1);
    setOpenPaymentDetails(false);
    resetPaymentForm()

    CalculateBalanced()

  };

  const CalculateBalanced = () => {
    const payments = JSON.parse(localStorage.getItem('payments')) || [];

    if (formData.total_purchase_invoice_amount_inc_gst){
      if (payments){
      const totalPaymentReceived = payments.reduce((sum, payment) => {
          const amount = parseFloat(payment.payment_received_amount) || 0;
          return sum + amount;
      }, 0);

      if (existingPayments){
        const totalPaymentReceived = existingPayments.reduce((sum, payment) => {
          const amount = parseFloat(payment.payment_received_amount) || 0;
          return sum + amount;
      }, 0);
      }

      const remainingAmount = formData.total_purchase_invoice_amount_inc_gst - totalPaymentReceived;

      // Update formData with the remaining amount
        setFormData(prevFormData => ({
          ...prevFormData,
          balance_amount: remainingAmount
          }));
      }else{
        setFormData(prevFormData => ({
          ...prevFormData,
          balance_amount: formData.total_purchase_invoice_amount_inc_gst
          }));
      }
    }
    }


  
  const handleProductMaster = (project_master_id, project_master_label ) => {
    setProductMaster(project_master_id);
    setProductMaster_label(project_master_label);
    axios.get(BACKEND_BASE_URL + `v1/product/product_master/${project_master_id}`, { headers })
      .then((response) => {
        if (response.status === 200) {
          setSelectedProductMaster(response.data.product_master || []);  // Set default value as array

          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occurred while fetching account data.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        setError(error?.response?.data?.message);
        toast.error("Error occurred while fetching account data.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const branchOptions = allBranch?.map(branch => ({
    value: branch.id,
    label: branch.branch_name
  })) || [];
  console.log("_______________________________________ ~ branchOptions ~ branchOptions:", branchOptions)
  console.log("_______________________________________ ~ branchOptions ~ formData:", formData.branch)
  
  const userOptions = allUser?.map(user => ({
    value: user.id,
    label: `${user.first_name} ${user.last_name}`
    // label: user.email
  })) || [];
  console.log("_______________________________________ ~ branchOptions ~ formData:", userOptions)


  const accountOptions = allaccountdata?.map(account => ({
      value: account.id,
      // label: account.name,
      label: `${account.name} (${account.csn})`,
  })) || [];

  const thirdPartyAccountOptions = allThirdPartyAccount?.map(account => ({
    value: account.id,
    // label: account.name,
    label: `${account.name} (${account.csn})`,
})) || [];

  const projectDetailoptions = allProjectDetails?.map(detail => ({
    value: (detail.id),
    label: detail.product_master,
  })) || [];

  const projectMasteroptions = allProjectMaster?.map(item => ({
    value: item.id,
    label: item.name,
  })) || [];




  const soldToOptions = allSoldTo?.map(sold_to => ({
    value: sold_to.id,
    label: `${sold_to.name} (${sold_to.csn})`
  })) || [];


  useEffect(() => {
    if (productMaster){
      calculateTotals();
      }
    }, [sellingAmount, purchaseAmount, quantity]);

  const calculateTotals = () => {
    console.log("Selling Amount:", sellingAmount);
    console.log("Quantity:", quantity);

    if (productMaster) {
        const totalSellingAmount = parseFloat(quantity) * parseFloat(sellingAmount);
        console.log("🚀 ~ calculateTotals ~ totalSellingAmount:", totalSellingAmount);
        const totalPurchaseAmount = parseFloat(quantity) * parseFloat(purchaseAmount);
        console.log("🚀 ~ calculateTotals ~ totalPurchaseAmount:", totalPurchaseAmount);
        const totalAcvAmount = parseFloat(quantity) * (selectedProductMaster?.acv_price || 1);
        console.log("🚀 ~ calculateTotals ~ totalAcvAmount:", totalAcvAmount);
        setTotalSellingAmount(totalSellingAmount);
        setTotalPurchaseAmount(totalPurchaseAmount);
        setTotalAcvAmount(totalAcvAmount);
        if (GSTPercent === 18){
          // const gst_value = totalSellingAmount * 18 / 100
          const gst_value = parseFloat(sellingAmount) * 18 / 100
          setSGSTAmount(0);
          setCGSTAmount(0);
          setIGSTAmount(gst_value);
        } 
        else{
          const gst_value = parseFloat(sellingAmount) * 9 / 100
          setSGSTAmount(gst_value);
          setCGSTAmount(gst_value);
          setIGSTAmount(0);
        }
    }
  };


useEffect(() => {
  console.log("🚀 ~ calculateTotals ~ totalAcvAm paile ",productFormData.product_master);
  
  if (productFormData.product_master){
    console.log("🚀 ~ calculateTotals ~ totalAcvAmount : baadme");
    
    calculateUpdateTotals();
    }
  }, [productFormData.selling_amount, productFormData.purchase_amount, productFormData.quantity]);

  const calculateUpdateTotals = () => {

    if (selectedProductMaster) {
        const totalSellingAmount = parseFloat(productFormData.quantity) * parseFloat(productFormData.selling_amount);
        console.log("🚀 ~ calculateTotals ~ totalSellingAmount:", totalSellingAmount);
        const totalPurchaseAmount = parseFloat(productFormData.quantity) * parseFloat(productFormData.purchase_amount);
        console.log("🚀 ~ calculateTotals ~ totalPurchaseAmount:", totalPurchaseAmount);
        const totalAcvAmount = parseFloat(productFormData.quantity) * (selectedProductMaster?.acv_price || 1);
        console.log("🚀 ~ calculateTotals ~ totalAcvAmount:", totalAcvAmount);
        setProductFormData(prevData => ({
          ...prevData,
          total_selling_amount_exc_gst: totalSellingAmount
        }));
        setProductFormData(prevData => ({
          ...prevData,
          total_purchase_amount_exc_gst: totalPurchaseAmount
        }));
        setProductFormData(prevData => ({
          ...prevData,
          total_acv_amount_exc_gst: totalAcvAmount
        }));
        if (GSTPercent === 18){
          const gst_value = parseFloat(productFormData.selling_amount) * 18 / 100
          setProductFormData(prevData => ({
            ...prevData,
            sgst_amount: 0
          }));
          setProductFormData(prevData => ({
            ...prevData,
            cgst_amount: 0
          }));
          setProductFormData(prevData => ({
            ...prevData,
            igst_amount: gst_value
          }));
        } 
        else{
          const gst_value = parseFloat(productFormData.selling_amount) * 9 / 100
          setProductFormData(prevData => ({
            ...prevData,
            sgst_amount: gst_value
          }));
          setProductFormData(prevData => ({
            ...prevData,
            cgst_amount: gst_value
          }));
          setProductFormData(prevData => ({
            ...prevData,
            igst_amount: 0
          }));
        }
        
    }
  };


  const resetForm = () => {
    setProductMaster('');
    setQuantity('');
    setSellingAmount('');
    setPurchaseAmount('');
    setTotalSellingAmount(0);
    setTotalPurchaseAmount(0);
    setTotalAcvAmount(0);
  };

  const resetPaymentForm = () => {
    console.log("_________-clear form");
    
    setPaymentNo('');
    setPaymentMethod('');
    setPaymentStatus('');
    setPaymentReceivedAmount('');
    setPaymentDate('');
    setPaymentRemarks('');
  }

  const resetPurchaseForm = () => {
    console.log("_________-clear form");
    
    setPurchaseFrom('');
    setPurchaseInvoiceNumber('');
    setPurchaseInvoiceDate('');
    setPurchaseInvoiceAmountExGST('');
    setPurchaseGstAmount('');
    setTotalPurchaseInvoiceAmountIncGST('');
    setPurchaseInvoice(null); // Assuming this is a file input
  };


  const product_master_Columns = [
    {
      field: 'srNo',
      headerName: 'SR No',
      width: 100,
      valueGetter: (params) => params.api.getRowIndex(params.id) + 1, // Generate SR No based on row index
    },
    { field: "product_master_name", headerName: "Product Master", width:300,
      renderCell: (params) => (
        <Tooltip title={params.value || ''}>
          <span className="whitespace-normal break-words overflow-hidden line-clamp-3">
          <div>
          <button
            onClick={() => handleOpenModel(params?.row.id)}
            className="action-button bg-white text-red-600 px-3 py-1 rounded"
          >
            {params.value}
          </button>
        </div>
          </span>
        </Tooltip>
      ),
      // renderCell: (params, index) => (
      //   <div>
      //     <button
      //       onClick={() => handleOpenModel(params?.row.id)}
      //       className="action-button bg-white text-black px-3 py-1 rounded"
      //     >
      //       {params.value}
      //     </button>
      //   </div>
      // ),
     },
    { field: "quantity", headerName: "Quantity" },
    { field: "selling_amount", headerName: "Selling Amount" },
    { field: "total_selling_amount_exc_gst", headerName: "Total Selling Amount" },
    { field: "purchase_amount", headerName: "Purchase Amount" },
    { field: "total_purchase_amount_exc_gst", headerName: "Total Purchase Amount" },
    { field: "total_acv_amount_exc_gst", headerName: "Total ACV Amount" },
    { field: "sgst_amount", headerName: "SGST Amount"},
    { field: "cgst_amount", headerName: "CGST Amount" },
    { field: "igst_amount", headerName: "IGST Amount"},
    {
      field: "actions",
      headerName: "Actionsssssssssss",
      sortable: false,
      renderCell: (params) => (
          <button
              className="text-red-600"
              onClick={() => ShowConfirmDeletePopup(params.row.id)}
          >
              Delete
          </button>
      ),
    },
  ];

  const product_master_local_storage = [
    {
      field: 'srNo',
      headerName: 'SR No',
      width: 100,
      valueGetter: (params) => params.api.getRowIndex(params.id) + 1, // Generate SR No based on row index
    },
    { field: "product_master_label", headerName: "Product Master", width:300,
      // renderCell: (params) => (
      //   <Tooltip title={params.value || ''}>
      //     <span className="whitespace-normal break-words overflow-hidden line-clamp-3">
      //     <div>
      //     <button
      //       onClick={() => handleOpenModel(params?.row.id)}
      //       className="action-button bg-white text-black px-3 py-1 rounded"
      //     >
      //       {params.value}
      //     </button>
      //   </div>
      //     </span>
      //   </Tooltip>
      // ),
     },
    { field: "quantity", headerName: "Quantity" },
    { field: "selling_amount", headerName: "Selling Amount" },
    { field: "total_selling_amount_exc_gst", headerName: "Total Selling Amount" },
    { field: "purchase_amount", headerName: "Purchase Amount" },
    { field: "total_purchase_amount_exc_gst", headerName: "Total Purchase Amount" },
    { field: "total_acv_amount_exc_gst", headerName: "Total ACV Amount" },
    { field: "sgst_amount", headerName: "SGST Amount"},
    { field: "cgst_amount", headerName: "CGST Amount" },
    { field: "igst_amount", headerName: "IGST Amount"},
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => (
          <button
              className="text-red-600"
              onClick={() => handleDeleteRow(params.row.id)}
          >
              Delete
          </button>
      ),
    },
  ];

  const payment_columns = [
    { field: "payment_no", headerName: "Payment No", flex: 1 },
    { field: "payment_method", headerName: "Payment Method", flex: 1 },
    { field: "payment_status", headerName: "Payment Status", flex: 1 },
    { field: "payment_received_amount", headerName: "Received Amount", flex: 1 },
    { field: "payment_date", headerName: "Payment Date", flex: 1 },
    { field: "remarks", headerName: "Remarks", flex: 1 },
    {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        renderCell: (params) => (
            <button
                className="text-red-600"
                onClick={() => handlePaymentDeleteRow(params.row.id)}
            >
                Delete
            </button>
        ),
    },
    ];

    const purchase_invoice_columns = [
      {"field": "id", "headerName": "ID", "flex": 1},
      {"field": "purchase_from_name", "headerName": "Purchased From", "flex": 1,
        renderCell: (params) => (
          <Tooltip title={params.value || ''}>
            <span className="table-cell-truncate">
              <div>
                <button
                  onClick={() => handleUpdatePurhcaseModel(params?.row.id)}
                  className="action-button bg-white text-black px-3 py-1 rounded"
                >
                  {params.value}
                </button>
              </div>
            </span>
          </Tooltip>
        ),
      },
      {"field": "purchase_invoice_number", "headerName": "Invoice No", "flex": 1},
      {"field": "purchase_invoice_date", "headerName": "Invoice Date", "flex": 1},
      {"field": "purchase_invoice_amount_ex_gst", "headerName": "Amount (Ex GST)", "flex": 1},
      {"field": "purchase_gst_amount", "headerName": "GST Amount", "flex": 1},
      {"field": "total_purchase_invoice_amount_inc_gst", "headerName": "Total Amount (Inc GST)", "flex": 1},
      {"field": "purchase_invoice", "headerName": "Invoice Document", "flex": 1,
        renderCell: (params) => (
          params.value ? <a href={`${params.value}`} target="_blank" rel="noopener noreferrer">View File</a> : "No File"
        ),
      },
      {
        field: "actions",
        headerName: "Actionsssssssssss",
        sortable: false,
        renderCell: (params) => (
            <button
                className="text-red-600"
                onClick={() => ShowConfirmPurchaseInvoiceDeletePopup(params.row.id)}
            >
                Delete
            </button>
        ),
      },
      // {
      //     "field": "actions",
      //     "headerName": "Actions",
      //     "sortable": false,
      //     renderCell: (params) => (
      //       <button
      //           className="text-red-600"
      //           onClick={() => handlePaymentDeleteRow(params.row.id)}
      //       >
      //           Delete
      //       </button>
      //   ),
      // },
  ]


  const handleDeleteRow = (id) => {
    const updatedContracts = productDetails.filter(item => item.id !== id);

    localStorage.setItem('productDetails', JSON.stringify(updatedContracts));
    SetProductDetails(updatedContracts);
  };


  const ShowConfirmPurchaseInvoiceDeletePopup = (id) => {
    console.log("_______________done");
    setSelectedId(id); 
    setPurchaseIvoiceDeleteOpen(true); 
  };

  const handlePurchaseInvoiceConfirm = () => {
    handleExistingPuchaseInvoiceDelete(selectedId); 
    setPurchaseIvoiceDeleteOpen(false); 
    setSelectedId(null); 
  };

  const handlePurchaseInvoiceCancel = () => {
    setPurchaseIvoiceDeleteOpen(false); 
    setSelectedId(null); 
  };

  const handleExistingPuchaseInvoiceDelete = (id) => {
    axios
      .delete(`${BACKEND_BASE_URL}v1/product/purchase_invoice/${id}`, { headers })
      .then((response) => {
        if (response.status === 204) {
          setError(null);
          toast.success("Purchase deleted successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          getOrderLoadingData()
          setOpenExistingPurchaseInvoice(true)
        } else {
          toast.error("Unexpected response from the server.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        setError(error?.response?.data?.message || "An error occurred.");
        toast.error("Error occurred while deleting the product.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  


  const ShowConfirmDeletePopup = (id) => {
    console.log("_______________done");
    setSelectedId(id); 
    setDeleteOpen(true); 
  };

  const handleDeleteConfirm = () => {
    handleExistingProductDelete(selectedId); 
    setDeleteOpen(false); 
    setSelectedId(null); 
  };

  const handleDeleteCancel = () => {
    setDeleteOpen(false); 
    setSelectedId(null); 
  };

  const handleExistingProductDelete = (id) => {
    axios
      .delete(`${BACKEND_BASE_URL}v1/product/product_details/${id}`, { headers })
      .then((response) => {
        if (response.status === 204) {
          setError(null);
          toast.success("Product deleted successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          getOrderLoadingData()
          setOpenExistingProductDetails(true)
        } else {
          toast.error("Unexpected response from the server.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        setError(error?.response?.data?.message || "An error occurred.");
        toast.error("Error occurred while deleting the product.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  




const handlePaymentDeleteRow = (id) => {
  const updatedContracts = payments.filter(item => item.id !== id);
  localStorage.setItem('payments', JSON.stringify(updatedContracts));
  setPayments(updatedContracts);
};

const paymentMethodOptions = [
  { value: 'NEFT', label: 'NEFT' },
  { value: 'RTGS', label: 'RTGS' },
  { value: 'Cheque', label: 'Cheque' },
];

const paymentStatusOptions = [
  { value: 'Paid', label: 'Paid' },
  { value: 'Partial Paid', label: 'Partial Paid' },
  { value: 'Pending', label: 'Pending' },
];

const ShowExistingPurchaseInvoice = () => {
  setOpenExistingPurchaseInvoice(true);
  }

const ShowExistingPaymentDetails = () => {
  setOpenExistingPaymentDetails(true);
  
  };


const ShowExistingProductDetails = () => {
  setOpenExistingProductDetails(true);
};
  

const handleOrderSelectChange = (e) => {
  const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

};

console.log("_____________________________++++++++++++++++++++formData.bd_person",formData.bd_person)
console.log("_____________________________++++++++++++++++++++user",userOptions)
console.log("_____________________________++++++++++++++++++++",userOptions?.filter(option => formData.bd_person.includes(option.value)))

console.log("_____________________________++++++++++++++++++++formData.bd_person:", formData.bd_person, "Type:", typeof formData.bd_person);
console.log("_____________________________++++++++++++++++++++userOptions:", userOptions);

const bdPersonArray = Array.isArray(formData.bd_person)
  ? formData.bd_person.map(person => person.id)  // Extract the ids
  : [];  // Fallback to an empty array if it's not an array

console.log("bdPersonArray (Extracted IDs):", bdPersonArray);

// Filter userOptions based on extracted bdPersonArray
const selectedBDPerson = userOptions?.filter(option => bdPersonArray.includes(option.value));

console.log("_____________________________++++++++++++++++++++final", selectedBDPerson);

    
  return (
    <>
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <Header title="Order Loading PO" subtitle="welcome to you Team" />
      </Box>

      {/* <div className="text-right">   */}
      <div>
        <button
          className="border px-4 py-2 font-[700] text-white bg-blue-500 border-gray-300 rounded"
          onClick={handleBackButtonClick}  // Trigger back confirmation popup
        >
          Back
        </button>
      </div>
      <div className="max-w-[800px] mx-auto mt-8 pb-14 p-6 bg-[#f2f0f0] rounded shadow-md">
      <h2 className="text-2xl font-semibold mb-6">Order Loading PO {formData?.user_type === 'EndUser' ? 'EndUser Form' : formData?.user_type === 'ThirdParty' ? 'Third Party Form' : 'Form'}</h2>
      <form onSubmit={handleSubmit}>
    {/* User Type Field */}
    <div className="mb-4">
        <label htmlFor="user_type" className="block text-gray-700 text-sm font-bold mb-2">User Type</label>
        {/* <select
            id="user_type"
            name="user_type"
            value={formData?.user_type}
            onChange={(event) => {
              setUserType(event.target.value);
            }}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
        >
            <option value="">Select User Type</option>
            <option value="EndUser">EndUser</option>
            <option value="ThirdParty">ThirdParty</option>
        </select> */}
        <input
            type="text"
            id="user_type"
            name="user_type"
            value={formData.user_type}
            disabled
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
        />
    </div>

    {/* Order Loading Date Field */}
    <div className="mb-4">
        <label htmlFor="order_loading_date" className="block text-gray-700 text-sm font-bold mb-2">Order Loading Date</label>
        <input
            type="date"
            id="order_loading_date"
            name="order_loading_date"
            value={formData.order_loading_date}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
        />
    </div>

    <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" >Order Type</label>
        <select
          name="order_type"
          value={formData.order_type}
          onChange={handleOrderSelectChange}
          className="basic-single"
          
          >
          <option value="">Select...</option>
          <option value="New">New</option>
          <option value="Renewal">Renewal</option>
        </select>
      </div>

    {/* Branch Field */}
        {/* <div className="mb-4">
            <label htmlFor="branch" className="block text-gray-700 text-sm font-bold mb-2">Branch</label>
            <Select
                id="branch"
                name="branch"
                options={branchOptions}
                value={branchOptions.find(option => option.value === formData.branch)}
                onChange={(selectedOption) => handleSelectChange(selectedOption, { name: 'branch' })}
                className="basic-single"
                classNamePrefix="select"
                placeholder="Select a Branch"
                isClearable={true} 
            />
        </div> */}

        <div className="mb-4">
            <label htmlFor="branch" className="block text-gray-700 text-sm font-bold mb-2">Branch</label>
            <Select
                id="branch"
                options={branchOptions}
                value={branchOptions.find(option => option.value === formData.branch) || null}
                onChange={(selectedOption) => handleBranch(selectedOption)}
                placeholder="Select a Branch"
                isClearable
                isLoading={!branchOptions.length}
            />
        </div>

        <div className="mb-4">
        <label htmlFor="bd_person" className="block text-gray-700 text-sm font-bold mb-2">BD Person</label>
          {/* <Select
              id="bd_person"
              name="bd_person"
              options={userOptions}
              value={userOptions.find(option => option.value === formData.bd_person)}
              onChange={(selectedOption) => handleBDPerson(selectedOption)}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Select a BD Person"
              required
              isClearable={true} 
              isMulti={true}
            /> */}
            <Select
              id="bd_person"
              name="bd_person"
              options={userOptions}
              // value={userOptions.filter(option => formData.bd_person.some(id => id === option.value))} // Ensure matching structure
              value={formData.bd_person} // Should be the full object structure
              onChange={handleBDPerson}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Select a BD Person"
              required
              isClearable={true}
              isMulti={true} // Enable multiple selection
            />
        </div>

        {/* Account Field */}
        <div className="mb-4">
            <label htmlFor="account" className="block text-gray-700 text-sm font-bold mb-2">Account</label>
            <Select
                id="account"
                name="account"
                options={accountOptions}
                // value={accountOptions.find(option => option.value === formData.account)}
                value={accountOptions.find(option => option.value === formData.account) || null}
                onChange={(selectedOption) => handleSelectChange(selectedOption, { name: 'account' })}
                className="basic-single"
                classNamePrefix="select"
                placeholder="Select an Account"
                isClearable={true} 
            />
        </div>

    {/* PO Number Field */}
    <div className="mb-4">
        <label htmlFor="po_number" className="block text-gray-700 text-sm font-bold mb-2">PO Number</label>
        <input
            type="text"
            id="po_number"
            name="po_number"
            value={formData.po_number}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
        />
    </div>

    {/* PO Date Field */}
    <div className="mb-4">
        <label htmlFor="po_date" className="block text-gray-700 text-sm font-bold mb-2">PO Date</label>
        <input
            type="date"
            id="po_date"
            name="po_date"
            value={formData.po_date}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
        />
    </div>

    {/* PO Copy Field */}
    <div className="mb-4">
        <label htmlFor="po_copy" className="block text-gray-700 text-sm font-bold mb-2">{formData?.user_type === 'EndUser' ? 'PO Copy' : formData?.user_type === 'ThirdParty' ? 'Third Party PO Copy' : 'Form'}</label>
        {existingPoCopy && (
              <div className="mb-2">
                <a href={`${existingPoCopy}`} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                  View Existing Po Copy
                </a>
              </div>
            )}
        <input
            type="file"
            id="po_copy"
            name="po_copy"
            // onChange={handleFileChange}
            onChange={(e) => {
              const file = e.target.files[0];
              const maxSize = 2 * 1024 * 1024;
              if (file && file.size > maxSize) {
                alert("File size should not exceed 2MB.");
                e.target.value = "";
              } else {
                handleFileChange(e);
              }
            }}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
    </div>

    { formData?.user_type === 'ThirdParty' ? (
      <>
      {/* End User PO Copy Field */}
      <div className="mb-4">
        <label htmlFor="client_po_copy" className="block text-gray-700 text-sm font-bold mb-2">Client PO Copy</label>
        {existingClientPoCopy && (
              <div className="mb-2">
                <a href={`${existingClientPoCopy}`} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                  View Existing Client Po Copy
                </a>
              </div>
        )}
        <input
            type="file"
            id="client_po_copy"
            name="client_po_copy"
            // onChange={handleFileChange}
            onChange={(e) => {
              const file = e.target.files[0];
              const maxSize = 2 * 1024 * 1024;
              if (file && file.size > maxSize) {
                alert("File size should not exceed 2MB.");
                e.target.value = "";
              } else {
                handleFileChange(e);
              }
            }}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
    </div>

    {/* Third Party Account Field */}
    <div className="mb-4">
        <label htmlFor="third_party_account" className="block text-gray-700 text-sm font-bold mb-2">Third Party Account</label>
        <Select
            id="third_party_account"
            name="third_party_account"
            options={thirdPartyAccountOptions}
            value={thirdPartyAccountOptions.find(option => option.value === formData.third_party_account)}
            onChange={(selectedOption) => handleSelectChange(selectedOption, { name: 'third_party_account' })}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select an Third Party Account"
            isClearable={true} 
        />
    </div>

      </>
    ):
    (<>
    </>)
    }
    
    {/* Billing Address Field */}
    <div className="mb-4">
        <label htmlFor="billing_address" className="block text-gray-700 text-sm font-bold mb-2">Billing Address</label>
        <textarea
            id="billing_address"
            name="billing_address"
            value={formData.billing_address}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
    </div>

    {/* Billing GST Number Field */}
    <div className="mb-4">
        <label htmlFor="billing_gst_number" className="block text-gray-700 text-sm font-bold mb-2">Billing GST Number</label>
        <input
            type="text"
            id="billing_gst_number"
            name="billing_gst_number"
            value={formData.billing_gst_number}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
    </div>

    {/* Shipping Address Field */}
    <div className="mb-4">
        <label htmlFor="shipping_address" className="block text-gray-700 text-sm font-bold mb-2">Shipping Address</label>
        <textarea
            id="shipping_address"
            name="shipping_address"
            value={formData.shipping_address}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
    </div>

    {/* Shipping GST Number Field */}
    <div className="mb-4">
        <label htmlFor="shipping_gst_number" className="block text-gray-700 text-sm font-bold mb-2">Shipping GST Number</label>
        <input
            type="text"
            id="shipping_gst_number"
            name="shipping_gst_number"
            value={formData.shipping_gst_number}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
    </div>

    <div className="mb-4">
      <label htmlFor="product_category" className="block text-gray-700 text-sm font-bold mb-2">
        Product Details
      </label>
      <div className="flex">
        {/* <Select
          id="product_details"
          name="product_details"
          options={projectDetailoptions}
          isMulti
          value={projectDetailoptions?.filter(option => formData.product_details.includes(option.value))}
          onChange={handleProductDetailsChange}
          className="w-full"
          classNamePrefix="select"
          placeholder="Select Product Details"
        /> */}
        <div onClick={() => ShowProductDetails()} style={{ cursor: 'pointer' }}>
          <AddIcon />  <PreviewIcon />
        </div>
        <div onClick={() => ShowExistingProductDetails()} style={{ cursor: 'pointer' }}>
          <a className="text-blue-500 font-bold"> See Existing Product</a>
        </div>
      </div>
    </div>



    <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" >License Contract Manager Name</label>
        <input
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          type="text"
          name="license_contract_manager_name"
          value={formData.license_contract_manager_name}
          onChange={handleChange}
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" >License Contract Manager Phone</label>
        <input
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          type="tel"
          name="license_contract_manager_phone"
          value={formData.license_contract_manager_phone}
          onChange={handleChange}
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" >License Contract Manager Email ID</label>
        <input
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          type="email"
          name="license_contract_manager_email_id"
          value={formData.license_contract_manager_email_id}
          onChange={handleChange}
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" >Proposed License Activation Date</label>
        <select
          name="proposed_license_activation_date"
          value={formData.proposed_license_activation_date}
          onChange={handleSelectLicenceChange}
          
          >
          <option value="">Select...</option>
          <option value="Immediate">Immediate</option>
          <option value="Particular Date">Particular Date</option>
        </select>
      </div>

      {/* <div className="mb-4"{(formData.proposed_license_activation_date === "Immediate") ? <></> : disabled}>  */}
      {/* <div className="mb-4"> */}
      {formData?.proposed_license_activation_date === "Particular Date" ? 
      (<>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Proposed Order Loading Date</label>
          <input
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            type="date"
            name="proposed_order_loading_date"
            value={formData.proposed_order_loading_date}
            onChange={handleChange}
            // disabled={showLicenceDate !== "Particular Date"}  // Disable unless 'Particular Date' is selected
          />
        </div>
      </>) :
      <></>
      }

      <div className="mb-4"> 
        <label className="block text-gray-700 text-sm font-bold mb-2" >Manual CIF Form</label>
        {existingManualCifForm && (
              <div className="mb-2">
                <a href={`${existingManualCifForm}`} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                  View Existing Manual Cif Form
                </a>
              </div>
        )}
        <input
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          type="file"
          name="manual_cif_form"
          // onChange={handleFileChange}
          onChange={(e) => {
            const file = e.target.files[0];
            const maxSize = 2 * 1024 * 1024;
            if (file && file.size > maxSize) {
              alert("File size should not exceed 2MB.");
              e.target.value = "";
            } else {
              handleFileChange(e);
            }
          }}
        />
      </div>

      <div className="mb-4"> 
        <label className="block text-gray-700 text-sm font-bold mb-2" >Selling Payment Terms</label>
        <input
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          type="number"
          name="selling_payment_terms"
          value={formData.selling_payment_terms}
          onChange={handleChange}
        />
      </div>

      <div className="mb-4"> 
        <label className="block text-gray-700 text-sm font-bold mb-2" >Purchase Payment Terms</label>
        <input
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          type="number"
          name="purchase_payment_terms"
          value={formData.purchase_payment_terms}
          onChange={handleChange}
        />
      </div>

      <div className="mb-4"> 
        <label className="block text-gray-700 text-sm font-bold mb-2" >License Activation Date</label>
        <input
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          type="date"
          name="license_activation_date"
          value={formData.license_activation_date}
          onChange={handleChange}
        />
      </div>

      <div className="mb-4"> 
        <label className="block text-gray-700 text-sm font-bold mb-2" >License Certificate</label>
        {formData.license_certificate && (
              <div className="mb-2">
                <a href={`${formData.license_certificate}`} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                  View Existing License Certificate
                </a>
              </div>
        )}
        <input
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          type="file"
          name="license_certificate"
          // onChange={handleFileChange}
          onChange={(e) => {
            const file = e.target.files[0];
            const maxSize = 2 * 1024 * 1024;
            if (file && file.size > maxSize) {
              alert("File size should not exceed 2MB.");
              e.target.value = "";
            } else {
              handleFileChange(e);
            }
          }}
        />
      </div>

      <div className="mb-4"> 
        <label className="block text-gray-700 text-sm font-bold mb-2" >Sales Invoice Number</label>
        <input
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          type="text"
          name="sales_invoice_number"
          value={formData.sales_invoice_number}
          onChange={handleChange}
        />
      </div>

      <div className="mb-4"> 
        <label className="block text-gray-700 text-sm font-bold mb-2" >Sales Invoice Date</label>
        <input
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          type="date"
          name="sales_invoice_date"
          value={formData.sales_invoice_date}
          onChange={handleChange}
        />
      </div>

      <div className="mb-4"> 
        <label className="block text-gray-700 text-sm font-bold mb-2" >Sales Invoice Amount (Ex GST)</label>
        <input
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          type="number"
          step="0.01"
          name="sales_invoice_amount_ex_gst"
          value={formData.sales_invoice_amount_ex_gst}
          onChange={handleChange}
        />
      </div>

      <div className="mb-4"> 
        <label className="block text-gray-700 text-sm font-bold mb-2" >Sales GST Amount (18%)</label>
        <input
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          type="number"
          // step="0.01"
          name="sales_gst_amount"
          value={formData.sales_gst_amount}
          onChange={handleChange}
          disabled
        />
      </div>

      <div className="mb-4"> 
        <label className="block text-gray-700 text-sm font-bold mb-2" >Total Sales Invoice Amount (Inc GST)</label>
        <input
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          type="number"
          step="0.01"
          name="tota_sales_invoice_amount_inc_gst"
          value={formData.tota_sales_invoice_amount_inc_gst}
          onChange={handleChange}
          disabled
        />
      </div>

      <div className="mb-4"> 
        <label className="block text-gray-700 text-sm font-bold mb-2" >Sales Invoice</label>
        {formData.sales_invoice && (
              <div className="mb-2">
                <a href={`${formData.sales_invoice}`} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                  View Existing Sales Invoice
                </a>
              </div>
        )}
        <input
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          type="file"
          name="sales_invoice"
          // onChange={handleFileChange}
          onChange={(e) => {
            const file = e.target.files[0];
            const maxSize = 2 * 1024 * 1024;
            if (file && file.size > maxSize) {
              alert("File size should not exceed 2MB.");
              e.target.value = "";
            } else {
              handleFileChange(e);
            }
          }}
        />
      </div>


        <div className="mb-4">
      <label htmlFor="payment" className="block text-gray-700 text-sm font-bold mb-2">
        Purchase Invoice
      </label>
      <div className="flex">
        <div onClick={() => ShowPurchaseInvoice()} style={{ cursor: 'pointer' }}>
          <AddIcon /> <PreviewIcon />
        </div>
        <span></span>
        <div onClick={() => ShowExistingPurchaseInvoice()} style={{ cursor: 'pointer' }}>
          <a className="text-blue-500 font-bold"> See Existing</a>
        </div>
      </div>
    </div>


      <div className="mb-4">
      <label htmlFor="payment" className="block text-gray-700 text-sm font-bold mb-2">
        Payment
      </label>
      <div className="flex">
        {/* <Select
          id="product_details"
          name="product_details"
          options={projectDetailoptions}
          isMulti
          value={projectDetailoptions?.filter(option => formData.product_details.includes(option.value))}
          onChange={handleProductDetailsChange}
          className="w-full"
          classNamePrefix="select"
          placeholder="Select Product Details"
        /> */}
        <div onClick={() => ShowPaymentDetails()} style={{ cursor: 'pointer' }}>
          <AddIcon />  <PreviewIcon />
        </div>
        <span></span>
        <div onClick={() => ShowExistingPaymentDetails()} style={{ cursor: 'pointer' }}>
          <a className="text-blue-500 font-bold"> See Existing</a>
        </div>
      </div>
    </div>

      <div className="mb-4"> 
        <label className="block text-gray-700 text-sm font-bold mb-2" >Balance Amount (Inc GST)</label>
        <input
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          type="number"
          step="0.01"
          name="balance_amount"
          value={formData.balance_amount}
          onChange={handleChange}
          disabled
        />
      </div>

      <div className="mb-4"> 
        <label className="block text-gray-700 text-sm font-bold mb-2" >Payment Delayed By Days</label>
        <input
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          type="number"
          name="payment_delayed_by_days"
          value={formData.payment_delayed_by_days}
          onChange={handleChange}
          disabled
        />
      </div>

      <div className="mb-4"> 
        <label className="block text-gray-700 text-sm font-bold mb-2" >Branch Interest Per Month</label>
        <input
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          type="number"
          step="0.01"
          name="branch_interest_per_month"
          value={formData.branch_interest_per_month}
          onChange={handleChange}
          disabled
        />
      </div>

      <div className="mb-4"> 
        <label className="block text-gray-700 text-sm font-bold mb-2" >Remarks</label>
        <input
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          type="text"
          name="remarks"
          value={formData.remarks}
          onChange={handleChange}
        />
      </div>

    {/* Submit Button */}
    <div className="mb-4">
                <button
                    onClick={(e) => handleSubmit(e, false)}
                    type="submit"
                    disabled={isSubmitting}
                    className={`bg-blue-500 text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                        isSubmitting ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-700"
                    }`}
                >
                    {isSubmitting ? "Updating..." : "Update"}
                </button>
                <> </>
                <button 
                    // onClick={() => handleSubmitWithEmail()}
                    onClick={(e) => handleSubmit(e, true)}
                    type="submit"
                    disabled={isSubmitting}
                    className={`bg-blue-500 text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                        isSubmitting ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-700"
                    }`}
                >
                    {isSubmitting ? "With email Updating..." : "Update and Send Email"}
                </button>

                
            </div>
            
        </form>

    </div>
    </Box>

    <Modal
      open={openProductDetails}
      onClose={() => setOpenProductDetails(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      classes={{ backgroundColor: "red" }}
    >
      <div className="relative -translate-x-2/4 bg-white h-[850px] max-w-[600px] w-full rounded-lg shadow-lg p-4 left-2/4 top-[18%] translate-y-[-18%]">
        <div className="absolute top-2 right-3 cursor-pointer">
          <MdClose
            className="cursor-pointer text-xl"
            onClick={() => { setOpenProductDetails(false);
            resetForm();
            }}
          />
        </div>
        <h3 className="text-base font-semibold leading-6 border-b pb-3 text-gray-900">
          Add Product Details
        </h3>
        <Box sx={{ paddingY: '30px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Product Master:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
            <Box display="flex" alignItems="center">
            
              <Select
                id="product_master"
                name="product_master"
                options={projectMasteroptions}
                value={projectMasteroptions.find(option => option.value === productMaster)} 
                onChange={(selectedOption) => {
                  handleProductMaster(selectedOption.value, selectedOption.label);
                  // setProductMaster(selectedOption.value);
                }}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                placeholder="Select a Product Master"
                isClearable={true} 
              />
                <div onClick={handleNavigation} style={{ cursor: 'pointer' }}>
                      <AddIcon /> 
                    </div>
              </Box>
              <div onClick={GetAllProjectMaster} style={{ cursor: 'pointer' }}>
                  <RefreshIcon /> 
              </div>
            </Grid>
          </Grid>
          <br />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Quantity:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <input
                type="number"
                id="quantity"
                name="quantity"
                value={quantity}
                onChange={(event) => {
                  setQuantity(event.target.value);
                }}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                placeholder="Enter quantity"
              />
            </Grid>
          </Grid>
          <br />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Selling Amount:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <input
                type="number"
                id="selling_amount"
                name="selling_amount"
                value={sellingAmount}
                // onChange={handleChange}
                onChange={(event) => {
                  setSellingAmount(event.target.value);
                  // calculateTotals(); // Calculate totals when selling amount changes
                }}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                placeholder="Enter selling amount"
              />
            </Grid>
          </Grid>
          <br />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Purchase Amount:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <input
                type="number"
                id="purchase_amount"
                name="purchase_amount"
                value={purchaseAmount}
                onChange={(event) => {
                  setPurchaseAmount(event.target.value);
                  // calculateTotals();
                }}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                placeholder="Enter purchase amount"
              />
            </Grid>
          </Grid>
          <br />

          {/* Total Selling Amount */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Total Selling Amount:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography sx={{ fontSize: '18px', fontWeight: 400 }}>
                {totalSellingAmount || 0} {/* Display calculated total */}
              </Typography>
            </Grid>
          </Grid>
          <br />
          
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Total Purchase Amount:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography sx={{ fontSize: '18px', fontWeight: 400 }}>
                {totalPurchaseAmount || 0} {/* Display calculated total */}
              </Typography>
            </Grid>
          </Grid>
          <br />

          {/* Total ACV Amount */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Total ACV Amount:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography sx={{ fontSize: '18px', fontWeight: 400 }}>
                {totalAcvAmount || 0} {/* Display calculated total */}
              </Typography>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>SGST Amount (per unit):</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
            <Typography sx={{ fontSize: '18px', fontWeight: 400 }}>
                {sgstAmount || 0} {/* Display calculated total */}
              </Typography>
             
            </Grid>
          </Grid>
          <br />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>CGST Amount (per unit):</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
            <Typography sx={{ fontSize: '18px', fontWeight: 400 }}>
                {cgstAmount || 0} {/* Display calculated total */}
              </Typography>
              
            </Grid>
          </Grid>
          <br />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>IGST Amount (per unit):</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
            <Typography sx={{ fontSize: '18px', fontWeight: 400 }}>
                {igstAmount || 0} {/* Display calculated total */}
              </Typography>
             
            </Grid>
          </Grid>
          <br />

          <div className="max-w-[447px] w-full text-right mt-4">
            <button
              className="border px-4 py-2 font-bold text-white bg-blue-500 rounded"
              onClick={handleAddProductDetails}

            >
              Add
            </button>
          </div>
        </Box>
      </div>
    </Modal>

    <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            classes={{ backgroundColor: "red" }}
          >
            <div className="absolute -translate-x-2/4 bg-white h-[636px] max-w-[1150px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
              <div className="h-full">
              
                <div className="p-4 my-4 h-[calc(100%_-_117px)] overflow-auto">
                    <>
                      
                        <DataGrid
                          rows={productDetails}
                          columns={product_master_local_storage}
                          getRowId={(row) => row.id} 
                          components={{ Toolbar: GridToolbar }}
                        />
                    </>
                </div>
                <div className="border-t">
                  <div className="pt-4 text-right">
                  <button
                      className="border px-2 py-1 font-[700] text-white bg-green-500 border-gray-300 rounded"
                      onClick={() => AddProductDetails()} style={{ cursor: 'pointer' }}
                    >
                      Add
                    </button>
                    <button
                      className="border px-2 py-1 font-[700] text-white bg-gray-500 border-gray-300 rounded"
                      onClick={() => {
                        setOpen(false);
                        // SetselectedAccount(null);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
    </Modal>

    <Modal
            open={paymentopen}
            onClose={() => setPaymentOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            classes={{ backgroundColor: "red" }}
          >
            <div className="absolute -translate-x-2/4 bg-white h-[636px] max-w-[1150px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
              <div className="h-full">
              
                <div className="p-4 my-4 h-[calc(100%_-_117px)] overflow-auto">
                    <>
                      
                        <DataGrid
                          rows={payments}
                          columns={payment_columns}
                          getRowId={(row) => row.id} 
                          components={{ Toolbar: GridToolbar }}
                        />
                    </>
                </div>
                <div className="border-t">
                  <div className="pt-4 text-right">
                  <button
                      className="border px-2 py-1 font-[700] text-white bg-green-500 border-gray-300 rounded"
                      onClick={() => AddPaymentDetails()} style={{ cursor: 'pointer' }}
                    >
                      Add
                    </button>
                    <button
                      className="border px-2 py-1 font-[700] text-white bg-gray-500 border-gray-300 rounded"
                      onClick={() => {
                        setPaymentOpen(false);
                        // SetselectedAccount(null);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
    </Modal>

    <Modal
      open={openPaymentDetails}
      onClose={() => setOpenPaymentDetails(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      classes={{ backgroundColor: "red" }}
    >
      <div className="relative -translate-x-2/4 bg-white h-[670px] max-w-[600px] w-full rounded-lg shadow-lg p-4 left-2/4 top-[18%] translate-y-[-18%]">
        <div className="absolute top-2 right-3 cursor-pointer">
          <MdClose
            className="cursor-pointer text-xl"
            onClick={() => { setOpenPaymentDetails(false);
            resetPaymentForm();
            }}
          />
        </div>
        <h3 className="text-base font-semibold leading-6 border-b pb-3 text-gray-900">
          Add Payment Details
        </h3>
        <Box sx={{ paddingY: '30px' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Payment No:</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <TextField
                        id="payment_no"
                        name="payment_no"
                        value={paymentNo}
                        onChange={(event) => setPaymentNo(event.target.value)}
                        className="w-full"
                        placeholder="Enter payment number"
                    />
                </Grid>
            </Grid>
            <br />

            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Payment Method:</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                <Select
                    id="payment_method"
                    name="payment_method"
                    options={paymentMethodOptions} // Array of payment method options
                    value={paymentMethodOptions.find(option => option.value === formData.payment_method)} // Selected payment method
                    onChange={(selectedOption) => setPaymentMethod(selectedOption ? selectedOption.value : '')}
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Select a Payment Method"
                    required
                    isClearable={true} 
                  />
                </Grid>
            </Grid>
            <br />

            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Payment Status:</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                <Select
                    id="payment_status"
                    name="payment_status"
                    options={paymentStatusOptions} // Array of payment status options
                    value={paymentStatusOptions.find(option => option.value === formData.payment_status)} // Selected payment status
                    onChange={(selectedOption) => setPaymentStatus(selectedOption ? selectedOption.value : '')}
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Select a Payment Status"
                    required
                    isClearable={true} 
                  />
                 </Grid>
            </Grid>
            <br />

            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Payment Received Amount:</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <TextField
                        type="number"
                        id="payment_received_amount"
                        name="payment_received_amount"
                        value={paymentReceivedAmount}
                        onChange={(event) => setPaymentReceivedAmount(event.target.value)}
                        className="w-full"
                        placeholder="Enter received amount"
                    />
                </Grid>
            </Grid>
            <br />

            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Payment Date:</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <TextField
                        type="date"
                        id="payment_date"
                        name="payment_date"
                        value={paymentDate}
                        onChange={(event) => setPaymentDate(event.target.value)}
                        className="w-full"
                        placeholder="Enter payment date"
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                  <Typography sx={{ fontSize: '18px', fontWeight: 600 }}> Remarks:</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                  <TextField
                      id="payment_remarks"
                      name="payment_remarks"
                      value={paymentRemarks}
                      onChange={(event) => setPaymentRemarks(event.target.value)}
                      className="w-full"
                      placeholder="Enter payment remarks"
                  />
              </Grid>
            </Grid>
            <br />

            <div className="max-w-[447px] w-full text-right mt-4">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddPaymentDetail}
                >
                    Add
                </Button>
            </div>
        </Box>
      </div>
    </Modal>

    <Modal
        open={accountPopup}
        onClose={() => showAccountPopup(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="absolute -translate-x-2/4 bg-white h-[300px] max-w-[400px] w-full rounded-lg shadow-[24] p-4 left-2/4 top-[50%] translate-y-[-50%]">
          <div className="h-full flex flex-col justify-between">
            <h2 id="modal-modal-title" className="text-xl font-bold mb-4">Confirm Account Selection</h2>
            <p id="modal-modal-description">Do you confirm that Account CSN is selected correctly?</p>
            <div className="flex justify-end mt-4">
              <button
                className="border px-4 py-2 font-[700] text-white bg-green-500 border-gray-300 rounded mr-2"
                onClick={() => handleConfirmAccount(true)}
              >
                Yes
              </button>
              <button
                className="border px-4 py-2 font-[700] text-white bg-gray-500 border-gray-300 rounded"
                onClick={() => handleConfirmAccount(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
    </Modal>

    <Modal
        open={backPopup}
        onClose={() => showBackPopup(false)}
        aria-labelledby="modal-back-title"
        aria-describedby="modal-back-description"
      >
        <div className="absolute -translate-x-2/4 bg-white h-[300px] max-w-[400px] w-full rounded-lg shadow-[24] p-4 left-2/4 top-[50%] translate-y-[-50%]">
          <div className="h-full flex flex-col justify-between">
            <h2 id="modal-back-title" className="text-xl font-bold mb-4">Confirm Navigation</h2>
            <p id="modal-back-description">Are you sure you want to go back? Any unsaved changes will be lost.</p>
            <div className="flex justify-end mt-4">
              <button
                className="border px-4 py-2 font-[700] text-white bg-green-500 border-gray-300 rounded mr-2"
                onClick={() => handleConfirmBack(true)}
              >
                Yes
              </button>
              <button
                className="border px-4 py-2 font-[700] text-white bg-gray-500 border-gray-300 rounded"
                onClick={() => handleConfirmBack(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
    </Modal>

      <Modal
            open={openExistingPaymentDetails}
            onClose={() => setOpenExistingPaymentDetails(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            classes={{ backgroundColor: "red" }}
          >
            <div className="absolute -translate-x-2/4 bg-white h-[636px] max-w-[1150px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
              <div className="h-full">
              
                <div className="p-4 my-4 h-[calc(100%_-_117px)] overflow-auto">

                    <>
                      
                        <DataGrid
                          rows={existingPayments}
                          columns={payment_columns}
                          getRowId={(row) => row.id} 
                          components={{ Toolbar: GridToolbar }}
                        />
                    </>
                </div>
                <div className="border-t">
                  <div className="pt-4 text-right">
                  <button
                      className="border px-2 py-1 font-[700] text-white bg-green-500 border-gray-300 rounded"
                      onClick={() => AddPaymentDetails()} style={{ cursor: 'pointer' }}
                    >
                      Add
                    </button>
                    <button
                      className="border px-2 py-1 font-[700] text-white bg-gray-500 border-gray-300 rounded"
                      onClick={() => {
                        setOpenExistingPaymentDetails(false)
                        // SetselectedAccount(null);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
      </Modal>


    <Modal
            open={openExistingProductDetails}
            onClose={() => ""}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            classes={{ backgroundColor: "red" }}
          >
            <div className="absolute -translate-x-2/4 bg-white h-[636px] max-w-[1150px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
              <div className="h-full">
              
                <div className="p-4 my-4 h-[calc(100%_-_117px)] overflow-auto">
                    <>
                      
                        <DataGrid
                          rows={existingProductDetails}
                          columns={product_master_Columns}
                          getRowId={(row) => row.id} 
                          components={{ Toolbar: GridToolbar }}
                        />
                    </>
                </div>
                <div className="border-t">
                  <div className="pt-4 text-right">
                  <button
                      className="border px-2 py-1 font-[700] text-white bg-green-500 border-gray-300 rounded"
                      onClick={() => AddProductDetails()} style={{ cursor: 'pointer' }}
                    >
                      Add
                    </button>
                    <button
                      className="border px-2 py-1 font-[700] text-white bg-gray-500 border-gray-300 rounded"
                      onClick={() => {
                        setOpenExistingProductDetails(false)
                        // SetselectedAccount(null);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
    </Modal>

    <Modal
      open={updateOpen}
      onClose={() => {
        setUpdateOpen(false);
        resetForm();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box 
        className="relative bg-white rounded-lg shadow-lg p-6"
        sx={{
          width: '600px',
          maxHeight: '850px',
          overflowY: 'auto',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          position: 'absolute',
          boxShadow: 24,
          borderRadius: '8px'
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => { setUpdateOpen(false); resetForm(); }}
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <MdClose />
        </IconButton>

        <Typography variant="h6" component="h2" sx={{ mb: 2, borderBottom: '1px solid #e0e0e0', pb: 2 }}>
          Update Product Details
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontWeight: 600 }}>Product Master:</Typography>
            <Box display="flex" alignItems="center">
              <Select
                id="product_master"
                name="product_master"
                options={projectMasteroptions}
                value={projectMasteroptions.find(option => option.value === productFormData.product_master)}
                onChange={(selectedOption) => handleSelectProductUpadeChange(selectedOption.value)}
                className="w-full"
                placeholder="Select a Product Master"
                isClearable
              />
              <IconButton onClick={handleNavigation}>
                <AddIcon />
              </IconButton>
              <IconButton onClick={GetAllProjectMaster}>
                <RefreshIcon />
              </IconButton>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontWeight: 600 }}>Quantity:</Typography>
            <input
              type="number"
              id="quantity"
              name="quantity"
              value={productFormData.quantity}
              onChange={(event) => handleProductUpdateChange('quantity', event.target.value)}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              placeholder="Enter quantity"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontWeight: 600 }}>Selling Amount:</Typography>
            <input
              type="number"
              id="selling_amount"
              name="selling_amount"
              value={productFormData.selling_amount}
              onChange={(event) => handleProductUpdateChange('selling_amount', event.target.value)}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              placeholder="Enter selling amount"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontWeight: 600 }}>Purchase Amount:</Typography>
            <input
              type="number"
              id="purchase_amount"
              name="purchase_amount"
              value={productFormData.purchase_amount}
              onChange={(event) => handleProductUpdateChange('purchase_amount', event.target.value)}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              placeholder="Enter purchase amount"
            />
          </Grid>

          {/* Total Selling Amount */}
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontWeight: 600 }}>Total Selling Amount:</Typography>
            <Typography sx={{ fontWeight: 400 }}>
              {productFormData.total_selling_amount_exc_gst || 0}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontWeight: 600 }}>Total Purchase Amount:</Typography>
            <Typography sx={{ fontWeight: 400 }}>
              {productFormData.total_purchase_amount_exc_gst || 0}
            </Typography>
          </Grid>

          {/* Total ACV Amount */}
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontWeight: 600 }}>Total ACV Amount:</Typography>
            <Typography sx={{ fontWeight: 400 }}>
              {productFormData.total_acv_amount_exc_gst || 0}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontWeight: 600 }}>SGST Amount (per unit):</Typography>
            {productFormData.sgst_amount || 0}
            {/* <input
              type="number"
              id="sgst_amount"
              name="sgst_amount"
              value={productFormData.sgst_amount}
              onChange={(event) => handleProductUpdateChange('sgst_amount', event.target.value)}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              placeholder="Enter SGST amount"
              disabled
            /> */}
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontWeight: 600 }}>CGST Amount (per unit):</Typography>
            {productFormData.cgst_amount || 0}

            {/* <input
              type="number"
              id="cgst_amount"
              name="cgst_amount"
              value={productFormData.cgst_amount}
              onChange={(event) => handleProductUpdateChange('cgst_amount', event.target.value)}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              placeholder="Enter CGST amount"
              disabled
            /> */}
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontWeight: 600 }}>IGST Amount (per unit):</Typography>
            {productFormData.igst_amount || 0}

            {/* <input
              type="number"
              id="igst_amount"
              name="igst_amount"
              value={productFormData.igst_amount}
              onChange={(event) => handleProductUpdateChange('igst_amount', event.target.value)}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              placeholder="Enter IGST amount"
              disabled
            /> */}
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontWeight: 600 }}>Remarks:</Typography>
            <textarea
              id="remarks"
              name="remarks"
              value={productFormData.remarks}
              onChange={(event) => handleProductUpdateChange('remarks', event.target.value)}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              placeholder="Enter remarks"
            />
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="flex-end" mt={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleUpdateProductDetails(selectedProductDetails)}
            sx={{ minWidth: '150px' }}
          >
            Update Product Details
          </Button>
        </Box>
      </Box>
    </Modal>

    <Modal
      open={openPurchaseInvoice}
      onClose={() => setOpenPurchaseInvoice(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      classes={{ backgroundColor: "red" }}
    >
      <div className="relative -translate-x-2/4 bg-white h-[670px] max-w-[600px] w-full rounded-lg shadow-lg p-4 left-2/4 top-[18%] translate-y-[-18%]">
        <div className="absolute top-2 right-3 cursor-pointer">
          <MdClose
            className="cursor-pointer text-xl"
            onClick={() => { setOpenPurchaseInvoice(false); resetPurchaseForm(); }}
          />
        </div>
        <form onSubmit={handleAddPurchaseInvoice}>
        <h3 className="text-base font-semibold leading-6 border-b pb-3 text-gray-900">
          Add Purchase Invoice
        </h3>
        <Box sx={{ paddingY: '30px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Purchase From:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Select
                id="purchase_from"
                name="purchase_from"
                options={soldToOptions} // Array of SoldTo options
                value={soldToOptions.find(option => option.value === purchaseInvoiceFormData.purchase_from)}
                onChange={(selectedOption) => handleSelectPurchaseFromChange(selectedOption.value)}
                // onChange={(selectedOption) => setPurchaseFrom(selectedOption ? selectedOption.value : '')}
                className="basic-single"
                classNamePrefix="select"
                placeholder="Select a Supplier"
                required
                isClearable={true}
              />
            </Grid>
          </Grid>
          <br />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Invoice Number:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                id="purchase_invoice_number"
                name="purchase_invoice_number"
                value={purchaseInvoiceNumber}
                onChange={(event) => setPurchaseInvoiceNumber(event.target.value)}
                className="w-full"
                placeholder="Enter invoice number"
                required
              />
            </Grid>
          </Grid>
          <br />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Invoice Date:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                type="date"
                id="purchase_invoice_date"
                name="purchase_invoice_date"
                value={purchaseInvoiceDate}
                onChange={(event) => setPurchaseInvoiceDate(event.target.value)}
                className="w-full"
                InputLabelProps={{ shrink: true }}
                required
              />
            </Grid>
          </Grid>
          <br />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Amount (Ex GST):</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                type="number"
                id="purchase_invoice_amount_ex_gst"
                name="purchase_invoice_amount_ex_gst"
                value={purchaseInvoiceAmountExGST}
                onChange={(event) => setPurchaseInvoiceAmountExGST(event.target.value)}
                className="w-full"
                placeholder="Enter amount excluding GST"
                required
              />
            </Grid>
          </Grid>
          <br />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>GST Amount:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                type="number"
                id="purchase_gst_amount"
                name="purchase_gst_amount"
                value={purchaseGstAmount}
                onChange={(event) => setPurchaseGstAmount(event.target.value)}
                className="w-full"
                placeholder="Enter GST amount"
                required
              />
            </Grid>
          </Grid>
          <br />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Total Amount (Inc GST):</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                type="number"
                id="total_purchase_invoice_amount_inc_gst"
                name="total_purchase_invoice_amount_inc_gst"
                value={totalPurchaseInvoiceAmountIncGST}
                onChange={(event) => setTotalPurchaseInvoiceAmountIncGST(event.target.value)}
                className="w-full"
                placeholder="Enter total amount including GST"
              />
            </Grid>
          </Grid>
          <br />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Upload Invoice:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <input
                type="file"
                id="purchase_invoice"
                name="purchase_invoice"
                onChange={(event) => setPurchaseInvoice(event.target.files[0])}
                className="w-full"
                accept="application/pdf, image/*"
                required
              />
            </Grid>
          </Grid>
          <br />

          <div className="max-w-[447px] w-full text-right mt-4">
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              Add
            </Button>
          </div>
        </Box>
          </form>
      </div>
    </Modal>


    <Modal
            open={openExistingPurchaseInvoice}
            onClose={() => setOpenExistingPurchaseInvoice(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            classes={{ backgroundColor: "red" }}
          >
            <div className="absolute -translate-x-2/4 bg-white h-[636px] max-w-[1150px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
              <div className="h-full">
              
                <div className="p-4 my-4 h-[calc(100%_-_117px)] overflow-auto">

                    <>
                      
                        <DataGrid
                          rows={existingPurchase}
                          columns={purchase_invoice_columns}
                          getRowId={(row) => row.id} 
                          components={{ Toolbar: GridToolbar }}
                        />
                    </>
                </div>
                <div className="border-t">
                  <div className="pt-4 text-right">
                  <button
                      className="border px-2 py-1 font-[700] text-white bg-green-500 border-gray-300 rounded"
                      onClick={() => AddPurchaseDetails()} style={{ cursor: 'pointer' }}
                    >
                      Add
                    </button>
                    <button
                      className="border px-2 py-1 font-[700] text-white bg-gray-500 border-gray-300 rounded"
                      onClick={() => {
                        setOpenExistingPurchaseInvoice(false)
                        // SetselectedAccount(null);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
      </Modal>

      <Modal
            open={purchaseopen}
            onClose={() => setPurchaseOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            classes={{ backgroundColor: "red" }}
          >
            <div className="absolute -translate-x-2/4 bg-white h-[636px] max-w-[1150px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
              <div className="h-full">
              Latest Added
                <div className="p-4 my-4 h-[calc(100%_-_117px)] overflow-auto">
                    <>
                      
                        <DataGrid
                          rows={purchases}
                          columns={purchase_invoice_columns}
                          getRowId={(row) => row.id} 
                          components={{ Toolbar: GridToolbar }}
                        />
                    </>
                </div>
                <div className="border-t">
                  <div className="pt-4 text-right">
                  <button
                      className="border px-2 py-1 font-[700] text-white bg-green-500 border-gray-300 rounded"
                      onClick={() => AddPurchaseDetails()} style={{ cursor: 'pointer' }}
                    >
                      Add
                    </button>
                    <button
                      className="border px-2 py-1 font-[700] text-white bg-gray-500 border-gray-300 rounded"
                      onClick={() => {
                        setPurchaseOpen(false);
                        // SetselectedAccount(null);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
    </Modal>

    <Modal
      open={openUpdatePurchaseInvoice}
      onClose={() => setOpenUpdatePurchaseInvoice(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      classes={{ backgroundColor: "red" }}
    >
      <div className="relative -translate-x-2/4 bg-white h-[700px] max-w-[600px] w-full rounded-lg shadow-lg p-4 left-2/4 top-[18%] translate-y-[-18%]">
        <div className="absolute top-2 right-3 cursor-pointer">
          <MdClose
            className="cursor-pointer text-xl"
            onClick={() => { setOpenUpdatePurchaseInvoice(false); resetPurchaseForm(); }}
          />
        </div>
        <form onSubmit={handleUpdatePurchaseInvoice}> {/* Update function */}
          <h3 className="text-base font-semibold leading-6 border-b pb-3 text-gray-900">
            Update Purchase Invoice {/* Updated Title */}
          </h3>
          <Box sx={{ paddingY: '30px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Purchase From:</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Select
                  id="purchase_from"
                  name="purchase_from"
                  options={soldToOptions}
                  value={soldToOptions.find(option => option.value === purchaseInvoiceFormData.purchase_from)}
                   // Prepopulate with existing data
                  onChange={(selectedOption) => handleSelectPurchaseFromChange(selectedOption.value)}
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Select a Supplier"
                  required
                  isClearable={true}
                />
              </Grid>
            </Grid>
            <br />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Invoice Number:</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField
                  id="purchase_invoice_number"
                  name="purchase_invoice_number"
                  value={purchaseInvoiceFormData?.purchase_invoice_number} // Prepopulate with existing data
                  onChange={handlePurchaseInvoiceChange}
                  className="w-full"
                  placeholder="Enter invoice number"
                  required
                />
              </Grid>
            </Grid>
            <br />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Invoice Date:</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField
                  type="date"
                  id="purchase_invoice_date"
                  name="purchase_invoice_date"
                  value={purchaseInvoiceFormData?.purchase_invoice_date} // Prepopulate with existing data
                  onChange={handlePurchaseInvoiceChange}
                  className="w-full"
                  InputLabelProps={{ shrink: true }}
                  required
                />
              </Grid>
            </Grid>
            <br />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Amount (Ex GST):</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField
                  type="number"
                  id="purchase_invoice_amount_ex_gst"
                  name="purchase_invoice_amount_ex_gst"
                  value={purchaseInvoiceFormData?.purchase_invoice_amount_ex_gst} // Prepopulate with existing data
                  onChange={handlePurchaseInvoiceChange}
                  className="w-full"
                  placeholder="Enter amount excluding GST"
                  required
                />
              </Grid>
            </Grid>
            <br />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>GST Amount:</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField
                  type="number"
                  id="purchase_gst_amount"
                  name="purchase_gst_amount"
                  value={purchaseInvoiceFormData?.purchase_gst_amount} // Prepopulate with existing data
                  onChange={handlePurchaseInvoiceChange}
                  className="w-full"
                  placeholder="Enter GST amount"
                  required
                />
              </Grid>
            </Grid>
            <br />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Total Amount (Inc GST):</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <TextField
                  type="number"
                  id="total_purchase_invoice_amount_inc_gst"
                  name="total_purchase_invoice_amount_inc_gst"
                  value={purchaseInvoiceFormData?.total_purchase_invoice_amount_inc_gst} // Prepopulate with existing data
                  onChange={handlePurchaseInvoiceChange}
                  className="w-full"
                  placeholder="Enter total amount including GST"
                />
              </Grid>
            </Grid>
            <br />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Upload Invoice:</Typography>
              </Grid>
              {purchaseInvoiceFormData?.purchase_invoice && (
              <div className="mb-2">
                <a href={`${purchaseInvoiceFormData.purchase_invoice}`} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                  View Existing Purchase Invoice
                </a>
              </div>
        )}
              <Grid item xs={12} sm={9}>
                <input
                  type="file"
                  id="purchase_invoice"
                  name="purchase_invoice"
                  onChange={handleInvoiceChange}
                  className="w-full"
                  accept="application/pdf, image/*"
                  
                />
              </Grid>
            </Grid>
            <br />

            <div className="max-w-[447px] w-full text-right mt-4">
              <Button
                type="submit"
                variant="contained"
                color="primary"
              >
                Update {/* Updated Button Text */}
              </Button>
            </div>
          </Box>
        </form>
      </div>
    </Modal>

    <Modal
        open={deleteOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <div className="absolute -translate-x-2/4 bg-white w-[400px] rounded-lg shadow-lg p-4 left-2/4 top-[40%] translate-y-[-40%]">
          <h2 id="delete-modal-title" className="text-lg font-bold mb-4">
            Confirm Deletion
          </h2>
          <p id="delete-modal-description" className="mb-4">
            Are you sure you want to delete this product?
          </p>
          <div className="flex justify-end space-x-4">
            <button
              className="px-4 py-2 bg-gray-500 text-white rounded"
              onClick={handleDeleteCancel}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-red-500 text-white rounded"
              onClick={handleDeleteConfirm}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>

    
      <Modal
        open={purchaseInvoiceDeleteOpen}
        onClose={handlePurchaseInvoiceCancel}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <div className="absolute -translate-x-2/4 bg-white w-[400px] rounded-lg shadow-lg p-4 left-2/4 top-[40%] translate-y-[-40%]">
          <h2 id="delete-modal-title" className="text-lg font-bold mb-4">
            Confirm Deletion
          </h2>
          <p id="delete-modal-description" className="mb-4">
            Are you sure you want to delete this Invoice Purchase?
          </p>
          <div className="flex justify-end space-x-4">
            <button
              className="px-4 py-2 bg-gray-500 text-white rounded"
              onClick={handlePurchaseInvoiceCancel}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-red-500 text-white rounded"
              onClick={handlePurchaseInvoiceConfirm}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>


    


    </>
  );
};

export default UpdateOrderLoadingPO;


