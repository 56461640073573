import React, { useState, useEffect } from "react";
import {Box, Modal, Typography, Grid} from "@mui/material";
import Header from "../../components/Header.jsx";
import {BACKEND_BASE_URL} from "../../components/config/apiEnv.js";
import axios from "axios";
import { toast } from "react-toastify";
import { useUser } from "../../components/UserProvider.js";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/AuthContext.js";
import { MdClose } from "react-icons/md";
import AddIcon from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';


const UpdateProductMaster = () => {
    const { id } = useParams();
    const [error, setError] = useState(null);
    const navigate = useNavigate()
    const { token } = useUser();
    const [isLoading, setIsLoading] = useState(false);
    const [dependency, SetDependency] = useState(false);
    const [allCategory, SetAllCategory] = useState(null);
    const [allUOM, SetAllUOM] = useState(null);
    const [allStatus, SetAllStatus] = useState(null);
    const [allOEM, SetAllOEM] = useState(null);
    const [allGSTType, SetAllGSTType] = useState(null);
    const [modelValue, SetModelValue] = useState(null);
    const [openassign, setOpenAssign] = useState(false);
    const [openGstassign, setOpenGstAssign] = useState(false);
    const [selectedCategory, SetSelectedCategory] = useState(null);
    const [selectedGst, SetSelectedGst] = useState(null);
    const [selectedGstValue, SetSelectedGstValue] = useState(null);
    const [formData, setFormData] = useState({
      
        name: '',
        product_description: '',
        product_category: '',
        product_sku: '',
        acv_price:'',
        dtp_price: '',
        oem:'',
        uom: '',
        gst_type: '',
        gst_ammount: '',
        startDate: '',
        endDate: '',
        status: '',
      });


    
    const headers = {
        Authorization: `Bearer ${token}`,
    };


    useEffect(() => {
      if (token){
        const fetchUserData = () => {
          axios.get(`${BACKEND_BASE_URL}v1/product/product_master/${id}`, { headers })
            .then((response) => {
              if (response.status === 200) {
                setFormData(response?.data?.product_master);
                setError(null);
                toast.success("Data Loaded", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              } else {
                toast.error("Error occurred while displaying the product.", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            })
            .catch((error) => {
              setError(error?.response?.data?.Response?.message);
              toast.error("Error occurred while displaying the product.", {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
        };
        
        fetchUserData();
      }
      }, [id, token]);


    useEffect(() => {
      if (token){
      updateStatus()
      }
    }, [formData?.endDate, token]);

    useEffect(() => {
      if (token){
      if (formData?.gst_type) {
      updateAmount()
      }
    }
    }, [token, formData?.gst_type, formData?.dtp_price]);


    useEffect(() => {
      if (token){
      GetAllCategory();
      GetAllUOM();
      GetAllStatus();
      GetAllOEM();
      GetAllGSTType();
      }
    }, [token, dependency]);

    const GetAllCategory = () => {
      axios.get(BACKEND_BASE_URL + `v1/product/product_master_category/`, { headers })
        .then((response) => {
          console.log("🚀 ~ .then ~ response:", response)
          if (response.status === 200) {
            SetAllCategory(response?.data);
            setError(null);
            toast.success("Data Loaded", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Error occured while displaying accounts.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(() => {
          setError(error?.response.data.Response.message);
          
          setIsLoading(false)
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          
        });
    };

    const GetAllUOM = () => {
      axios.get(BACKEND_BASE_URL + `v1/product/product_master_uom/`, { headers })
        .then((response) => {
          console.log("🚀 ~ .then ~ response:", response)
          if (response.status === 200) {
            SetAllUOM(response?.data);
            setError(null);
            toast.success("Data Loaded", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Error occured while displaying accounts.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(() => {
          setError(error?.response.data.Response.message);
          
          setIsLoading(false)
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          
        });
    };


    const GetAllStatus = () => {
      axios.get(BACKEND_BASE_URL + `v1/product/product_master_status/`, { headers })
        .then((response) => {
          console.log("🚀 ~ .then ~ response:", response)
          if (response.status === 200) {
            SetAllStatus(response?.data);
            setError(null);
            toast.success("Data Loaded", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Error occured while displaying accounts.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(() => {
          setError(error?.response.data.Response.message);
          
          setIsLoading(false)
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          
        });
    };


    const GetAllOEM = () => {
      axios.get(BACKEND_BASE_URL + `v1/product/product_master_oem/`, { headers })
        .then((response) => {
          console.log("🚀 ~ .then ~ response:", response)
          if (response.status === 200) {
            SetAllOEM(response?.data);
            setError(null);
            toast.success("Data Loaded", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Error occured while displaying accounts.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(() => {
          setError(error?.response.data.Response.message);
          
          setIsLoading(false)
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          
        });
    };

    const GetAllGSTType = () => {
      axios.get(BACKEND_BASE_URL + `v1/product/product_master_gst_type/`, { headers })
        .then((response) => {
          console.log("🚀 ~ .then ~ response:", response)
          if (response.status === 200) {
            SetAllGSTType(response?.data);
            setError(null);
            toast.success("Data Loaded", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Error occured while displaying accounts.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(() => {
          setError(error?.response.data.Response.message);
          
          setIsLoading(false)
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };

      // const updateStatus = () => {
      //   const { startDate, endDate } = formData;
      //   console.log("_______________________working",endDate);
      //   const currentDate = new Date();
      //   const formattedCurrentDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
      //   if (startDate <= formattedCurrentDate && endDate >= formattedCurrentDate) {
      //     formData.status = 'active';
      //   } else {
      //     formData.status = 'inactive';
      //   }
      //   setFormData(formData);
      // };


      const updateStatus = () => {
        
        const { startDate, endDate, status } = formData;
        const currentDate = new Date();
        const formattedCurrentDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
        const startDateTime = new Date(`${startDate}T00:00:00.000Z`);
        const endDateTime = new Date(`${endDate}T00:00:00.000Z`);
        const currentDateTime = new Date(`${currentDate}T00:00:00.000Z`);

        if  (currentDate >= startDateTime && currentDate <= endDateTime) {
          setFormData((formData) => ({ ...formData, status: '1' }));
        } else {
          setFormData((formData) => ({ ...formData, status: '2' }));
        }
      };

      const updateAmount = () => {
        const { dtp_price, gst_type  } = formData;

        if (dtp_price && gst_type){
            const filteredValues = allGSTType?.filter(item => item?.id === parseInt(gst_type));
            if (filteredValues?.length > 0) {
              const amount = parseInt(dtp_price) + (dtp_price * filteredValues[0]?.value / 100)
              formData.gst_ammount = amount
              setFormData(formData => ({
                ...formData,
                gst_ammount: amount
            }));
          } else {
              toast.error("Invalid GST type", {
                  position: toast.POSITION.TOP_RIGHT,
              });
          }
        }else{
          toast.error("Please select dpt price first", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      };


      
      
      const handleAdd = (adding_value) => {
        const requestData = {
            name: selectedCategory || null,
        };
        axios
        .post(BACKEND_BASE_URL + `v1/product/product_master_${adding_value}/`, requestData, { headers })
        .then((response) => {
            console.log("🚀 ~ .then ~ response:", response)
            if (response.status === 201) {
              SetDependency(true)
              setOpenAssign(false)
              SetSelectedCategory(null)
            setError(null);
            toast.success("Update Product Master created Successfully", {
                position: toast.POSITION.TOP_RIGHT,
            });
            } else {
            toast.error("Error occured while displaying accountsfdfdsfds.", {
                position: toast.POSITION.TOP_RIGHT,
            });
            }
        })
        .catch((error) => {
            toast.error(error.response.data?.error, {
            position: toast.POSITION.TOP_RIGHT,
            });
        });
      };

      const handleGstAdd = () => {
        const requestData = {
            name: selectedGst || null,
            value: selectedGstValue || null,
        };
        axios
        .post(BACKEND_BASE_URL + `v1/product/product_master_gst_type/`, requestData, { headers })
        .then((response) => {
            console.log("🚀 ~ .then ~ response:", response)
            if (response.status === 201) {
              SetDependency(true)
              setOpenGstAssign(false)
              SetSelectedCategory(null)
            setError(null);
            toast.success("Update Product Master created Successfully", {
                position: toast.POSITION.TOP_RIGHT,
            });
            } else {
            toast.error("Error occured while displaying accountsfdfdsfds.", {
                position: toast.POSITION.TOP_RIGHT,
            });
            }
        })
        .catch((error) => {
            toast.error(error.response.data?.error, {
            position: toast.POSITION.TOP_RIGHT,
            });
        });
      };

    const handleUpdate = (e) => {
      e.preventDefault();
        const requestData = {
            name: formData?.name,
            product_description: formData?.product_description,
            product_category: formData?.product_category,
            product_sku: formData?.product_sku,
            acv_price: formData?.acv_price,
            dtp_price: formData?.dtp_price,
            oem: formData?.oem,
            uom: formData?.uom,
            gst_type: formData?.gst_type,
            gst_ammount: formData?.gst_ammount,
            startDate: formData?.startDate,
            endDate: formData?.endDate,
            status: formData?.status,
        };

        axios
        .put(BACKEND_BASE_URL + `v1/product/product_master/${id}`, requestData, { headers })
        .then((response) => {
            console.log("🚀 ~ .then ~ response:", response)
            if (response.status === 200) {
                navigate("/product_master")
            setError(null);
            toast.success("Update Product Master Successfully", {
                position: toast.POSITION.TOP_RIGHT,
            });
            } else {
            toast.error("Error occured while displaying accountsfdfdsfds.", {
                position: toast.POSITION.TOP_RIGHT,
            });
            }
        })
        .catch((error) => {
            toast.error(error.response.data?.error, {
            position: toast.POSITION.TOP_RIGHT,
            });
        });
    };

    const AddCategory = (value) => {
      SetModelValue(value)
      setOpenAssign(true);
      console.log("__________ Add category __________");
    };

    const AddGstType = (value) => {
      SetModelValue(value)
      setOpenGstAssign(true);
      console.log("__________ Add category __________");
    };

    
    
  return (
    <>
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <Header title="Update Product Master" subtitle="welcome to you Team" />
      </Box>
      <div className="max-w-[800px] mx-auto mt-8 pb-14 p-6 bg-[#f2f0f0] rounded shadow-md">
      <h2 className="text-2xl font-semibold mb-6">Update Product Master Form</h2>
      <form onSubmit={handleUpdate}>
        {error && (
          <div style={{ color: 'red', marginTop: '8px' }}>
            {error}
          </div>
        )}
        <div className="mb-4">
          <label
            htmlFor="name"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Product Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="product_description"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Product Description
          </label>
          <input
            type="text"
            id="product_description"
            name="product_description"
            value={formData.product_description}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="product_category"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Product Category
          </label>
          <div className="flex">
          <select
            id="product_category"
            name="product_category"
            value={formData.product_category}
            onChange={handleChange}
            className="w- px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
          >
           {allCategory ? (
              <option key="null" value="">Select a Category</option>
            ) : null}
            {allCategory?.map(category => (
              <option key={category.id} value={category.id}>{category.name}</option>
            ))}
          </select>
            <div onClick={() => AddCategory("category")} style={{ cursor: 'pointer' }}>
              <AddIcon />
            </div>
            </div>
          {/* <button
            onClick={() => AddCategory()}
            className="action-button bg-[#8dbe86] text-black px-3 py-1 rounded h-[52px] w-[100px] text-center, text-lg"
          >
            <AddIcon/>
          </button> */}
        </div>
        <div className="mb-4">
          <label
            htmlFor="product_sku"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Product SKU
          </label>
          <input
            type="text"
            id="product_sku"
            name="product_sku"
            value={formData.product_sku}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="acv_price"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            ACV Price
          </label>
          <input
            type="number"
            id="acv_price"
            name="acv_price"
            value={formData.acv_price}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="dtp_price"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Unit Price
          </label>
          <input
            type="number"
            id="dtp_price"
            name="dtp_price"
            value={formData.dtp_price}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="uom"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Unit of Measure
          </label>
          <div className="flex">
          <select
            id="uom"
            name="uom"
            value={formData.uom}
            onChange={handleChange}
            className="w px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
          >
            {allUOM ? (
              <option key="null" value="">Select a UOM</option>
            ) : null}
            {allUOM?.map(uom => (
              <option key={uom.id} value={uom.id}>{uom.name}</option>
            ))}
          </select>
            <div onClick={() => AddCategory("uom")} style={{ cursor: 'pointer' }}>
              <AddIcon />
            </div>
            </div>
        </div>
        <div className="mb-4">
          <label
            htmlFor="oem"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            OEM
          </label>
          <div className="flex">
          <select
            id="oem"
            name="oem"
            value={formData.oem}
            onChange={handleChange}
            className="w px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            // required
          >
            {allOEM ? (
              <option key="null" value="">Select a OEM</option>
            ) : null}
            {allOEM?.map(oem => (
              <option key={oem.id} value={oem.id}>{oem.name}</option>
            ))}
          </select>
            <div onClick={() => AddCategory("oem")} style={{ cursor: 'pointer' }}>
              <AddIcon />
            </div>
            </div>
        </div>
        <div className="mb-4">
          <label
            htmlFor="gst_type"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            GST Type
          </label>
          <div className="flex">
            <select
              id="gst_type"
              name="gst_type"
              value={formData.gst_type}
              onChange={handleChange}
              className="w px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              required
            >
              {allGSTType ? (
                <option key="null" value="">Select a GST Type</option>
              ) : null}
              {allGSTType?.map(gst_type => (
                <option key={gst_type.id} value={gst_type.id}>{gst_type.name} {gst_type.value}</option>
              ))}
            </select>
              <div onClick={() => AddGstType("gst_type")} style={{ cursor: 'pointer' }}>
                <AddIcon />
              </div>
          </div>
        </div>
        <div className="mb-4">
          <label
            htmlFor="gst_ammount"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            GST Amount
          </label>
          <input
            type="text"
            id="gst_ammount"
            name="gst_ammount"
            value={formData?.gst_ammount}
            readOnly
            // onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 bg-gray-200"
          />
        </div>
        
        <div className="mb-4">
          <label
            htmlFor="startDate"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
          Start Date
          </label>
          <input
            type="date"
            id="startDate"
            name="startDate"
            value={formData.startDate}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="endDate"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            End Date
          </label>
          <input
            type="date"
            id="endDate"
            name="endDate"
            value={formData.endDate}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="status"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Status
          </label>
          <div className="flex">
            <select
              id="status"
              name="status"
              value={formData.status}
              onChange={handleChange}
              className="w px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              required
            >
              {allStatus ? (
                <option key="null" value="">Select a Status</option>
              ) : null}
              {allStatus?.map(status => (
                <option key={status.id} value={status.id}>{status.name}</option>
              ))}
            </select>
              <div onClick={() => AddCategory("status")} style={{ cursor: 'pointer' }}>
                <AddIcon />
              </div>
          </div>
        </div>
        <button
          type="submit"
          className={`bg-${error ? 'gray-300' : 'blue-500'} text-white px-4 py-2 rounded-md ${error ? 'cursor-not-allowed' : 'hover:bg-blue-600 focus:outline-none focus:bg-blue-600'}`}
          disabled={!!error}
        >
          Update
        </button>
      </form>
      </div>
    </Box>
    <Modal
        open={openassign}
        onClose={() => setOpenAssign(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        classes={{ backgroundColor: "red" }}
      >
        <div className="relative -translate-x-2/4 bg-white h-[350px] max-w-[600px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
          <div className="absolute top-2 right-3 cursor-pointer">
            <MdClose
              className="cursor-pointer text-xl"
              onClick={() => {
                setOpenAssign(false);
                // SetselectedAssignAccount(null);  
              }}
            />
          </div>
          <h3 className="text-base font-semibold leading-6 border-b pb-3 text-gray-900">
            Add Update Product Master {modelValue}
          </h3>
              <Box sx={{paddingY:'30px'}}>

            <Grid container spacing={2}>

              <Grid item sx={12} sm={3}>
                <Typography sx={{fontSize:'18px' , fontWeight:600}}> {modelValue} Name :</Typography>
              </Grid>
              <Grid item sx={12} sm={9}>
              <input
                  type="text"
                  id={modelValue}
                  name={modelValue}
                  value={selectedCategory}
                  onChange={(event) => {
                    SetSelectedCategory(event.target.value);
                  }}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                />
              </Grid>
            </Grid>
            <div className="max-w-[447px] w-full text-right mt-4">
              <button
                    className="border px-2 py-1 font-[700] text-white bg-gray-500 border-gray-300 rounded "
                    onClick={() => handleAdd(modelValue)}
                  >
                    Add
                  </button>
            </div>
              </Box>
        </div> 
      </Modal>

      <Modal
        open={openGstassign}
        onClose={() => setOpenGstAssign(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        classes={{ backgroundColor: "red" }}
      >
        <div className="relative -translate-x-2/4 bg-white h-[350px] max-w-[600px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
          <div className="absolute top-2 right-3 cursor-pointer">
            <MdClose
              className="cursor-pointer text-xl"
              onClick={() => {
                setOpenGstAssign(false);
                // SetselectedAssignAccount(null);  
              }}
            />
          </div>
          <h3 className="text-base font-semibold leading-6 border-b pb-3 text-gray-900">
            Add Update Product Master {modelValue}
          </h3>
              <Box sx={{paddingY:'30px'}}>

            <Grid container spacing={2}>

              <Grid item sx={12} sm={3}>
                <Typography sx={{fontSize:'18px' , fontWeight:600}}> Name :</Typography>
              </Grid>
              <Grid item sx={12} sm={9}>
              <input
                  type="text"
                  id={modelValue}
                  name={modelValue}
                  value={selectedCategory}
                  onChange={(event) => {
                    SetSelectedGst(event.target.value);
                  }}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                />
              </Grid>
            </Grid>
            <br></br>

            <Grid container spacing={2}>

              <Grid item sx={12} sm={3}>
                <Typography sx={{fontSize:'18px' , fontWeight:600}}> Value :</Typography>
              </Grid>
              <Grid item sx={12} sm={9}>
              <input
                  type="number"
                  id={modelValue}
                  name={modelValue}
                  value={selectedCategory}
                  onChange={(event) => {
                    SetSelectedGstValue(event.target.value);
                  }}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                />
              </Grid>
            </Grid>
            <div className="max-w-[447px] w-full text-right mt-4">
              <button
                    className="border px-2 py-1 font-[700] text-white bg-gray-500 border-gray-300 rounded "
                    onClick={() => handleGstAdd()}
                  >
                    Add
                  </button>
            </div>
              </Box>
        </div> 
      </Modal>
    </>
  );
};

export default UpdateProductMaster;