import React, { useEffect, useState } from "react";
import { BACKEND_BASE_URL, BACKEND_BASE_MEDIA_URL } from "../../components/config/apiEnv.js";
import { Box, Typography, useTheme, Modal, Tooltip, Grid, Button } from "@mui/material";
import { DataGrid, GridToolbar} from "@mui/x-data-grid";
import { tokens } from "../../theme.js";
import { mockDataTeam } from "../../data/mockData.js";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header.jsx";
import axios from "axios";
import { toast } from "react-toastify";
import { useUser } from "../../components/UserProvider.js";
import { } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { FaLock, FaUnlock } from 'react-icons/fa';
import { MdClose } from "react-icons/md";


const OrderLoadingPOHistory = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()
  const [orderLoadingData, SetOrderlaodingData] = useState([]);
  const [loading, SetLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openAction, setOpenAction] = useState(false);
  const [actionOrderID, setActionOrderID] = useState(null);

  const {token, user} = useUser()
  const [openProduct, setOpenProduct] = useState(false);
  const [productDetails, SetProductDetails] = useState([]);
  console.log("🚀 ~ OrderLoadingPOHistory ~ productDetails:", productDetails)
  const [showDetails, SetShowDetails] = useState([]);
  const [selectedOrderPO, setSelectedOrderPO] = useState(null);
  const [modelName, setModelName] = useState(null);
  const [userType, setUserType] = useState(null);
  
  
  const [rows, setRows] = useState(orderLoadingData);
  const [paymentData, setPaymentData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [purchaseData, setPurchaseData] = useState([]);
  const [emailLicenseCertificate, SetEmailLicenseCertificate] = useState(false);
  const [emailSalesInvoice, SetEmailSalesInvoice] = useState(false);
  const [emailPaymentRequest, SetEmailPaymentRequest] = useState(false);
  const [ccEmail, setCCEmail] = useState(null);

  const [errorMessage, setErrorMessage] = useState("");
  
  

  useEffect(() => {
    if (token){
    const user = localStorage.getItem("user");
    if (user) {
      const userData = JSON.parse(user);
      setUserType(userData.user_type);
    }
  }
  }, [token]);

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    if (token){
      GetOrderLoadingData();
    }
  }, [token]);


    const GetOrderLoadingData = () => {
        axios.get(BACKEND_BASE_URL + `v1/product/order_loading_po/`, {headers})
        .then((response) => {
          console.log("🚀 ~ .then ~ response:", response)
          if (response.status === 200) {
            SetOrderlaodingData(response?.data?.order_loading_po);
            SetLoading(false);
            setError(null);
            toast.success("Data Loaded", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }else {
              toast.error("Error occured while displaying accounts.", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
        .catch(() => {
          setError(error?.response.data.Response.message);
          SetLoading(false);
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    };

    const ShowTablesDetailsList = (id, name) => {
      setModelName(name)
      setOpenProduct(true)
      axios.get(BACKEND_BASE_URL + `v1/product/order_loading_po/${id}`, { headers })
        .then((response) => {
          if (response.status === 200) {
            setSelectedOrderPO(response.data.order_loading_po || []); 
            if (name === "Product"){
              SetShowDetails(response.data.order_loading_po.product_details || []);
              setProductData(response.data.order_loading_po.product_details || []);
            }
            else if(name === "Purchase"){
              SetShowDetails(response.data.order_loading_po.purchase_invoice || []);
              setPurchaseData(response.data.order_loading_po.purchase_invoice || []);
            }
            else {
              SetShowDetails(response.data.order_loading_po.payment || []);
              setPaymentData(response.data.order_loading_po.payment || []);
            }

            setError(null);
            toast.success("Data Loaded", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Error occurred while fetching account data.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          setError(error?.response?.data?.message);
          toast.error("Error occurred while fetching account data.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    };


    const toggleLock = async (id, lock) => {
      console.log("🚀🚀🚀🚀🚀🚀 ~ toggleLock ~ lock:", lock);
      const newLockState = lock === 'true' ? true : false;

      try {
        // API call to toggle lock status
        const response = await axios.get(BACKEND_BASE_URL + `v1/product/order_loading_lock_unlock/${id}/${newLockState}`, { headers });

        if (response.status === 200) {
          // Update the table data state
          SetOrderlaodingData(prevData =>
            prevData.map(row =>
              row.id === id ? { ...row, locked: newLockState } : row
            )
          );

          // Show success toast
          toast.success(`Selected Order Loading is set to ${newLockState ? 'locked' : 'unlocked'}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occurred while updating lock status.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        setError(error?.response?.data?.message);
        toast.error("Error occurred while updating lock status.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    };


  const getRowId = (row) => row.id;

  const columns = [
    // { field: 'id', headerName: 'ID', width: 60},
    {
      field: 'srNo',
      headerName: 'SR No',
      width: 60,
      valueGetter: (params) => params.api.getRowIndex(params.id) + 1, // Generate SR No based on row index
    },
    
    { 
      field: 'order_number', 
      headerName: 'Order Number',
      renderCell: (params) => (
        <Tooltip title={params.value || ''}>
          <button
            className={`text-red-600 ${params.row.locked ? 'cursor-not-allowed' : ''}`}
            onClick={!params.row.locked ? () => navigate(`/update_order_loading_po/${params.id}`) : null}
            disabled={params.row.locked}
          >
            <span className="table-cell-truncate">{params.value}</span>
          </button>
        </Tooltip>
      ), 
      width: 280 
    },
    { field: 'order_type', headerName: 'Order Type', width: 150 },
    { field: 'user_type', headerName: 'User Type', width: 150 },
    ...(userType && (userType === "Superadmin" || userType === "BDManager" || userType === "Primaryadmin") 
  ? [{
      field: 'locked',
      headerName: 'Lock / Unlock',
      width: 150,
      renderCell: (params) => (
        <div className="flex items-center w-full justify-center">
          {!params?.row.locked ? (
            <button
              onClick={userType === "Superadmin" ? () => toggleLock(params?.row.id, 'true') : null}
              className={`action-button bg-transparent text-black px-3 py-1 rounded ${userType !== "Superadmin" && "cursor-not-allowed"}`}
              disabled={userType !== "Superadmin"}
            >
              <FaUnlock className="text-green-500" />
            </button>
          ) : (
            <button
            onClick={userType === "Superadmin" ? () => toggleLock(params?.row.id, 'false') : null}
            className={`action-button bg-transparent text-black px-3 py-1 rounded ${userType !== "Superadmin" && "cursor-not-allowed"}`}
            disabled={userType !== "Superadmin"}
            >
              <FaLock className="text-red-500" />
            </button>
          )}
        </div>
      ),
    }]
  : []),

    { field: 'order_loading_date', headerName: 'Order Loading Date', width: 200, type: 'date' },
    { field: 'branch_name', headerName: 'Branch', width: 200 },
    { field: 'bd_person_name', headerName: 'BD Person', width: 200 },
    // { 
    //   field: 'bd_person', 
    //   headerName: 'BD Person', 
    //   width: 200,
    //   renderCell: (params) => (
    //     <div>
    //       {params?.value?.map(person => (
    //         <div key={person.id}>{person.first_name}</div>
    //       ))}
    //     </div>
    //   ),
    // },
    { field: 'account_name', headerName: 'Account', width: 200,
      renderCell: (params) => (
        <Tooltip title={params.value || ''}>
            <span className="table-cell-truncate">{params.value}</span>
        </Tooltip>
      ),
    },
    { field: 'po_number', headerName: 'PO Number', width: 200 },
    { field: 'po_date', headerName: 'PO Date', width: 200, type: 'date' },
    {
      field: 'po_copy',
      headerName: 'PO Copy',
      width: 200,
      renderCell: (params) => (
        params.value ? <a href={`${params.value}`} target="_blank" rel="noopener noreferrer">Download PO Copy {params.value}</a> : "No File"
      ),
    },
    {
      field: 'client_po_copy',
      headerName: 'Client PO Copy',
      width: 200,
      renderCell: (params) => (
        params.value ? <a href={`${params.value}`} target="_blank" rel="noopener noreferrer">Download Client PO Copy</a> : "No File"
      ),
    },
    { field: 'third_party_account_name', headerName: 'Third Party Account', width: 200,
      renderCell: (params) => (
        <Tooltip title={params.value || ''}>
            <span className="table-cell-truncate">{params.value}</span>
        </Tooltip>
    ),
    },
    { field: 'billing_address', headerName: 'Billing Address', width: 250, 
      renderCell: (params) => (
        <Tooltip title={params.value || ''}>
            <span className="table-cell-truncate">{params.value}</span>
        </Tooltip>
      ),
     },
    { field: 'billing_gst_number', headerName: 'Billing GST Number', width: 200, 
      renderCell: (params) => (
        <Tooltip title={params.value || ''}>
            <span className="table-cell-truncate">{params.value}</span>
        </Tooltip>
      ),
     },
    { field: 'shipping_address', headerName: 'Shipping Address', width: 250, 
      renderCell: (params) => (
        <Tooltip title={params.value || ''}>
            <span className="table-cell-truncate">{params.value}</span>
        </Tooltip>
      ),
     },
    { field: 'shipping_gst_number', headerName: 'Shipping GST Number', width: 200, 
      renderCell: (params) => (
        <Tooltip title={params.value || ''}>
            <span className="table-cell-truncate">{params.value}</span>
        </Tooltip>
      ),
     },
    {
      field: 'product',
      headerName: 'Product Details',
      width: 150,
      renderCell: (params) => (
        <div className="flex items-center w-full justify-center">
          <button
            onClick={() => ShowTablesDetailsList(params?.row.id, "Product")}
            className="action-button bg-[#8dbe86] text-black px-3 py-1 rounded"
          >
            Show
          </button>
        </div>
      ),
    },

    {
      field: 'do_slip',
      headerName: 'DO Slip',
      width: 200,
      renderCell: (params) => (
        params.value ? <a href={`${params.value}`} target="_blank" rel="noopener noreferrer">Download DO Slip</a> : "No File"
      ),
    },
    { field: 'license_contract_manager_name', headerName: 'License Contract Manager Name', width: 250, 
      renderCell: (params) => (
        <Tooltip title={params.value || ''}>
            <span className="table-cell-truncate">{params.value}</span>
        </Tooltip>
      ),
     },
    { field: 'license_contract_manager_phone', headerName: 'License Contract Manager Phone', width: 200, 
      renderCell: (params) => (
        <Tooltip title={params.value || ''}>
            <span className="table-cell-truncate">{params.value}</span>
        </Tooltip>
      ),
     },
    { field: 'license_contract_manager_email_id', headerName: 'License Contract Manager Email', width: 250, 
    renderCell: (params) => (
        <Tooltip title={params.value || ''}>
            <span className="table-cell-truncate">{params.value}</span>
        </Tooltip>
      ),  
     },
    { field: 'proposed_license_activation_date', headerName: 'Proposed License Activation Date', width: 250, 
    renderCell: (params) => (
        <Tooltip title={params.value || ''}>
            <span className="table-cell-truncate">{params.value}</span>
        </Tooltip>
      ),  
     },
    { field: 'proposed_order_loading_date', headerName: 'Proposed Order Loading Date', width: 200, type: 'date', 
    renderCell: (params) => (
        <Tooltip title={params.value || ''}>
            <span className="table-cell-truncate">{params.value}</span>
        </Tooltip>
      ),  
     },
    
    {
      field: 'manual_cif_form',
      headerName: 'Manual CIF Form',
      width: 200,
      renderCell: (params) => (
        params.value ? <a href={`${params.value}`} target="_blank" rel="noopener noreferrer">Download Manual CIF Form</a> : "No File"
      ),
    },
    // { field: 'selling_payment_terms', headerName: 'Selling Payment Terms', width: 200 },
    {
      field: 'selling_payment_terms',
      headerName: 'Selling Payment Terms',
      width: 200,
      renderCell: (params) => (
        <div>
          {params.value !== null ? `${params.value} days` : ''}
        </div>
      ),
    },
    { field: 'purchase_payment_terms', headerName: 'Purchase Payment Terms', width: 200,
      renderCell: (params) => (
        <div>
          {params.value !== null ? `${params.value} days` : ''}
        </div>
      ),
     },
    { field: 'license_activation_date', headerName: 'License Activation Date', width: 200, type: 'date',
      renderCell: (params) => (
        <Tooltip title={params.value || ''}>
            <span className="table-cell-truncate">{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'license_certificate',
      headerName: 'License Certificate',
      width: 200,
      renderCell: (params) => (
        params.value ? <a href={`${params.value}`} target="_blank" rel="noopener noreferrer">Download License Certificate</a> : "No File"
      ),
    },
    { field: 'sales_invoice_number', headerName: 'Sales Invoice Number', width: 200 },
    { field: 'sales_invoice_date', headerName: 'Sales Invoice Date', width: 200, type: 'date' },
    { field: 'sales_invoice_amount_ex_gst', headerName: 'Sales Invoice Amount (Ex GST)', width: 250 },
    { field: 'sales_gst_amount', headerName: 'Sales GST Amount (18%)', width: 200 },
    { field: 'tota_sales_invoice_amount_inc_gst', headerName: 'Total Sales Invoice Amount (Inc GST)', width: 250 },
    // { field: 'purchase_from', headerName: 'Purchase From', width: 200 },
    // { field: 'purchase_invoice_number', headerName: 'Purchase Invoice Number', width: 200 },
    // { field: 'purchase_invoice_date', headerName: 'Purchase Invoice Date', width: 200, type: 'date' },
    // { field: 'purchase_invoice_amount_ex_gst', headerName: 'Purchase Invoice Amount (Ex GST)', width: 250 },
    // { field: 'purchase_gst_amount', headerName: 'Purchase GST Amount', width: 200 },
    // { field: 'total_purchase_invoice_amount_inc_gst', headerName: 'Total Purchase Invoice Amount (Inc GST)', width: 250 },

    {
      field: 'purchase',
      headerName: 'Purchase Invoice',
      width: 150,
      renderCell: (params) => (
        <div className="flex items-center w-full justify-center">
          <button
            onClick={() => ShowTablesDetailsList(params?.row.id, "Purchase")}
            className="action-button bg-[#8dbe86] text-black px-3 py-1 rounded"
          >
            Show
          </button>
        </div>
      ),
    },

    {
      field: "payment",
      headerName: "Payment Details",
      width: 150,
      renderCell: (params) => (
        <div className="flex items-center w-full justify-center">
          <button
            onClick={() => ShowTablesDetailsList(params?.row.id, "Payment")}
            className="action-button bg-[#8dbe86] text-black px-3 py-1 rounded"
          >
            Show
          </button>
        </div>
      ),
    },
    { field: 'balance_amount', headerName: 'Balance Amount (Inc GST)', width: 200 },
    { field: 'payment_delayed_by_days', headerName: 'Payment Delayed By Days', width: 200,
      renderCell: (params) => (
        <div>
          {params.value !== null ? `${params.value} days` : ''}
        </div>
      ),
     },
    { field: 'branch_interest_per_month', headerName: 'Branch Interest Per Month', width: 200 },
    { field: 'remarks', headerName: 'Remarks', width: 250, 
      renderCell: (params) => (
        <Tooltip title={params.value || ''}>
            <span className="table-cell-truncate">{params.value}</span>
        </Tooltip>
      ),
     },

     ...(userType && userType === "Superadmin" || userType === "BDManager" || userType === "Primaryadmin"
      ? [{
        field: "",
        headerName: "Action",
        width: 150,
        renderCell: (params, index) => (
          <div className="flex items-center w-full justify-center">
            <button
              onClick={() => handleActionModel(params?.row.id)}
              className={`action-button bg-[#8dbe86] text-black px-3 py-1 rounded ${params?.row.locked && "cursor-not-allowed"}`}
            >
              Action
            </button>
          </div>
        ),
      }] :
      []),
  ];

  const handleActionModel = (order_id) => {
    setOpenAction(true);
    setActionOrderID(order_id)
    // axios
    //   .get(BACKEND_BASE_URL + `v1/api/subs_trigger/${order_id}`, { headers })
    //   .then((response) => {
    //     console.log("🚀 ~ .then ~ response:", response)
    //     if (response.status === 200) {
    //       SetselectedsubsTrigger(response?.data?.trigger_choices || []);
    //       SetLoading(false);
    //       setError(null);
    //       toast.success("Data Loaded", {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //     } else {
    //       toast.error("Error occured while displaying accounts.", {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //     }
    //   })
    //   .catch(() => {
    //     setError(error?.response.data.Response.message);
    //     SetLoading(false);
    //     toast.error("Error occured while displaying accounts.", {
    //       position: toast.POSITION.TOP_RIGHT,
    //     });
        
    //   });
  };


  const handleActionSubmit = async () => {
    const payload = {
      email_license_certificate: emailLicenseCertificate,
      email_sales_invoice: emailSalesInvoice,
      cc_email: ccEmail,
    };
  
    try {
      const response = await axios.put(BACKEND_BASE_URL + `/v1/product/order_loading_action/${actionOrderID}/${emailPaymentRequest}`, payload, { headers });
  
      if (response.status === 201) {
        console.log('API call successful', response.data);
        toast.success("Action successfully submitted", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setOpenAction(false);
        setActionOrderID(null);
        setSelectedOrderPO([]);
        SetEmailLicenseCertificate(false)
        SetEmailPaymentRequest(false)
        SetEmailSalesInvoice(false)
        setCCEmail(null)
      } else {
        toast.error("Error occurred while submitting action.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      // Handle error
      console.error('API call failed', error);
      toast.error("API call failed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };


  const handleDocumentCheck = async (documentType, isChecked) => {
  try {
    if (!isChecked) {
      // If the checkbox is unchecked, simply set the state to false without API call
      if (documentType === 'license_certificate') {
        SetEmailLicenseCertificate(false);
        toast.success("License Certificate deselected.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (documentType === 'sales_invoice') {
        SetEmailSalesInvoice(false);
        toast.success("Sales Invoice deselected.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return;
    }

    const response = await axios.get(BACKEND_BASE_URL + `v1/product/check_order_loading_documents/${actionOrderID}`, { headers });

    if (response.status === 200) {
      if (documentType === 'license_certificate') {
        if (response.data.license_certificate_exists) {
          SetEmailLicenseCertificate(true);
          toast.success("License Certificate is available and selected.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setErrorMessage(response.data.license_message || "Please upload License Certificate.");
          SetEmailLicenseCertificate(false);
          toast.error("Please upload License Certificate.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else if (documentType === 'sales_invoice') {
        if (response.data.sales_invoice_exists) {
          SetEmailSalesInvoice(true);
          toast.success("Sales Invoice is available and selected.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setErrorMessage(response.data.invoice_message || "Please upload Sales Invoice.");
          SetEmailSalesInvoice(false);
          toast.error("Please upload Sales Invoice.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    } else {
      toast.error("Error occurred while checking document status.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    setErrorMessage(error?.response?.data?.message || "An error occurred.");
    toast.error("Error occurred while checking document status.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};


  const product_master_Columns = [
    // { field: "id", headerName: "ID" },
    {
      field: 'srNo',
      headerName: 'SR No',
      width: 100,
      valueGetter: (params) => params.api.getRowIndex(params.id) + 1, // Generate SR No based on row index
    },
    { field: "product_master_name", headerName: "Product Master", width:300,
      renderCell: (params) => (
        <Tooltip title={params.value || ''}>
          <span className="whitespace-normal break-words overflow-hidden line-clamp-3">
            {params.value}
          </span>
        </Tooltip>
      ),
     },
    { field: "quantity", headerName: "Quantity", flex: 1 },
    { field: "selling_amount", headerName: "Selling Amount", flex: 1 },
    { field: "total_selling_amount_exc_gst", headerName: "Total Selling Amount (Ex GST)", flex: 1 },
    { field: "purchase_amount", headerName: "Purchase Amount", flex: 1 },
    { field: "total_purchase_amount_exc_gst", headerName: "Total Purchase Amount (Ex GST)", flex: 1 },
    { field: "total_acv_amount_exc_gst", headerName: "Total ACV Amount (Ex GST)", flex: 1 },
    { field: "sgst_amount", headerName: "SGST Amount (per unit)", flex: 1 },
    { field: "cgst_amount", headerName: "CGST Amount (per unit)", flex: 1 },
    { field: "igst_amount", headerName: "IGST Amount (per unit)", flex: 1 },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   sortable: false,
    //   renderCell: (params) => (
    //       <button
    //           className="text-red-600"
    //           onClick={() => handleDeleteRow(params.row.id)}
    //       >
    //           Delete
    //       </button>
    //   ),
    // },
  ];

  const payment_columns = [
    { field: "payment_no", headerName: "Payment No", flex: 1 },
    { field: "payment_method", headerName: "Payment Method", flex: 1 },
    { field: "payment_status", headerName: "Payment Status", flex: 1 },
    { field: "payment_received_amount", headerName: "Received Amount", flex: 1 },
    { field: "payment_date", headerName: "Payment Date", flex: 1 },
    // {
    //     field: "actions",
    //     headerName: "Actions",
    //     sortable: false,
    //     renderCell: (params) => (
    //         <button
    //             className="text-red-600"
    //             onClick={() => handleDeleteRow(params.row.id)}
    //         >
    //             Delete
    //         </button>
    //     ),
    // },
  ];

  const purchase_columns = [
    { field: 'purchase_from_name', headerName: 'Purchase From', flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value || ''}>
          <span className="whitespace-normal break-words overflow-hidden line-clamp-3">
            {params.value}
          </span>
        </Tooltip>
      ),
    },
    { field: 'purchase_invoice_number', headerName: 'Purchase Invoice Number', flex: 1},
    { field: 'purchase_invoice_date', headerName: 'Purchase Invoice Date', type: 'date' , flex: 1},
    { field: 'purchase_invoice_amount_ex_gst', headerName: 'Purchase Invoice Amount (Ex GST)', flex: 1},
    { field: 'purchase_gst_amount', headerName: 'Purchase GST Amount', flex: 1},
    { field: 'total_purchase_invoice_amount_inc_gst', headerName: 'Total Purchase Invoice Amount (Inc GST)', flex: 1},


    // { field: "payment_no", headerName: "Payment No", flex: 1 },
    // { field: "payment_method", headerName: "Payment Method", flex: 1 },
    // { field: "payment_status", headerName: "Payment Status", flex: 1 },
    // { field: "payment_received_amount", headerName: "Received Amount", flex: 1 },
    // { field: "payment_date", headerName: "Payment Date", flex: 1 },

  ];


  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();

  const mergedData = orderLoadingData.map(order => {
      const payment = paymentData.find(p => p.orderId === order.id) || {};
      const mergedRow = {
        ...order,
        ...payment,
        id: order.id, // Ensure the merged row retains the correct 'id'
      };
      console.log('Merged Row:', mergedRow);
      return mergedRow;
    });

  const prepareExportData = (rows) => {
    console.log("______________yaha talk",rows);
    
    return rows.map(row => ({
      ...row,
      product: row.product_details
        ? row.product_details.map(p => `${p.product_master_name} (${p.quantity})`).join(', ')
        : 'No Products',
    }));
  };
  

  return (
    <>
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="List of Order Loading PO" subtitle="we are in the same team" />
      </Box>
      <Box sx={{display:"flex"  , gap:'10px' , justifyContent:'end'}}>
      <Box display="flex" marginBottom={2} justifyContent="end" alignItems="center">
          <button
            onClick={() => navigate('/order_loading_po')}
            className="action-button bg-[#8dbe86] text-black px-3 py-1 rounded h-[52px] text-center text-lg"
            style={{ whiteSpace: 'nowrap' }}
          >
            Add Order Loading PO
          </button>       
        </Box>
        </Box>

      <Box
        m="8px 0 0 0"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
         {/* <DataGrid rows={orderLoadingData} columns={columns} getRowId={getRowId} /> */}
         <DataGrid
              rows={orderLoadingData}
              columns={columns}
              getRowId={getRowId}
              // getRowId={(row) => {
              //   console.log('Row ID:', row.id);
              //   return row.id;
              // }}
              components={{
                Toolbar: () => (
                  <div>
                    <GridToolbar
                    csvOptions={{
                      fileName: `OrderLoading_trisita_${user.first_name} ${user.last_name}_${currDate}_${currTime}`,
                      // getRowsToExport: () => mergedData,
                      fields: [
                        "user_type",
                        "locked",
                        "order_loading_date",
                        "branch_name",
                        "bd_person_name",
                        "account",
                        "po_number",
                        "po_date",
                        "po_copy",
                        "client_po_copy",
                        "third_party_account",
                        "billing_address",
                        "billing_gst_number",
                        "shipping_address",
                        "shipping_gst_number",
                        "product",
                        "license_contract_manager_name",
                        "license_contract_manager_phone",
                        "license_contract_manager_email_id",
                        "proposed_license_activation_date",
                        "manual_cif_form",
                        "selling_payment_terms",
                        "purchase_payment_terms",
                        "license_activation_date",
                        "license_certificate",
                        "sales_invoice_number",
                        "sales_invoice_date",
                        "sales_invoice_amount_ex_gst",
                        "sales_gst_amount",
                        "tota_sales_invoice_amount_inc_gst",
                        "purchase_from",
                        "purchase_invoice_number",
                        "purchase_invoice_date",
                        "purchase_invoice_amount_ex_gst",
                        "purchase_gst_amount",
                        "total_purchase_invoice_amount_inc_gst",
                        "payment",
                        "balance_amount",
                        "payment_delayed_by_days",
                        "branch_interest_per_month",
                        "remarks",
                        
                      ],
                    }} />
                  </div>
                )
              }}
              checkboxSelection 
              disableRowSelectionOnClick                                                               
            />
            {/* <DataGrid
              rows={orderLoadingData}
              columns={columns}
              getRowId={getRowId}
              components={{
                Toolbar: () => (
                  <div>
                    <GridToolbar
                    csvOptions={{
                      fileName: `OrderLoading_trisita_${user.first_name} ${user.last_name}_${currDate}_${currTime}`,
                      fields: [
                        "user_type",
                        "branch_name",
                        "bd_person",
                        "account",
                        "po_number",
                        "po_date",
                        "po_copy",
                        "product",
                      ],
                      getRowsToExport: () => prepareExportData(orderLoadingData),
                    }} />
                  </div>
                )
              }}
              checkboxSelection 
              disableRowSelectionOnClick                                                               
            /> */}


      </Box>
    </Box>
    <Modal
            open={openProduct}
            onClose={() => setOpenProduct(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            classes={{ backgroundColor: "red" }}
          >
            <div className="absolute -translate-x-2/4 bg-white h-[636px] max-w-[1150px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
              <div className="h-full">
              
                <div className="p-4 my-4 h-[calc(100%_-_117px)] overflow-auto">
                    <>
                      
                        <DataGrid
                          rows={showDetails}
                          columns={
                            modelName === "Product"
                              ? product_master_Columns
                              : modelName === "Payment"
                              ? payment_columns
                              : modelName === "Purchase"
                              ? purchase_columns
                              : []
                          }
                          // columns={modelName === "Product" ? product_master_Columns : payment_columns}
                          getRowId={(row) => row.id} 
                          // components={{ Toolbar: GridToolbar }}
                        />
                    </>
                </div>
                <div className="border-t">
                  <div className="pt-4 text-right">
                    <button
                      className="border px-2 py-1 font-[700] text-white bg-gray-500 border-gray-300 rounded"
                      onClick={() => {
                        setOpenProduct(false);
                        // SetselectedAccount(null);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
    </Modal>
    <Modal
          open={openAction}
          onClose={() => setOpenAction(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          classes={{ backgroundColor: "red" }}
        >
          <div className="relative -translate-x-2/4 bg-white h-[400px] max-w-[600px] w-full rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
            <div className="absolute top-2 right-3 cursor-pointer">
              <MdClose
                className="cursor-pointer text-xl"
                onClick={() => {
                  setOpenAction(false);
                  setActionOrderID(null);
                  setSelectedOrderPO([]);
                  SetEmailLicenseCertificate(false)
                  SetEmailPaymentRequest(false)
                  SetEmailSalesInvoice(false)
                  setCCEmail(null)
                }}
              />
            </div>
            <h3 className="text-base font-semibold leading-6 border-b pb-3 text-gray-900">
              Set Action
            </h3>
            <Box sx={{ paddingY: '30px' }}>
              <Grid container spacing={2}>
                {/* Email License Certificate */}
                <Grid item xs={12}>
                  <div className="flex items-center gap-4">
                    <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Email License Certificate:</Typography>
                    <input
                      type="checkbox"
                      id="email_license_certificate"
                      name="email_license_certificate"
                      className="leading-tight"
                      checked={emailLicenseCertificate}
                      onChange={(event) => handleDocumentCheck('license_certificate', event.target.checked)}

                    />
                  </div>
                </Grid>

                {/* Email Sales Invoice */}
                <Grid item xs={12}>
                  <div className="flex items-center gap-4">
                    <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Email Sales Invoice:</Typography>
                    <input
                      type="checkbox"
                      id="email_sales_invoice"
                      name="email_sales_invoice"
                      className="leading-tight"
                      checked={emailSalesInvoice}
                      onChange={(event) => handleDocumentCheck('sales_invoice', event.target.checked)}

                    />
                  </div>
                </Grid>

                {/* Email Payment Request */}
                <Grid item xs={12}>
                  <div className="flex items-center gap-4">
                    <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Email Payment Request:</Typography>
                    <input
                      type="checkbox"
                      id="email_payment_request"
                      name="email_payment_request"
                      className="leading-tight"
                      checked={emailPaymentRequest}
                      onChange={(event) => SetEmailPaymentRequest(event.target.checked)}
                    />
                  </div>
                </Grid>

                {/* CC Email */}
                <Grid item xs={12}>
                  <div className="mb-4">
                    <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>CC Email:</Typography>
                    <input
                      type="text"
                      id="cc_email"
                      name="cc_email"
                      value={ccEmail}
                      onChange={(event) => setCCEmail(event.target.value)}
                      className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                    />
                  </div>
                </Grid>
              </Grid>

              <div className="max-w-[447px] w-full text-right mt-4">
                <Button
                  className="border px-2 py-1 font-[700] text-white bg-gray-500 border-gray-300 rounded"
                  onClick={handleActionSubmit}
                >
                  Action
                </Button>
              </div>
            </Box>
          </div>
    </Modal>



  </>
  );
};

export default OrderLoadingPOHistory;
