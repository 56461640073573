import React, { useEffect, useState } from "react";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import { Box, InputBase, MenuItem, Select, Typography, useTheme, FormControl, InputLabel } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme.js";
import Header from "../../components/Header.jsx";
import axios from "axios";
import { toast } from "react-toastify";
import { useCSN } from "../../components/CSNProvider.jsx";
import ReactApexChart from "react-apexcharts";
import { useParams } from 'react-router-dom';
import { useUser } from "../../components/UserProvider.js";
import Loader from "../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";


const Usage = () => {
  const { ccsn, from_date, to_date} = useParams();
  const navigate = useNavigate()
  const { csn } = useCSN()
  const initialState = {
		ccsn: ccsn,
    from_date: from_date,
    to_date: to_date,
    product_line_code:""
	};
  const [formData, setFormData] = useState(initialState);
  const [loading, SetLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [usageData, SetUsageData] = useState([]);
  const [logincounts, SetLoginCounts] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [featureNameCharts, SetFeatureNameCharts] = useState([]);
  const [productLine, setProductLine] = useState("");
  
  const [productLines, setProductLines] = useState([]);

  const {token} = useUser()

  const filteredData = usageData?.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(filterText.toLowerCase())
    )
  );
  const headers = {
		"Authorization": `Bearer ${token}`
	}

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const handleProductLineChange = (event) => {
    const selectedProductLine = event.target.value;
    console.log("________________________()())()()()()()",selectedProductLine);
    
    setFormData((prevState) => ({
      ...prevState,
      product_line_code: selectedProductLine,
    }));
    SetLoading(true);
    // GetProductLineChartData(); // Trigger API call immediately after selection
  };

  useEffect(() => {
    if (token){
      GetFeatureNameChartData()
    }
	}, [csn, formData.product_line_code]);

  const GetFeatureNameChartData = () => {
    SetLoading(true);
    const apiUrl = `${BACKEND_BASE_URL}v1/api/get_usage_product_feature_chart/${csn ? `${csn}` : ''}`;
    axios.post(apiUrl, formData, { headers })
			.then((response) => {
      if (response.status === 200) {
        // SetUsageData(response?.data?.usages)
        // SetLoginCounts(response?.data?.login_counts)
        SetFeatureNameCharts(response?.data?.feature_name_counts)
        
        // SetLoading(false);
        setError(null);
        toast.success("Data Loaded", {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }).catch((error) => {
      setError(error?.response?.data?.Response?.error?.message);
      SetLoading(false);
      toast.error(error?.response?.data?.Response?.error?.message, {
        position: toast.POSITION.TOP_RIGHT  
      })				
    });
  }

  const dates = [...new Set(featureNameCharts.map((item) => item["Usage Date"]))]; // Unique dates
const featureNames = [...new Set(featureNameCharts.map((item) => item["Feature Name"]))]; // Unique feature names

const seriesData = featureNames.map((featureName) => {
  const dataForFeature = dates.map((date) => {
    const entry = featureNameCharts.find(
      (item) =>
        item["Usage Date"] === date && item["Feature Name"] === featureName
    );
    return entry ? entry["Count"] : 0; // Count or 0 if missing
  });

  return {
    name: featureName, // Feature name
    data: dataForFeature, // Data aligned with dates
  };
});

const chartOptions = {
  chart: { type: "line", height: 350 },
  xaxis: {
    categories: dates, // Dates on the x-axis
    title: { text: "Usage Dates" },
  },
  yaxis: {
    title: { text: "Counts" },
  },
  title: { text: "Feature Name Metrics Over Time", align: "center" },
  tooltip: {
    shared: true,
    intersect: false,
    y: {
      formatter: (value) => `${value} counts`,
    },
  },
};




  useEffect(() => {
    if (token){
    GetUsages()
    }
	}, [csn, token]);

  const GetUsages = () => {
    SetLoading(true);
    const apiUrl = `${BACKEND_BASE_URL}v1/api/get_usage_data/${csn ? `${csn}` : ''}`;
    axios.post(apiUrl, formData, { headers })
			.then((response) => {
      if (response.status === 200) {
        SetUsageData(response?.data?.usages)
        SetLoginCounts(response?.data?.login_counts)
        setProductLines(response?.data?.product_line_codes)
        SetLoading(false);
        setError(null);
        toast.success("Data Loaded", {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }).catch((error) => {
      setError(error?.response?.data?.Response?.error?.message);
      SetLoading(false);
      toast.error(error?.response?.data?.Response?.error?.message, {
        position: toast.POSITION.TOP_RIGHT  
      })				
    });
  }
   let uniqueIdCounter = 1;
  const getRowId = () => {
    return uniqueIdCounter++;
  };

  const columns = [
    { field: 'end_Customer_CSN', headerName: 'CSN', flex:1, width: 200 },
    { field: 'account_name', headerName: 'Account', flex:1, width: 200 },
    { field: 'usage_Type', headerName: 'Usage Type', flex:1, width: 200 },
    
    {
      field: 'usage_date',
      headerName: 'Usage Date',
      width: 200,
      valueGetter: (params) => formatDate(params.row['usage_date']),
    },
    { field: 'product_line_code', headerName: 'Product Line Code', flex:1, width: 200 },
    { field: 'feature_id', headerName: 'Feature Id', flex:1, width: 200 },
    { field: 'feature_name', headerName: 'Feature Name', flex:1, width: 200 },
    { field: 'team_name', headerName: 'Team Name', flex:1, width: 200 },
    { field: 'primary_admin_email', headerName: 'Primary Admin Email', flex:1, width: 200},
  ];


  const [status, setStatus] = React.useState("All Status");

  const uniqueProductLineCodes = [...new Set(logincounts?.map(count => count['Product Line Code']))];

const productLineData = {};

uniqueProductLineCodes.forEach(productLineCode => {
  const filteredData = logincounts?.filter(count => count['Product Line Code'] === productLineCode);

  if (filteredData) {
    const categories = filteredData.map(count => count['Usage Date']);
    const data = filteredData.map(count => (isNaN(count['Count']) ? 0 : count['Count']));

    productLineData[productLineCode] = {
      categories,
      data,
    };
  }
});

const defaultCategory = '';
const mergedData = (productLineData[uniqueProductLineCodes[0]]?.categories || []).map((date, index) => {
  const mergedItem = { Date: date || defaultCategory };

  uniqueProductLineCodes.forEach(productLineCode => {
    const productLineDataItem = productLineData[productLineCode];
    mergedItem[productLineCode] =
      (productLineDataItem && productLineDataItem.data && productLineDataItem.data[index] !== undefined)
        ? productLineDataItem.data[index]
        : 0;
  });

  return mergedItem;
});

const series = uniqueProductLineCodes.map(productLineCode => ({
  name: productLineCode,
  data: mergedData.map(item => item[productLineCode] || 0),
}));

  const options = {
    series,
    chart: {
      height: 350,
      type: 'line',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth',
    },
    title: {
      text: 'Login Counts Over Time',
      align: 'left',
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: mergedData.map(item => item.Date),
      title: {
        text: 'Date',
      },
    },
    yaxis: {
      title: {
        text: 'Login Count',
      },
      min: 0,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  };

  return (
    <>
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
        <Header title="Usages" subtitle="welcome to you Team" />
        <Box sx={{ display:{ md:"flex"}  }}>
        <button
            // onClick={() => navigate(`/usage_user_count/${ccsn}/${from_date}/${to_date}`)}
            onClick={() => window.open(`/usage_user_count/${ccsn}/${from_date}/${to_date}`, '_blank')}
            className="action-button bg-[#8dbe86] text-black px-3 mr-4 py-1 rounded h-[52px] w-[200px] text-center, text-lg"
          >
            Unique User Count 
          </button>
          <Box sx={{ ml:  { md:2}   , minWidth:"00px"}}>
            <InputBase
              sx={{
               
                width: "100%",
                height:"52px",
                flex: 1,
                paddingX: 2,
                paddingY: "3px",
                borderRadius: 1,
                border: "1px solid",
                borderColor: colors.grey[900],
              }}
              placeholder="Search"
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{border:'0.8px solid' , borderColor:colors.grey[900]}}>
        <Box sx={{backgroundColor:colors.primary[400] , borderBottom:'0.8px solid' , borderColor:colors.grey[900], padding:2,}}>
          <Typography variant="h4" sx={{fontWeight:'600'}} component="h3">
            Data of direct seat metrics i.e. 1:1 asscociation between product pool and agreement(without prorated)
          </Typography>
        </Box>
        <div id="chart">
        <ReactApexChart options={options} series={options.series} type="line" height={350} />
        </div>
      </Box>

      {/* Second Charts */}
      <Box sx={{ border: "0.8px solid", borderColor: "grey.900", padding: 2 }}>
      <Box sx={{ marginBottom: 2 }}>
      <FormControl fullWidth>
    <InputLabel id="product-line-select-label">Select Product Line</InputLabel>
    <Select
      labelId="product-line-select-label"
      value={formData.product_line_code}
      onChange={handleProductLineChange}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 'none', // Disable scroll by setting a maxHeight of none
          },
        },
      }}
    >
      <MenuItem value="">None</MenuItem>
      {productLines.map((line) => (
        <MenuItem key={line} value={line}>
          {line}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
      </Box>
      <div id="chart">
        {loading ? (
          <Typography>Loading...</Typography>
        ) : featureNameCharts.length > 0 ? (
          <ReactApexChart options={chartOptions} series={seriesData} type="line" height={350} />
        ) : (
          <Typography>No Data Available</Typography>
        )}
      </div>
    </Box>
      
      <Box
        m="8px 0 0 0"
        width="100%"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
      
      {loading ? (
          <p>Loading data...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : (
            <>
            <input
            type="text"
            placeholder="Search by Name"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            />
            <DataGrid
              rows={filteredData}
              columns={columns}
              getRowId={getRowId}
              components={{ Toolbar: GridToolbar }}
          />
            </>
      )}
      </Box>
    </Box>
    {
      loading && <Loader />
    }
    </>
  );
};

export default Usage;



