import React, { useState, useContext, useEffect, useRef } from "react";
import { ColorModeContext, tokens } from "../../theme";
import { 
  useTheme, InputBase, FormControl, Select, Tooltip, Box, Avatar, Menu, 
  MenuItem, ListItemIcon, Divider, IconButton, Typography, Button, Modal
} from "@mui/material";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import Logout from "@mui/icons-material/Logout";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import { useProSidebar } from "react-pro-sidebar";
import { useNavigate, Link } from "react-router-dom";
import { useCSN } from "../../components/CSNProvider";
import { useAuth } from "../../components/AuthContext";
import { useYear } from "../../components/YearProvider";
import { useUser } from "../../components/UserProvider.js";
import axios from "axios";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import { toast } from "react-toastify";
import Badge from "@mui/material/Badge";
import { NotificationContext } from "../../components/NotificationContext.js";

const Topbar = () => {
  const { user, token } = useUser();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const colorMode = useContext(ColorModeContext);
  const [csn, setCSN] = useState("");
  const [year, setYear] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notifications, setNotification] = useState(null);
  const [notificationsCount, setNotificationCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, SetLoading] = useState(true);
  const [error, setError] = useState(null);


  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { HandleCSN } = useCSN();
  const { HandleYear } = useYear();
  const { HandleLogout } = useAuth();
  const { toggleSidebar, broken, rtl } = useProSidebar();
  const { unreadCount, setUnreadCount} = useContext(NotificationContext);
  console.log("🚀 ~ Topbar ~ unreadCount:", unreadCount)
  // { } = useContext(NotificationContext);


  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);


  const headers = {
    "Authorization": `Bearer ${token}`
	}

  useEffect(() => {
    if (token){
    GetAllNotification();
    }
  }, [token]);


  const GetAllNotification = () => {
    axios.get(BACKEND_BASE_URL + `v1/api/notifications/`, { headers })
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response?.data?.count)
        if (response.status === 200) {
          setNotification(response?.data?.notifications || []);
          // setNotificationCount(response?.data?.count)
          setUnreadCount(response?.data?.count)
          
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying notification 00000.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        SetLoading(false);
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying notification.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  

  const handleSelect = (event) => {
    setCSN(event.target.value);
    HandleCSN(event.target.value);
  };

  const handleSelectYear = (event) => {
    setYear(event.target.value);
    HandleYear(event.target.value);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutAndClose = () => {
    handleClose();
    HandleLogout();
    navigate("/");
  };

  const handleNotificationClick = () => {
    setNotificationOpen(true);
  };

  return (
    <>
      <Box display="flex" alignItems={"center"} justifyContent="space-between" p={2}>
        <Box display="flex">
          {broken && !rtl && (
            <IconButton
              sx={{ margin: "0 6 0 2" }}
              onClick={() => toggleSidebar()}
            >
              <MenuOutlinedIcon />
            </IconButton>
          )}
        </Box>
        <Box display="flex" alignItems={"center"}>
          <FormControl sx={{ marginRight: 2, minWidth: 120 }}>
            <Select
              value={year}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              onChange={handleSelectYear}
            >
              <MenuItem value={""}>All Year</MenuItem>
              <MenuItem value={"ADSK FY 2025"}>ADSK FY 2025</MenuItem>
              <MenuItem value={"ADSK FY 2024"}>ADSK FY 2024</MenuItem>
              <MenuItem value={"ADSK FY 2023"}>ADSK FY 2023</MenuItem>
            </Select>
          </FormControl>
          {user.user_type !== "Client" && (
            <FormControl sx={{ marginRight: 2, minWidth: 120 }}>
              <Select
                value={csn}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                onChange={handleSelect}
              >
                <MenuItem value={""}>All CSN</MenuItem>
                <MenuItem value={"5102086717"}>5102086717</MenuItem>
                <MenuItem value={"5117963549"}>5117963549</MenuItem>
                <MenuItem value={"1234567890"}>1234567890</MenuItem>
              </Select>
            </FormControl>
          )}
          <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? (
              <LightModeOutlinedIcon />
            ) : (
              <DarkModeOutlinedIcon />
            )}
          </IconButton>
          <IconButton onClick={handleNotificationClick}
                style={{ cursor: "pointer" }}>
            <Badge badgeContent={unreadCount} color="error" overlap="circular">
              <NotificationsOutlinedIcon />
            </Badge>
          </IconButton>
          <IconButton>
            <SettingsOutlinedIcon />
          </IconButton>
          <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <PersonOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={handleClose}>
              <Link
                to={`/update_profile/${user?.id}`}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Avatar /> Edit Profile
              </Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Avatar /> My account
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link
                to={`/change_password`}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Avatar /> Change Password
              </Link>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <PersonAdd fontSize="small" />
              </ListItemIcon>
              Add another account
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
            <MenuItem onClick={handleLogoutAndClose}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      {notificationOpen && (
  <Modal
    open={notificationOpen}
    onClose={() => setNotificationOpen(false)}
    aria-labelledby="notification-modal-title"
    aria-describedby="notification-modal-description"
  >
    <Box
      sx={{
        position: "absolute",
        top: "10%",
        right: "2%",
        width: "300px",
        backgroundColor: "#ffffff",
        boxShadow: 3,
        borderRadius: 1,
        p: 2,
        zIndex: 1000,
        outline: "none",
      }}
    >
      <Typography id="notification-modal-title" variant="h6" gutterBottom>
        Notifications
      </Typography>

      {notifications.length > 0 ? (
        notifications.slice(0, 5).map((notification) => (
          <Box
            key={notification.id}
            sx={{
              mb: 2,
              p: 1,
              backgroundColor: notification.is_read ? "#f5f5f5" : "#e3f2fd",
              borderRadius: 1,
            }}
          >
            <Typography variant="body2">
              {notification.notification_message.slice(0, 20)}...
            </Typography>
            <Typography
              variant="caption"
              sx={{ display: "block", mt: 0.5, color: "#757575" }}
            >
              {new Date(notification.created_at).toLocaleString()}
            </Typography>
          </Box>
        ))
      ) : (
        <Typography variant="body2">No new notifications.</Typography>
      )}

      <Box textAlign="right" mt={2}>
        <Button
          variant="text"
          size="small"
          onClick={() => {
            setNotificationOpen(false);
            setTimeout(() => {
              try {
                navigate("/notifications");
              } catch (error) {
                console.error("Navigation failed:", error);
              }
            }, 100);
          }}
        >
          All Notifications
        </Button>
        <IconButton size="small" onClick={() => setNotificationOpen(false)}>
          <Logout fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  </Modal>
)}

    </>
  );
};

export default Topbar;