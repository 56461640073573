import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { MyProSidebarProvider } from "./pages/global/sidebar/sidebarContext";
import Topbar from "./pages/global/Topbar";
import Dashboard from "./pages/dashboard";
import Team from "./pages/team";
import Form from "./pages/form";
import Calendar from "./pages/calendar";
import Bar from "./pages/bar";
import Line from "./pages/line";
import Pie from "./pages/pie";
import FAQ from "./pages/faq";
import Geography from "./pages/geography";
import InsightMatrics from "./pages/Insight_Matrics/InsightMatrics";
import InsightMatricsContract from "./pages/Insight_Matrics/InsightMatricsContract";
import Login from "./pages/login/Login";
import Opportunity from "./pages/Opportunity/Opportunity";
import Subscription from "./pages/Subscription/Subscription";
import Account from "./pages/Account/Account";
import Usage from "./pages/Usage/Usage";
import { CSNProvider } from "./components/CSNProvider";
import GetUsage from "./pages/Usage/GetUsage";
import UpdateProfile from "./pages/User/UpdateProfile";
import { UserProvider } from "./components/UserProvider";
import RunChampaign from "./pages/Campaign/RunChampaign";
import ChangePassword from "./pages/User/ChangePassword";
import CampaignHsitory from "./pages/Campaign/CampaignHistory";
import RenewEmailHistory from "./pages/RenewEmail/RenewEmailHistory";
import ContactInformation from "./pages/team/ContactInformation";
import React, { useEffect, useRef } from 'react';
import { useAuth } from "./components/AuthContext";
import ActiveAccounts from "./pages/Account/ActiveAccounts";
import InactiveAccounts from "./pages/Account/InavtiveAccounts";
import Templates from "./pages/RenewEmail/Templates";
import ProductMaster from "./pages/Product_Master/ProductMaster";
import AddProductMaster from "./pages/Product_Master/AddProductMaster";
import AddProduct from "./pages/Product/AddProduct";
import Product from "./pages/Product/Product";
import Quotation from "./pages/Quotation/Quotation";
import AddQuotation from "./pages/Quotation/AddQuotation";
import TriggerSubs from "./pages/Subscription/TriggerSubs";
import AddOrderLoadingPO from "./pages/Order_Loading/AddOrderLoadingPO";
import OrderLoadingPOHistory from "./pages/Order_Loading/OrderLoadingPOHistory";
import OrderLoadingPO from "./pages/Order_Loading/OrderLoadingPO";
import UpdateProductMaster from "./pages/Product_Master/UpdateProductMaster";
import OrderLoadingDistributor from "./pages/Order_Loading/OrderLoadingDistributor";
import UpdateOrderLoadingPO from "./pages/Order_Loading/UpdateOrderLoadingPO";
import AddThirdPartyAccount from "./pages/Account/AddAccount";
import ThirdPartyAccount from "./pages/Account/ThirdPartyAccount";
import AddAccount from "./pages/Account/AddAccount";
import UpdateAccount from "./pages/Account/UpdateAccount";
import ManageTemplate from "./pages/RenewEmail/ManageTemplate";
import UploadPage from "./pages/Upload/UploadPage";
import UploadProductMaster from "./pages/Product_Master/UploadProductMaster";
import CampareSubscription from "./pages/Subscription/CampareSubscription";
import DeletedSubscription from "./pages/Subscription/DeletedSubscription";
import NewSubscription from "./pages/Subscription/NewSubscription";
import ChangeLogSubscription from "./pages/Subscription/ChangeLogSubscription";
import InsightMatricsV2 from "./pages/Insight_Matrics/InsightMatricsV2";
import { YearProvider } from "./components/YearProvider";
import InsightMatrics2 from "./pages/Insight_Matrics/InsightMatrics2";
import ExportedFiles from "./pages/team/ExportedFiles";
import Account2 from "./pages/Account/Account2";
import InsightMatricsTabular from "./pages/Insight_Matrics/InsightMatricsTabular";
import AccountTabular from "./pages/Account/AccountTabular";
import InsightMatricsClient from "./pages/Insight_Matrics/InsightMatricsClient";
import AccountClient from "./pages/Account/AccountClient";
import RenewalDue30 from "./pages/Subscription/RenewalDue30";
import RAOrderSubscription from "./pages/Subscription/RAOrderSubscription";
import UsageUserCount from "./pages/Usage/UsageUserCount";
import ChangeAlertSubscription from "./pages/Subscription/ChangeAlertSubscription";
import Backup from "./pages/Backup/Backup";
import AllNotifications from "./pages/global/AllNotifications";
import { NotificationProvider } from "./components/NotificationContext";
import SelectedAudienceList from "./pages/Campaign/SelectedAudienceList";
import FinalCampaign from "./pages/Campaign/FinalCampaign";


const App = () => {
  const location = useLocation(); 
  const [theme, colorMode] = useMode();

  const {checkAuthentication } = useAuth();
  const isAuthenticated = checkAuthentication();
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    if (!checkAuthentication()) {
    }
  }, [checkAuthentication]);

  return (
    <YearProvider>
    <CSNProvider>
    <UserProvider>
    {!isAuthenticated ? (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
              )
      : <>
      <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
          <CssBaseline />
          <NotificationProvider>
          <MyProSidebarProvider>
          <div style={{ height: "100%", width: "100%" }}>
            <main>
              <Topbar />
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/template" element={<Templates />} />
                <Route path="/manage_template" element={<ManageTemplate />} />
                <Route path="/update_profile/:id" element={<UpdateProfile />} />
                <Route path="/change_password" element={<ChangePassword />} />
                <Route path="/select_audience" element={<RunChampaign />} />
                <Route path="/campaign_audience_list" element={<SelectedAudienceList />} />
                <Route path="/final_campaign" element={<FinalCampaign />} />
                
                <Route path="/campaign_history" element={<CampaignHsitory />} />
                <Route path="/renew_history" element={<RenewEmailHistory />} />
                
                <Route path="/team" element={<Team />} />
                <Route path="/exported_file" element={<ExportedFiles />} />
                <Route path="/insight_metrics" element={<InsightMatrics />} />
                <Route path="/insight_metrics_client" element={<InsightMatricsClient />} />
                <Route path="/insight_metrics_tabular" element={<InsightMatricsTabular />} />
                <Route path="/insight_metrics2" element={<InsightMatrics2 />} />
                <Route path="/insight_metrics_v2" element={<InsightMatricsV2 />} />
                <Route path="/insight_metrics/:id" element={<InsightMatricsContract />} />
                
                <Route path="/usage/:ccsn/:from_date/:to_date" element={<Usage />} />
                <Route path="/usage_user_count/:ccsn/:from_date/:to_date" element={<UsageUserCount />} />
                <Route path="/get_usage" element={<GetUsage />} />

                <Route path="/account" element={<Account />} />
                <Route path="/client_account" element={<AccountClient />} />
                <Route path="/account_tabular" element={<AccountTabular />} />
                <Route path="/account2" element={<Account2 />} />
                <Route path="/third_party_account" element={<ThirdPartyAccount />} />
                <Route path="/update_account/:id" element={<UpdateAccount />} />
                <Route path="/add_account" element={<AddAccount />} />
                
                
                <Route path="/active_account" element={<ActiveAccounts />} />
                <Route path="/inactive_account" element={<InactiveAccounts />} />
                <Route path="/contact_information" element={<ContactInformation />} />
                <Route path="/subscription" element={<Subscription />} />
                <Route path="/renewal_due" element={<RenewalDue30 />} />
                <Route path="/set_trigger" element={<TriggerSubs />} />
                <Route path="/opportunity" element={<Opportunity />} />

                <Route path="/form" element={<Form />} />
                <Route path="/bar" element={<Bar />} />
                <Route path="/pie" element={<Pie />} />
                <Route path="/line" element={<Line />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/geography" element={<Geography />} />
                <Route path="/product_master" element={<ProductMaster />} />
                <Route path="/add_product_master" element={<AddProductMaster />} />
                <Route path="/update_product_master/:id" element={<UpdateProductMaster />} />
                <Route path="/product" element={<Product />} />
                <Route path="/add_product" element={<AddProduct />} />
                <Route path="/quotation" element={<Quotation />} />
                <Route path="/add_quotation" element={<AddQuotation />} />
                <Route path="/order_loading_po" element={<OrderLoadingPO />} /> 
                <Route path="/order_loading_po_list" element={<OrderLoadingPOHistory />} />
                <Route path="/add_order_loading_po" element={<AddOrderLoadingPO />} />
                <Route path="/update_order_loading_po/:id" element={<UpdateOrderLoadingPO />} />
                <Route path="/order_loading_distributor" element={<OrderLoadingDistributor />} /> 
                <Route path="/upload" element={<UploadPage />} /> 
                <Route path="/upload_price_list" element={<UploadProductMaster />} /> 
                <Route path="/campare_subscription" element={<CampareSubscription />} /> 
                <Route path="/deleted_subscription" element={<DeletedSubscription />} /> 
                <Route path="/alert_subscription" element={<ChangeAlertSubscription />} /> 
                <Route path="/ra_order_subscription" element={<RAOrderSubscription />} /> 
                <Route path="/new_subscription" element={<NewSubscription />} /> 
                <Route path="/change_log_subscription" element={<ChangeLogSubscription />} /> 
                <Route path="/backup" element={<Backup />} /> 
                <Route path="/notifications" element={<AllNotifications />} /> 
                

              </Routes>
            </main>
          </div>
        </MyProSidebarProvider>
        </NotificationProvider>
      </ThemeProvider>
      </ColorModeContext.Provider>
          </>

        }
      </UserProvider>      
      </CSNProvider>
      </YearProvider>

  );
};

export default App;
