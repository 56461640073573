import React, { createContext, useState } from "react";

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  console.log("🚀 ~ NotificationProvider ~ unreadCount:", unreadCount)

  const updateUnreadCount = (notifications) => {
    const unread = notifications.filter((notif) => !notif.is_read).length;
    setUnreadCount(unread);
  };

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        unreadCount,
        setNotifications,
        setUnreadCount,
        updateUnreadCount,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
