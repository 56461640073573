import React, { useState } from "react";
import axios from "axios";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import { useUser } from "../../components/UserProvider.js";


const Backup = () => {
  const [status, setStatus] = useState("");
  const [taskId, setTaskId] = useState("");

  const { token , user} = useUser();


  const handleBackup = async () => {
    const URL = "v1/api/trigger-backup/";

    const headers = {
        Authorization: `Bearer ${token}`,
      };

    setStatus("Triggering backup...");
    try {
      const response = await axios.post(BACKEND_BASE_URL + URL, {}, { headers });
      setStatus(response.data.message);
      setTaskId(response.data.task_id);
    } catch (error) {
      setStatus("Failed to trigger backup. Please try again.");
      console.error("Error triggering backup:", error);
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">Backup Operation</h2>
      <button
        onClick={handleBackup}
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        Trigger Backup
      </button>
      <div className="mt-4">
        {status && <p>{status}</p>}
        {taskId && <p>Task ID: {taskId}</p>}
      </div>
    </div>
  );
};

export default Backup;
